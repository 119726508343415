import { actionTypes } from "./types"

export const IsLoggedIn = (userDetail) => {
    return {
        type : actionTypes.IsLoggedIn,
        payload: userDetail
        
    }
}
export const IsLoggedOut = (userDetail) => {
    return {
        type : actionTypes.IsLoggedOut,
        payload: userDetail
    }
}
export const UserUpdate = (userDetail) => {
    return {
        type : actionTypes.UserUpdate,
        payload: userDetail
    }
}