import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import moment from "moment";
import { useNavigate } from "react-router-dom";

var page = 1;

// console.log(page,"pahge")

function MainContainer({ toggleSearch }) {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.userDetail?.userToken);
  const isLogin = useSelector((state) => state.isLogin);

  const [eventDetail, setEventDetail] = useState([]);
  // const [ page , setPage] = useState(1);
  // const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState();
  const [search, setSearch] = useState("");
  const [dusrasearch, setDusrasearch] = useState("");

  const [searchdetail, setSearchdetail] = useState([]);
  const [pagelength, setPagelength] = useState();
  const [pagerecords, setPagerecords] = useState();
  const [isFound, setIsFound] = useState(false);

  var totalRecords = 0;
  const viewMore = () => {
    geteventDetail((page += 1));
  };
  const errorHandler = (err) => {
    navigate("/Error");
  };

  useEffect(() => {
    page = 1;
  }, []);

  const eventHandler = (a) => {
    page = 1;
    navigate("/event", { state: { id: a } });
  };

  const geteventDetail = async (page) => {
    await fetch(`${process.env.REACT_APP_BASE_URL}event?page=${page}`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "result");
        if (result.result == 0) {
          setIsFound(true);
        }
        var length = result?.length;
        setPagerecords(result.totalPage);
        setPagelength(length);

        totalRecords = result.data.length;
        setRecords(totalRecords);
        var Event_info = result.data;

        // });
        setLoading(false);
        Event_info = eventDetail.concat(Event_info);
        setEventDetail(Event_info);
      })
      .catch((err) => {});
  };

  const searchedDetails = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}searchEvent`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "efea");
        var eventy = result?.Data;

        setSearchdetail(eventy);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    geteventDetail(1);
    searchedDetails();
  }, []);

  const filteredContactAll =
    dusrasearch?.length === 0
      ? searchdetail
      : searchdetail.filter(
          (data) =>
            data.event_name.toLowerCase().includes(dusrasearch.toLowerCase()) ||
            moment(data?.event_date)
              .format("YYYY-MM-DD")
              .toLowerCase()
              .includes(dusrasearch) ||
            data.occasion.toLowerCase().includes(dusrasearch.toLowerCase()) ||
            data.event_start_time
              .toLowerCase()
              .includes(dusrasearch.toLowerCase()) ||
            data.event_end_time
              .toLowerCase()
              .includes(dusrasearch.toLowerCase()) ||
            data.status.toLowerCase().includes(dusrasearch.toLowerCase()) ||
            data.name_of_the_honour
              .toLowerCase()
              .includes(dusrasearch.toLowerCase())
        );

  const filteredContacts =
    search?.length === 0
      ? eventDetail
      : eventDetail.filter(
          (data) =>
            data.event_name.toLowerCase().includes(search.toLowerCase()) ||
            moment(data?.event_date)
              .format("YYYY-MM-DD")
              .toLowerCase()
              .includes(search) ||
            data.occasion.toLowerCase().includes(search.toLowerCase()) ||
            data.event_start_time
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            data.event_end_time.toLowerCase().includes(search.toLowerCase()) ||
            data.status.toLowerCase().includes(search.toLowerCase())
        );
  console.log(filteredContactAll, "filteredContactAll");

  return (
    <div>
      {toggleSearch == true ? (
        <section className="Main-all-project">
          <div className="container">
            {loading ? (
              <div>
                {isFound ? (
                  <div className="d-feex-center red">
                    <h3>Event Not found</h3>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="product-detail-all-MainContainer">
                {toggleSearch && (
                  <div
                    aria-labelledby="navbarDropdown"
                    style={{ textAlign: "center" }}
                  >
                    <div className="Serachbar">
                      {dusrasearch && dusrasearch?.length ? (
                        <input
                          className="head-inpu abcde"
                          type="search"
                          name="search"
                          value={dusrasearch}
                          onChange={(e) => setDusrasearch(e.target.value)}
                          id="search"
                          placeholder="Search..."
                        />
                      ) : (
                        <>
                          <input
                            className="head-inpu abcde"
                            type="search"
                            name="search"
                            value={dusrasearch}
                            onChange={(e) => {
                              console.log(e.target.value, "dusra");
                              setDusrasearch(e.target.value);
                            }}
                            id="search"
                            placeholder="Search..."
                          />
                          <button
                            className="search_btn"
                            href="#"
                            disabled={search}
                          >
                            <img
                              className="searchbar-icon"
                              src={`${process.env.REACT_APP_BASE_URL}img/search.png`}
                              alt="icon"
                            />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {filteredContactAll?.length == 0 ? (
                  <div className="no-data">
                    <p>No Events found</p>
                  </div>
                ) : (
                  <div>
                    {filteredContactAll?.map((data, index) => (
                      <div
                        className="event-listing-row"
                        onClick={() => eventHandler(data?.event_id)}
                        key={index}
                      >
                        <span className="text">{data.event_name}</span>
                        <span className="date">
                          {moment(data?.event_date).format("YYYY-MM-DD")}
                        </span>
                        <span className="lable">{data.occasion}</span>

                        <span className="lable">{data.name_of_the_honour}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      ) : (
        <section className="Main-all-project">
          <div className="container">
            {loading ? (
              <div>
                <div>
                  {isFound ? (
                    <div className="d-feex-center red">
                      <h3>Event Not found</h3>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="product-detail-all-MainContainer">
                {toggleSearch && (
                  <div
                    aria-labelledby="navbarDropdown"
                    style={{ textAlign: "center" }}
                  >
                    <div className="Serachbar">
                      {search && search?.length ? (
                        <input
                          className="head-inpu abcde"
                          type="search"
                          name="search"
                          value={search}
                          onChange={(e) => {
                            console.log(e, "search");
                            setSearch(e.target.value);
                          }}
                          id="search"
                          placeholder="Search..."
                        />
                      ) : (
                        <>
                          <input
                            className="head-inpu abcde"
                            type="search"
                            name="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            id="search"
                            placeholder="Search..."
                          />
                          <button
                            className="search_btn"
                            href="#"
                            disabled={search}
                          >
                            <img
                              className="searchbar-icon"
                              src={`${process.env.REACT_APP_BASE_URL}img/search.png`}
                              alt="icon"
                            />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {filteredContacts?.length == 0 ? (
                  <div className="no-data">
                    <p>No Events found</p>
                  </div>
                ) : (
                  <div>
                    {filteredContacts.map((data, index) => (
                      <div
                        className="event-listing-row"
                        onClick={() => eventHandler(data?.id)}
                        key={index}
                      >
                        <span className="text">{data.event_name}</span>
                        <span className="date">
                          {" "}
                          {moment(data?.event_date).format("YYYY-MM-DD")}
                        </span>
                        <span className="lable">{data.occasion}</span>
                        <span className="lable">{data.name_of_the_honour}</span>
                      </div>
                    ))}
                  </div>
                )}

                {records >= 10 ? (
                  <button
                    title="view more"
                    className="buton_vieMore"
                    onClick={viewMore}
                  >
                    view more
                  </button>
                ) : (
                  " "
                )}
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
}

export default MainContainer;
