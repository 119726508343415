import React, { useEffect, useRef, useState } from "react";
import { db, storage } from "../Firebase";
import Draggable, { ControlPosition } from "react-draggable";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import "../App.css";
import { useLocation } from "react-router-dom";
import { AiOutlineContacts, AiOutlineCalendar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { getStorage, getMetadata } from "firebase/storage";

import { MentionsInput, Mention } from "react-mentions";
import $ from "jquery";

import {
  collection,
  updateDoc,
  getDoc,
  doc,
  onSnapshot,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import { BsPlusCircle } from "react-icons/bs";
import { GrAttachment } from "react-icons/gr";
import { MdGroups } from "react-icons/md";

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import { Overlay, Popover } from "react-bootstrap";
import ar from "date-fns/esm/locale/ar/index.js";
import { current } from "@reduxjs/toolkit";
import Dropdown from "react-bootstrap/Dropdown";
function Chat() {
  // const [toggleSearch, setToggleSearch] = useState(true);
  const location = useLocation();
  // let eventId = location.state.id;
  const realToken = useSelector((state) => state?.userDetail?.userToken);
  const rusername = useSelector((state) => state?.userDetail?.data?.username);
  const token = useSelector((state) => state?.userDetail?.data?.id);
  // console.log(token, "efrgxhvcxztryjhgtfygujvgf");
  const [users, setusers] = useState([]);
  const [search, setSearch] = useState("");
  const [areashow, setareashow] = useState(true);
  const [groupSearch, setgroupSearch] = useState("");
  const [chatId, setchatId] = useState("");
  const [conversation, setconversation] = useState([]);
  const [groupconversations, setgroupconversations] = useState([]);
  const [usershowinglist, setUsershowinglist] = useState([]);
  const [userschat, setuserschat] = useState([]);
  const [msg, setmsg] = useState("");
  const [docmu, setdocmu] = useState("");
  const [selectedusername, setselectedusername] = useState("");
  const [currentuserid, setcurrentuserid] = useState("");
  const [currentusername, setcurrentusername] = useState("");
  const [groupid, setgroupid] = useState("");
  const [disabled, setdisabled] = useState(true);
  const [userlistnew, setuserlistnew] = useState([]);
  const [cuurentgroupname, setcuurentgroupname] = useState("");
  const [cuurentmention, setcuurentmention] = useState("");
  const [usersselected, setusersselected] = useState([]);
  const [file, setFile] = useState(null);
  const formFileRef = useRef();
  const [areaexpanded, setareaexpanded] = useState("collapsed");
  const [isgroupchat, setisgroupchat] = useState();
  const reff = collection(db, "users");
  const isLogin = useSelector((state) => state.isLogin);
  const [mentions, setmention] = useState([]);
  const [keypress, setkeypress] = useState(false);
  const [selectValue, setselectValue] = useState();
  // const [imgUrl, setImgUrl] = useState("");
  const [progresspercent, setProgresspercent] = useState(0);
  const [target, setTarget] = useState(null);
  const chatListRef = useRef(null);
  const reffery = useRef(null);
  const [notiarray, setNotiarray] = useState([]);

  const msgBox = useRef(null);
  const PopUp = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const trackPos = (data) => {
    setPosition({ x: data.x, y: data.y });
  };

  console.log(msg);

  // const messaging = getMessaging();
  // const [isTokenFound, setTokenFound] = useState(false);
  //   getToken(setTokenFound);
  //   console.log(isTokenFound,"tvuk6f")
  // onMessage(messaging, (payload) => {
  //   console.log('Message received. ', payload);
  //   const notificationTitle = payload.notification.title;
  // const notificationOptions = {
  //   body: payload.notification.body,
  // };

  //   // ...
  //   console.log(notificationOptions,"mentions")
  //   console.log(notificationTitle,"mentions")

  // });

  //  onSnapshot(doc(db, "users", token.toString()), (snapshot) => {
  //      var data = snapshot.data()?.groupConversation;
  //      console.log(data, "kjb");
  //      setNotiarray(data);
  //    })

  // var dd = notiarray.map((e) => {
  //   console.log(e, "dd")
  //   return e.isunread;
  // });

  // const Max = Math.max(...dd);
  // console.log(Max , "max")

  // localStorage.setItem("Counts" , Max)

  const keypressFunc = (event) => {
    handleKeypress(event);
    if (event.key == "@") {
      // mentions

      // console.log("@pressed", event);

      setkeypress(true);
      setTimeout(() => {
        PopUp?.current.click();
      }, 100);

      setTarget(event.target);

      return true;
    } else {
      setkeypress(false);
      return true;
    }
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      if (!isgroupchat) {
        sendtopersonal();
      } else {
        sendtogroup();
      }
    }
  };

  const sendUploadFileGroup = async () => {
    if (!file) return;

    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
        setFile(null);
        formFileRef.current.value = "";
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // console.log(imgUrl,"rttgioj")
          // setImgUrl(downloadURL)
          const upData = await getDoc(
            doc(db, "groupConversation", groupid.toString())
          );
          const mes = upData.data().messages;
          // console.log(mes,"check")
          let dat = {
            createdAt: new Date(),
            senderName: currentusername,
            content: "",
            fileName: file.name || "",
            senderId: currentuserid,
            documents: downloadURL,
            type: file.type,
          };

          mes.push(dat);
          // console.log(mes, "check-msg");

          await updateDoc(doc(db, "groupConversation", groupid.toString()), {
            messages: mes,
          });
          setmsg("");
          setdocmu("");
          setFile(null);
        });
      }
    );
  };

  const sendUploadFile = async () => {
    if (!file) return;
    // console.log(file, "esdrfhkul");

    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
        setFile(null);
        formFileRef.current.value = "";
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // console.log(imgUrl,"rttgioj")
          // setImgUrl(downloadURL)
          const upData = await getDoc(
            doc(db, "conversation", chatId.toString())
          );
          const mes = upData.data().messages;
          let dat = {
            createdAt: new Date(),
            senderName: currentusername,
            content: "",
            fileName: file.name || "",

            senderId: currentuserid,
            documents: downloadURL,
            type: file.type,
          };

          mes.push(dat);

          await updateDoc(doc(db, "conversation", chatId.toString()), {
            messages: mes,
          });
          setmsg("");
          setdocmu("");
          setFile(null);
        });
      }
    );
  };

  // const onChange = (onChange) => {
  //   setToggleSearch(!toggleSearch)

  // }

  const onFileChange = (e) => {
    var fileInput = document.getElementById("formFile");

    // console.log(fileInput , "srgettht");
    var filePath = fileInput.value;

    e.preventDefault();
    const file = e.target?.files[0];
    // console.log(file)
    setmsg(file.name);
    // fileInput.value = '';
    if (file.size > 10485760.0) {
      alert("File is too big! Maximun 10MB File is allowed");
      fileInput.value = "";
      return false;
    } else if (!file) return;
    setFile(file);
  };

  // const imagelink = imgUrl;

  function getcurrentuser() {
    onSnapshot(doc(db, "users", token.toString()), (snapshot) => {
      // console.log('test 2');
      let data = snapshot.data();
      setcurrentuserid(data.id);

      setcurrentusername(data.name);
      setconversation(data.conversation);

      setgroupconversations(data.groupConversation);

      let arr = [];
      data.groupConversation.map((e) => {
        arr.push(e.timestamp.seconds);
      });
      arr.sort();
      // console.log(arr.sort(), "gdvshgdvs");

      let index = [];

      arr.forEach((e) => {
        data.groupConversation.map((f) => {
          if (e == f.timestamp.seconds) {
            index.push(data.groupConversation.indexOf(f));
          }
        });
      });
      var newarr = [];
      index.map((f) => {
        // console.log(data.groupConversation[f] , "diff1");
        newarr.push(data.groupConversation[f]);
      });

      setUsershowinglist(newarr.reverse());
    });
  }

  //   async function getcurrentuser() {
  //     const converstaiondata = await getDoc(doc(db, "users", token.toString()));
  //     let data = converstaiondata.data();
  //     setcurrentuserid(data.id);

  //     setcurrentusername(data.name);
  //     setconversation(data.conversation);

  //     setgroupconversations(data.groupConversation);

  // let arr = []
  //     data.groupConversation.map((e)=>{
  //       arr.push(e.timestamp.seconds);
  //     })
  //     console.log(arr.sort(),"gdvshgdvs");

  //     let index = [];

  //    arr.forEach((e)=>{
  //       data.groupConversation.map((f)=>{
  //           if(e == f.timestamp.seconds){
  //             index.push(data.groupConversation.indexOf(f));
  //           }
  //       })
  //    })
  //     var newarr = []
  //     index.map((f)=>{
  //     // console.log(data.groupConversation[f] , "diff1");
  //     newarr.push(data.groupConversation[f]);
  //    })

  //    console.log(newarr , "newarray");
  //    setUsershowinglist(newarr.reverse())
  //   }

  //selecting a user and disaplay its messages for single chat
  async function selectuser(e) {
    setTimeout(() => {
      setareashow(false);
      // console.log(areashow, "in timeout");
    }, 1000);
    setareashow(false);
    setareaexpanded("");
    // console.log(areashow, "yyf");
    setselectedusername(e.name);
    // console.log("select", e.name);

    setdisabled(false);
    setisgroupchat(false);
    let n = e.id.toString();
    // console.log(n, "n");

    const docuCovo = token;
    // console.log(docuCovo);

    let v = [token, n];
    let v1 = v.sort().join("_");
    // console.log(v1);
    let chatId = null;

    // e.conversation

    const index = e.conversation.findIndex((e) => e.chatId == v1);
    if (index === -1) {
      setDoc(doc(db, "conversation", v1), {
        chatId: v1,
        messages: [],
      });
      const currentUser = {
        chatId: v1,
        userId: token,
        userName: currentusername,
      };

      const senderData = {
        chatId: v1,
        userId: n,
        userName: e.username,
      };

      // let jayesh = e.name;

      e.conversation.push(currentUser);
      await updateDoc(doc(db, "users", e.id), {
        conversation: e.conversation,
      });
      // console.log("conversation", conversation);
      const arr = [...conversation, senderData];
      setconversation(arr);
      await updateDoc(doc(db, "users", `${token}`), {
        conversation: arr,
      });
    }

    // v1

    onSnapshot(doc(db, "conversation", v1), (snapshot) => {
      // console.log('test 3');
      // console.log('test 3currentuserid',currentuserid);
      // console.log('test e.groupID',e.groupID);
      let messages = snapshot.data().messages;
      setuserschat(messages);
    });

    setchatId(v1);
  }

  //selecting a group and disaplay its messages
  async function selectgroup(e) {
    // console.log('groupID',e.groupID);
    // console.log('groupID e',e);
    // {console.log(usershowinglist  ,"userlist 12")}
    // setcurrentusername(groupconversations.groupName);

    $(document).ready(function () {
      $(".accordion_user").click(function () {
        $(".accordion_user").removeClass("colored");
        $(this).addClass("colored");
      });
    });

    let abcs = await getDoc(doc(db, "users", currentuserid.toString()));
    // console.log(abcs.data().groupConversation, "Selecteduserdata");
    let getGroup = abcs.data().groupConversation;

    getGroup.filter((ele) => {
      if (ele.groupID == e.groupID) {
        var index = getGroup.indexOf(ele);
        // console.log(index, "idnesdnf");
        getGroup[index].isunread = 0;
        updateDoc(doc(db, "users", currentuserid.toString()), {
          groupConversation: getGroup,
        });
      }
    });

    // console.log(e, "e");
    setcuurentgroupname(e.groupName);

    setdisabled(false);
    setgroupid(e.groupID);
    setisgroupchat(true);
    // console.log(groupid, "selectgroup");

    onSnapshot(
      doc(db, "groupConversation", e.groupID.toString()),
      (snapshot) => {
        // console.log('test currentuserid',currentuserid);
        // console.log('test e.groupID',e.groupID);
        let messages = snapshot.data().messages;
        // console.log(snapshot.data().groupMember , "mem");
        // messages.forEach((date) => {
        //   date.createdAt = new Date(date.createdAt.toDate().toDateString());
        // });
        // console.log(messages, "msgs");
        setuserschat(messages);
      }
    );

    setTimeout(() => {
      fetch(`${process.env.REACT_APP_BASE_URL}mentionUser`, {
        method: "post",
        headers: {
          "x-access-token": `${realToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ event_id: e.groupID }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          // console.log(res,"res")
          var parties = res?.data;
          // console.log(res.data.map((ele)=>{

          //   return(
          //     ele
          //   )}),"ytduyvyb")

          // console.log(res.date.filter((ele)=> {
          //   ele
          // }))
          // console.log(rusername,"efefeeee")

          // var removeuser = parties.map((ele) => {
          //   console.log(ele.username.indexOf(currentusername) , "efe")
          //     return ele.username
          // }).indexOf(currentusername)
          // parties.splice(removeuser, 1);

          // var removeuser = parties.map((ele) => {
          //         var indexuser =
          // })

          var removeuser = parties.findIndex((object) => {
            return object.username === rusername;
          });

          parties.splice(removeuser, 1);

          // console.log(removeuser,"parties")
          // const removeName = parties.indexOf(currentusername);
          // if(removeName > -1) {
          //   parties.splice(removeName, 1)
          // }

          // console.log(parties,"res")

          // var t = res.data.filter((e) => e.is_owner == 0);
          // const addName = localStorage.getItem("nameofhonour")
          // t.push(addName);
          setmention(parties);
          // console.log(mentions,"mentionUser")
        });
    }, 10);

    setmsg("");
    msgBox?.current?.focus();
  }

  async function sendtopersonal() {
    if (file) {
      sendUploadFile();
    } else {
      if (msg.length == 0 || msg.trim() == "" || msg.trim() == null) {
        alert("please write your CHAT message");
      } else {
        const upData = await doc(db, "conversation", chatId.toString());

        const mes = upData.data().messages;
        let dat = {
          createdAt: new Date(),
          senderName: currentusername,
          content: msg.trim(),
          senderId: currentuserid,
          documents: "",
          type: "",
        };

        // console.log(dat, "trduygopjli");

        mes.push(dat);

        await updateDoc(doc(db, "conversation", chatId.toString()), {
          messages: mes,
        });
        setmsg("");
        setdocmu("");
      }
    }
  }

  async function sendtogroup() {
    if (file) {
      sendUploadFileGroup();
    } else {
      if (msg.length == 0 || msg.trim() === "" || msg.trim() === null) {
        alert("please Enter Chat message");
      } else {
        // console.log("you are sending msh to grp");
        const upData = await getDoc(
          doc(db, "groupConversation", groupid.toString())
        );

        var gorupmemebers = upData.data().groupMember;

        var array1 = [];

        // for() {
        // console.log(array1 , "array1");

        // }
        for await (const ele of gorupmemebers) {
          let list = await getDoc(doc(db, "users", ele.toString()));
          array1.push(list.data().username);
          // console.log(array1 , "array275276286622");
        }
        // console.log(array1 , "array222");
        // await gorupmemebers.forEach(async(ele)=>{

        //   })

        let mes = upData.data().messages;
        // console.log(currentusername , "list");

        let dat = {
          createdAt: new Date(),
          senderName: currentusername,
          // fileName : file.name || "",
          fileName: " ",
          // content: msg.trim(),
          content: msg,

          senderId: currentuserid,
          documents: "",
          type: "",
        };
        // console.log(imagelink , 'docmu');
        mes.push(dat);

        setmsg("");
        setdocmu("");
        msgBox.current.focus();
        await updateDoc(doc(db, "groupConversation", groupid.toString()), {
          messages: mes,
        });
      }

      await gorupmemebers.forEach(async (list) => {
        let docsmem = await getDoc(doc(db, "users", list.toString()));
        // console.log(docsmem.data(),"list")
        let getGroup = docsmem.data().groupConversation;
        getGroup.filter((ele) => {
          if (ele.groupID == groupid) {
            let index = getGroup.indexOf(ele);
            getGroup[index].timestamp = new Date();
            updateDoc(doc(db, "users", list.toString()), {
              groupConversation: getGroup,
            });

            if (currentuserid == list) {
              getGroup[index].isunread = 0;
              // console.log(list, "0");
              updateDoc(doc(db, "users", list.toString()), {
                groupConversation: getGroup,
              });
            } else {
              getGroup[index].isunread = getGroup[index].isunread + 1;
              // console.log(getGroup[index].isunread, "1");
              updateDoc(doc(db, "users", list.toString()), {
                groupConversation: getGroup,
              });
            }
          }
        });
      });
    }
    reffery.current.focus();
  }
  function uploadfile() {
    const input = document.getElementById("formFile");
    input.click();
  }

  async function getuserlist() {
    const q = query(collection(db, "users"));
    const userslist = await getDocs(q);
    const userdata = userslist.docs.map((list) => ({
      ...list.data(),
      id: list.id,
    }));
    setuserlistnew(userdata);
    // console.log(userlistnew);

    // userlistnew.map((list)=> {
    // })
  }

  // console.log(usershowinglist.length , "list");
  // console.log(usershowinglist[1].isunread,"unread")

  const filteredContacts =
    search.length === 0
      ? userlistnew
      : userlistnew.filter((data) =>
          // console.log(data.name,"ergcvbjh")
          data.name.toLowerCase().includes(search.toLocaleLowerCase())
        );

  const filteredgroup =
    groupSearch.length === 0
      ? usershowinglist
      : usershowinglist.filter((data) =>
          // console.log(data.name,"ergcvbjh")
          data.groupName.toLowerCase().includes(groupSearch.toLocaleLowerCase())
        );

  useEffect(() => {
    getcurrentuser();
    getuserlist();
    onSnapshot(reff, (snapshot) => {
      // console.log('test 1');
      let usersdata = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // setusers(current => [
      //   ...current,usersdata])
      setusers(usersdata);

      //  console.log(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  }, []);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    reffery.current?.scrollIntoView({ behavior: "smooth" });
  }, [userschat]);
  // console.log(mentions);

  const getTaggedName = (username) => {
    // console.log(username ,"srgr")
    // console.log('====================================');
    // console.log(name);
    // console.log('====================================');
    if (username.includes("@")) {
      mentions.map((e, index) => {
        // PopUp?.current.focus();
        // console.log(e,"eee")
        if (username.includes(e.username)) {
          username = username.replace(e.username, `<b>${e.username}</b>`);
        }
      });
    }
    return { __html: username };
  };

  return (
    <div className="fotterhatao">
      {isLogin ? (
        <div
          style={{ display: "flex", flexDirection: "row" }}
          className="Main-all-project"
        >
          {" "}
          {/* <Draggable onDrag={(e, data) => trackPos(data)}>
            <div
              className="boxee"
              style={{ border: " 1px solid rebeccapurple" }}
            >
              <div>Move me around!</div>
              <div>
                x: {position.x.toFixed(0)}, y: {position.y.toFixed(0)}
              </div>
            </div>
          </Draggable> */}
          {/*==================================== SELECT CHAT ================================================= */}
          <div className="accordion" id="accordionExample">
            {/* <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={`${!areashow ? "accordion-button collapsed " : "accordion-button"}`}
              
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded={`${areashow}`}
              aria-controls="collapseOne"
              onClick={(e)=>{areashow ? setareashow(!areashow) : setareashow(areashow)}}
            >
              Users
            </button>
          </h2>
          <div
            id="collapseOne"
            // onClick={onChange}
            className={`${areashow ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'}`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
            >
            {console.log(areashow , "areashow")}
            <div className="accordion-body accordion_left">
            <div className="Serachbar">
                    <input
                      className="head-inpu"
                      type="text"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      id="search"
                    />
                    <button className="search_btn" href="#">
                      <img
                        className="searchbar-icon"
                        src="img/search.png"
                        alt="icon"
                      />
                    </button>
                  </div>
              {filteredContacts.map((list, index) => {
                return (
                  <div key={index}>
                    {currentusername === list.name ? (
                      <div></div>
                    ) : (
                      <div className="accordion_user">
                        <a
                          className="chathvover"
                          style={{ cursor: "pointer" }}
                          onClick={() => selectuser(list)}
                        >
                          {list.name}
                        </a>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}

            {/* ********************Web View ************************** */}

            <div className="accordion-item badivali d-none d-md-block">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button d-none d-md-block"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  disabled={true}
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Groups
                </button>
                <button
                  className="accordion-button d-block d-md-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <MdGroups className="badalogo" />
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse show"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body accordion_left_two">
                  <div className="Serachbar">
                    <input
                      className="head-inpu"
                      type="search"
                      name="search"
                      placeholder="Search"
                      value={groupSearch}
                      onChange={(e) => setgroupSearch(e.target.value)}
                      id="search"
                    />
                    <button className="search_btn" href="#">
                      <img
                        className="searchbar-icon"
                        src="img/search.png"
                        alt="icon"
                      />
                    </button>
                  </div>
                  {filteredgroup.length != 0 ? (
                    filteredgroup.map((e, index) => {
                      if (e.isGroupDeleted === false) {
                        return (
                          <div key={index}>
                            {e.isunread == 0 ? (
                              <div onClick={() => selectgroup(e)}>
                                <div
                                  className="accordion_user"
                                  onClick={() => selectgroup(e)}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    // data-bs-toggle="collapse"
                                    // data-bs-target=".accordion-collapse.show"
                                    className="chathvover "
                                    style={{ cursor: "pointer" }}
                                  >
                                    {e.groupName}
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div
                                  className="accordion_user"
                                  onClick={() => selectgroup(e)}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    // data-bs-toggle="collapse"
                                    // data-bs-target=".accordion-collapse.show"
                                    className="chathvover"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "900",
                                    }}
                                  >
                                    {e.groupName}
                                  </a>
                                  <span className="dot-open">{e.isunread}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className="accordion_user">
                      <b>No Group Found</b>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* *********************** Mobile View ***************************** */}

            <div className="accordion-item chotivali d-block d-md-none">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button d-none d-md-block"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Groups
                </button>
                <button
                  className="accordion-button d-block d-md-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <MdGroups className="badalogo" />
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse show"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body accordion_left_two">
                  <div className="Serachbar">
                    <input
                      className="head-inpu"
                      type="search"
                      name="search"
                      placeholder="Search"
                      value={groupSearch}
                      onChange={(e) => setgroupSearch(e.target.value)}
                      id="search"
                    />
                    <button className="search_btn" href="#">
                      <img
                        className="searchbar-icon"
                        src="img/search.png"
                        alt="icon"
                      />
                    </button>
                  </div>
                  {filteredgroup.length != 0 ? (
                    filteredgroup.map((e, index) => {
                      if (e.isGroupDeleted === false) {
                        return (
                          <div key={index}>
                            {e.isunread == 0 ? (
                              <div
                                onClick={() => selectgroup(e)}
                                data-bs-toggle="collapse"
                                data-bs-target=".accordion-collapse.show"
                              >
                                <div
                                  className="accordion_user"
                                  // onClick={() => selectgroup(e)}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    data-bs-toggle="collapse"
                                    data-bs-target=".accordion-collapse.show"
                                    className="chathvover "
                                    style={{ cursor: "pointer" }}
                                  >
                                    {e.groupName}
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div
                                  className="accordion_user"
                                  onClick={() => selectgroup(e)}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    data-bs-toggle="collapse"
                                    data-bs-target=".accordion-collapse.show"
                                    className="chathvover"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "900",
                                    }}
                                  >
                                    {e.groupName}
                                  </a>
                                  <span className="dot-open">{e.isunread}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className="accordion_user">
                      <b>No Group Found</b>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*==================================== SELECT CHAT END ================================================= */}
          {/* <div
        className="accordion_right_content"
        style={{ borderLeft: "1px solid black", height: "100vh" }} 
      ></div> */}
          <div className="accordion_right_content_box">
            {disabled ? (
              ""
            ) : (
              <div className="usser_title">
                <h3>{isgroupchat ? cuurentgroupname : selectedusername}</h3>

                {/* <div className={` ${disabled ? "d-none" : ""}`}>
                  <a
                    target=" "
                    href="https://calendly.com/supermixentertainment/planning-meeting"
                  >
                    <button
                      type="button"
                      className={` btn btn-primary d-none d-md-block `}
                    

                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      SCHEDULE MEETING
                    </button>
                    <button
                      className="d-block d-md-none "
                      type="button"
                     
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <AiOutlineCalendar className="badalogo" />
                    </button>
                  </a>
                </div> */}

                <div></div>
              </div>
            )}

            <div className="chat_info">
              {disabled ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}welcome.jpg`}
                  className="welcome-img"
                  alt="img"
                />
              ) : (
                <div className="d-none"></div>
              )}

              {userschat.map((e, index) => {
                let date = new Date(e.createdAt.seconds * 1000)
                  .toLocaleString("en-IN")
                  .slice(0, 8);
                let datetime = new Date(
                  e.createdAt.seconds * 1000
                ).toLocaleString("en-IN");
                let time = new Date(e.createdAt.seconds * 1000)
                  .toLocaleString("en-IN")
                  .slice(10);

                let currentdate = new Date()
                  .toLocaleString("en-IN")
                  .slice(0, 8);

                const image = e.documents;

                return (
                  <div key={index}>
                    <div>
                      {e.senderId == currentuserid ? (
                        <div className="sender_img">
                          {e.content.length === 0 ||
                          e.content.length === null ? (
                            <>
                              {e.type.startsWith("image") ? (
                                <div>
                                  <a
                                    href={e.documents}
                                    target=" "
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      alt="img"
                                      className="sender"
                                      style={{ height: "200px" }}
                                      src={e.documents}
                                    ></img>
                                  </a>
                                </div>
                              ) : (
                                // <div>
                                //   <iframe
                                //     className="sender"
                                //     style={{ height: "200px" }}
                                //     src={e.documents}
                                //   ></iframe>{" "}
                                // </div>
                                <div>
                                  {e.type.startsWith("video") ? (
                                    <div>
                                      <a
                                        target=" "
                                        href={e.documents}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {/* </a> */}
                                        <video
                                          controls={true}
                                          autoPlay="autoplay"
                                          className="sender"
                                          style={{ height: "200px" }}
                                          src={e.documents}
                                        ></video>
                                      </a>
                                      {/* <video
                                style={{width: "100px", height:"100px" }}
                                src={e.documents}
                                alt="profile"
                              ></video> */}
                                    </div>
                                  ) : (
                                    <div>
                                      <a
                                        target=" "
                                        href={e.documents}
                                        style={{ cursor: "pointer" }}
                                        className="SenterDocAnchor right"
                                      >
                                        {e?.fileName}
                                        <img
                                          alt="icon"
                                          className="ms-2 ImageSenderSize"
                                          style={{ height: "200px" }}
                                          src={`${process.env.REACT_APP_BASE_URL}img/black-pdf.svg`}
                                        ></img>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              )}
                              <div>
                                {currentdate == date ? (
                                  <div className="time">{time}</div>
                                ) : (
                                  <div className="time">{datetime}</div>
                                )}
                              </div>
                              <div ref={reffery} />
                            </>
                          ) : (
                            // <>
                            //   {e.type.startsWith("image") ? (
                            //     <div>
                            //       <a
                            //         href={e.documents}
                            //         target="_blank"
                            //         style={{ cursor: "pointer" }}
                            //       >
                            //         <img
                            //           className="sender"
                            //           style={{ height: "200px" }}
                            //           src={e.documents}
                            //         ></img>
                            //       </a>
                            //     </div>
                            //   ) : (
                            //     <div>
                            // <iframe
                            //   className="sender"
                            //   style={{ height: "200px" }}
                            //   src={e.documents}
                            // ></iframe>{" "}
                            //     </div>
                            //   )}
                            //   <div>
                            //     {currentdate == date ? (
                            //       <div className="time">{time}</div>
                            //     ) : (
                            //       <div className="time">{datetime}</div>
                            //     )}
                            //   </div>
                            //   <div ref={reffery} />
                            // </>
                            <>
                              <div
                                className="sender"
                                dangerouslySetInnerHTML={getTaggedName(
                                  e.content
                                )}
                              ></div>
                              <div>
                                {currentdate == date ? (
                                  <div className="time">{time}</div>
                                ) : (
                                  <div className="time">{datetime}</div>
                                )}
                                <div ref={reffery} />
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div>
                          {e.content.length === 0 ||
                          e.content.length === null ? (
                            <>
                              {e.type.startsWith("image") ? (
                                <a
                                  href={e.documents}
                                  target=" "
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    alt="icon"
                                    className="ms-2 receiver"
                                    style={{ height: "200px" }}
                                    src={e.documents}
                                  ></img>
                                </a>
                              ) : (
                                // <iframe
                                //   title ="frbg"
                                //   src={e.documents}
                                //   alt="profile"
                                // ></iframe>
                                <div>
                                  {e.type.startsWith("video") ? (
                                    <div>
                                      <a
                                        target=" "
                                        href={e.documents}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <video
                                          controls={true}
                                          autoPlay="autoplay"
                                          title="frbg"
                                          className="receiver"
                                          src={e.documents}
                                          alt="profile"
                                        ></video>
                                      </a>
                                    </div>
                                  ) : (
                                    <div>
                                      <a
                                        target=" "
                                        href={e.documents}
                                        className="SenterDocAnchor left"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {e?.fileName}
                                        <img
                                          alt="icon"
                                          className="ms-2 ImageSenderSize"
                                          style={{ height: "200px" }}
                                          src={`${process.env.REACT_APP_BASE_URL}img/black-pdf.svg`}
                                        ></img>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              )}
                              <div>
                                {currentdate == date ? (
                                  <div className="time">{time}</div>
                                ) : (
                                  <div className="time">{datetime}</div>
                                )}
                              </div>
                              <div ref={reffery} />
                            </>
                          ) : (
                            // <>
                            //   {e.type.startsWith("image") ? (
                            //     <a
                            //       href={e.documents}

                            //       style={{ cursor: "pointer" }}
                            //     >
                            //       <img
                            //       alt="icon"
                            //         className="ms-2 receiver"
                            //         style={{ height: "200px" }}
                            //         src={e.documents}
                            //       ></img>
                            //     </a>
                            //   ) : (
                            //     <iframe
                            //       title ="frbg"
                            //       src={e.documents}
                            //       alt="profile"
                            //     ></iframe>
                            //   )}
                            //   <div>
                            //     {currentdate == date ? (
                            //       <div className="time">{time}</div>
                            //     ) : (
                            //       <div className="time">{datetime}</div>
                            //     )}
                            //   </div>
                            //   <div ref={reffery} />
                            // </>
                            <>
                              <div
                                className="ms-3 receiver"
                                dangerouslySetInnerHTML={getTaggedName(
                                  e.content
                                )}
                                ref={reffery}
                              >
                                {/* {e.content} */}
                              </div>
                              <div ref={reffery} />
                              <div>
                                {currentdate == date ? (
                                  <div className="time">
                                    {time}
                                    <div>{e.senderName}</div>
                                  </div>
                                ) : (
                                  <div className="time">
                                    {datetime}
                                    <div>{e.senderName}</div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                          <div ref={reffery} />
                        </div>
                      )}
                    </div>
                    {/* ) : ( */}
                    {/* <div>
                    {e.senderId == currentuserid ? (
                      <div className="sender_img">
                        {e.content.length === 0 || e.content.length === null ? (
                          <>
                           {
                           e.type.startsWith("image") ?
                           (
                            <img
                            className="sender"
                            style={{ height: "200px" }}
                            src={e.documents}
                            ></img>
                          ) : (<iframe
                            className="sender"
                            style={{ height: "200px" }}
                            src={e.documents}
                            alt="profile"
                          >

                          </iframe>)
                           }
                            
                              {console.log(e.documents,"receijjjjer")}
                            <div>
                              {currentdate == date ? (
                                <div className="time">{time}</div>
                              ) : (
                                <div className="time">{datetime}</div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="sender">{e.content}</div>
                            <div>
                              {currentdate == date ? (
                                <div className="time">{time}</div>
                              ) : (
                                <div className="time">{datetime}</div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        {e.content.length === 0 || e.content.length === null ? (
                          <>
                        {console.log(e.documents,"receiver")}

                        {
                           e.type.startsWith("image") ?
                           (
                            <img
                            className="ms-2 receiver"
                            style={{ height: "200px" }}
                            src={e.documents}
                          ></img>
                          ) : (<iframe
                            className="ms-2 receiver"
                            style={{ height: "200px" }}
                            src={e.documents}
                          >

                          </iframe>)
                           }
                          
                            <div>
                              {currentdate == date ? (
                                <div className="time">{time}</div>
                              ) : (
                                <div className="time">{datetime}</div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="ms-2 receiver">{e.content}</div>
                            <div>
                              {currentdate == date ? (
                                <div className="time">{time}</div>
                              ) : (
                                <div className="time">{datetime}</div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )} */}
                  </div>
                );
              })}
              <div style={{ marginTop: "50px" }}></div>
            </div>

            {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
            <div className="main-doom">
              {progresspercent >= 100 || progresspercent < 1 ? (
                ""
              ) : (
                <div className="">
                  <div
                    className="dhoom"
                    style={{ width: `${progresspercent}%` }}
                  >
                    {progresspercent}
                  </div>
                </div>
              )}
            </div>
            {disabled ? (
              ""
            ) : (
              <div className="inputbox" ref={chatListRef}>
                <ChatListOverlay
                  target={target}
                  keypress={keypress}
                  mentions={mentions}
                  PopUp={PopUp}
                  selectValue={selectValue}
                  currentuserid={currentuserid}
                  selectmention={(username) => {
                    setmsg((prev) => prev + `${username} `);
                    setkeypress(false);
                    msgBox?.current.focus();
                  }}
                />
                <input
                  type="text"
                  className={`forn form-control ${disabled ? "d-none" : ""}`}
                  value={msg}
                  onKeyPress={handleKeypress}
                  onKeyDown={keypressFunc}
                  onChange={(e) => {
                    setmsg(e.target.value);
                    setFile(null);
                    formFileRef.current.value = "";
                  }}
                  ref={msgBox}
                  placeholder="Enter your message"
                  forceSuggestionsAboveCursor={true}
                />

                {/* <MentionsInput
                type="text"
                className={`forn form-control ${disabled ? "d-none" : ""}`}
                value={msg}
                onKeyPress={handleKeypress}
                onKeyDown={keypressFunc}
                onChange={(e) => {
                  setmsg(e.target.value);
                  setFile(null);
                  formFileRef.current.value = "";
                }}
                placeholder="Enter your message"
              >
                <Mention
                 target={target}
                keypress={keypress}
                mentions={mentions}
                />
              </MentionsInput>  */}

                <input
                  type="file"
                  id="formFile"
                  // multiple
                  className="form-control"
                  style={{ display: "none" }}
                  onChange={onFileChange}
                  ref={formFileRef}
                />

                <button
                  className={`btn file_btn m-2 ${disabled ? "d-none" : ""}`}
                  onClick={uploadfile}
                >
                  <GrAttachment />
                </button>

                {/* {isgroupchat ? ( */}
                <button
                  type="submit"
                  className={`btn btn-primary ms-2 ${disabled ? "d-none" : ""}`}
                  onClick={sendtogroup}
                >
                  Send
                </button>
                {/* ) : ( */}
                {/* <button
                  type="submit"
                  className={`btn btn-primary  ${disabled ? "d-none" : ""}`}
                  onClick={sendtopersonal}
                >
                  Send
                </button>
              )} */}
                <div ref={reffery} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div class="d-fex-center red" style={{ padding: "135px" }}>
          <h3>User Not found</h3>
        </div>
      )}
    </div>
  );
}

export default Chat;

const ChatListOverlay = ({
  mentions,
  keypress,
  target,
  ref,
  PopUp,
  selectmention,
  currentuserid,
  selectValue,
}) => {
  // console.log("mentions", mentions);
  return (
    <Overlay
      show={keypress}
      target={target}
      placement="top"
      container={ref}
      containerPadding={20}
      className="pointer"
    >
      <div id="popover-contained">
        <>
          {/* <div class="dropdown">
            <button
              style={{ opacity: "0", height: "0" }}
              ref={PopUp}
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {(mentions || []).map((ele) => {
                const d = ele;
                return (
                  <>
                    {d.id == currentuserid ? null : (
                      <li key={ele.id}>
                        <a
                          href="javascript:void(0)"
                          onClick={() => selectmention(d.username)}
                          class="dropdown-item"
                        >
                          {d.username}
                        </a>
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </div> */}

          <div class="btn-group dropup">
            <button
              type="button"
              style={{ opacity: "0", height: "0" }}
              class="btn btn-secondary dropdown-toggle"
              ref={PopUp}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></button>
            <ul class="dropdown-menu" id="ChatDrop">
              {(mentions || []).map((ele, index) => {
                const d = ele;
                return (
                  <div key="index">
                    {d.id == currentuserid ? null : (
                      <li key={d.id}>
                        <a
                          href="javascript:void(0)"
                          onClick={() => selectmention(d.username)}
                          class="dropdown-item"
                        >
                          {d.username}
                        </a>
                      </li>
                    )}
                  </div>
                );
              })}
            </ul>
          </div>
          {/* <select
               className="form-control"
               autofocus
               areashow={true}
               areaexpanded
              //  PopUp={PopUp}
               value={selectValue}
               ref={PopUp}
               onChange={(e)=>selectmention(e.target.value)}
              >
    
              </select> */}
        </>
      </div>
    </Overlay>
  );
};
