import React from 'react'
import "../App.css"
function Errros() {
  return (
    <div className="Main-all-project">
      <div>
        <img src="11104.jpg" alt="Error" className="error_image"/>
        <h3 className="something">SOMETHING WENT WRONG</h3>
        </div>
    </div>
  )
}

export default Errros