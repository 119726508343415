import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging/sw";
import { setTokenFound } from "./Chat/Chat";
import { getToken } from "firebase/messaging";

// const getToken = (setTokenFound) => {
//   return getToken(messaging, {vapidKey: 'BPTBMOifv4K2Er_n3Prrm7vL7BhMc4BQmvOHZu2bpRboqX6jdmzp5zeik2kLz48oBH1fFb9VAha2vw6uGeRjawE'}).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       setTokenFound(true);
//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//       setTokenFound(false);
//       // shows on the UI that permission is required
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // catch error while creating client token
//   });
// }

const firebaseConfig = {
  //Live FirConfig  start----------------------------------------------------------------------->
  apiKey: "AIzaSyAQr_5RdIaunmXqRgOKwKaGYK2gmlNh--k",
  authDomain: "fir-add-299a0.firebaseapp.com",
  projectId: "fir-add-299a0",
  storageBucket: "fir-add-299a0.appspot.com",
  messagingSenderId: "770853750619",
  appId: "1:770853750619:web:572c31d99bee1c0beabc7e",
  measurementId: "G-2MQRCKFXXK",
  //Live FirConfig  End
  //Local FirConfig  start----------------------------------------------------------------------->
  // apiKey: "AIzaSyCWE1zELAfEdjzfGfht8RQgyL8YB8iBfKE",
  // authDomain: "supermixtest-1cd88.firebaseapp.com",
  // projectId: "supermixtest-1cd88",
  // storageBucket: "supermixtest-1cd88.appspot.com",
  // messagingSenderId: "424873199060",
  // appId: "1:424873199060:web:fef6b7dd968c100e5cc241",
  //Local FirConfig  end
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// const messaging = getMessaging(app);

export { db, storage };
