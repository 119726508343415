import React, { useState, useEffect } from "react";
import {
  weddingActivity,
  sweet16Activity,
  mitzvahActivity,
  otherDefault,
} from "./activitiesDropdownList";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ImCross } from "react-icons/im";
import { MdAdd } from "react-icons/md";
import { BallTriangle } from "react-loader-spinner";
import Swal from "sweetalert2";
import SweetAlertPopup from "../sweetAlertPopup/SweetAlertPopup";
import * as $ from "jquery";

const Plannerlatest = () => {
  const token = useSelector((state) => state?.userDetail?.userToken);
  console.log(token, "check_msp");
  const location = useLocation();
  let eventId = location.state.id;
  const [selectList, setSelectList] = useState([]);
  const [occasion, setOccasion] = useState([]);
  const [event_type, setEvent_type] = useState([]);
  const [loading, setLoading] = useState(true);
  const [plannerId, setPlannerId] = useState("");
  const [diffrence, setDiffrence] = useState();
  const [message, setMessage] = useState("");
  const [isStart, setIsStart] = useState(false);

  const initialActivityData = {
    start_time_main: "",
    end_time_main: "",
    activity_main: "",
    // isValidMain: false,
    room_name: "",
    room_type: "indoor",
    childArr: [],
  };

  const initialChildData = {
    start_time_child: "",
    activity_child: "",
    isValidChild: false,
    subChildArr: [],
  };

  const [plannerData, setPlannerData] = useState([initialActivityData]);
  const [duplicate, setDuplicate] = useState([]);

  const changeHandler = (value, activityIndex) => {
    setPlannerData((prev) => {
      const data = [...prev];
      data[activityIndex].room_name = "";
      data[activityIndex].start_time_main = "";
      data[activityIndex].end_time_main = "";
      data[activityIndex].activity_main = value;

      data[activityIndex].childArr = [];
      console.log(data, "data");
      data[activityIndex].childArr.push(initialChildData);
      return data;
    });
  };

  const changeHandlerChild = (value, activityIndex, ChildactivityIndex) => {
    setPlannerData((prev) => {
      const data = [...prev];

      const index = selectList?.findIndex(
        (e) => e.name == data[activityIndex].activity_main
      );

      var ChildDrop = [];
      duplicate.forEach((dupData, mainIndex) => {
        if (mainIndex === activityIndex) {
          dupData.childArr.forEach((childDupData, childIndx) => {
            if (
              childDupData.activity_child == value &&
              ChildactivityIndex === childIndx
            ) {
              if (!ChildDrop.length) {
                ChildDrop = childDupData.subChildArr;
              }
            }
          });
        }
      });
      console.log(value, selectList, index);
      let subChild = JSON.parse(
        JSON.stringify(getChildActivityList(value, selectList, index)[0])
      );

      if (ChildDrop && ChildDrop.length && ChildDrop[activityIndex]) {
        subChild = ChildDrop[activityIndex];
      }
      data[activityIndex].childArr[ChildactivityIndex].activity_child = value;

      data[activityIndex].childArr[ChildactivityIndex].subChildArr = [];
      data[activityIndex].childArr[ChildactivityIndex].subChildArr.push(
        subChild
      );
      return JSON.parse(JSON.stringify(data));
    });
  };

  const InputChange = (
    value,
    name,
    activityIndex,
    ChildactivityIndex,
    subChildIndex
  ) => {
    setPlannerData((prev) => {
      const data = [...prev];
      if (name === "start_time") {
        data[activityIndex].start_time_main = value;
      } else if (name === "end_time") {
        data[activityIndex].end_time_main = value;
      } else if (name === "room_name") {
        data[activityIndex].room_name = value;
      } else if (name === "room_type") {
        data[activityIndex].room_type = value;
      } else if (name === "start_time_child") {
        data[activityIndex].childArr[ChildactivityIndex].start_time_child =
          value;
      } else if (name === "person-name-1") {
        data[activityIndex].childArr[ChildactivityIndex].subChildArr[
          subChildIndex
        ].person_name_1_subChild = value;
      } else if (name === "person-name-2") {
        data[activityIndex].childArr[ChildactivityIndex].subChildArr[
          subChildIndex
        ].person_name_2_subChild = value;
      } else if (name === "song-title") {
        data[activityIndex].childArr[ChildactivityIndex].subChildArr[
          subChildIndex
        ].song_title_subChild = value;
      } else if (name === "artist") {
        data[activityIndex].childArr[ChildactivityIndex].subChildArr[
          subChildIndex
        ].artist_subChild = value;
      } else if (name === "add-youtube-link") {
        data[activityIndex].childArr[ChildactivityIndex].subChildArr[
          subChildIndex
        ].youtube_link_subChild = value;
      } else if (name === "notes") {
        data[activityIndex].childArr[ChildactivityIndex].subChildArr[
          subChildIndex
        ].notes_subChild = value;
      } else if (name === "Relation-to-Guest-Of-Honor") {
        data[activityIndex].childArr[ChildactivityIndex].subChildArr[
          subChildIndex
        ].relation_to_guest_of_honors_subChild = value;
      } else if (name === "person-name") {
        data[activityIndex].childArr[ChildactivityIndex].subChildArr[
          subChildIndex
        ].person_name_subChild = value;
      } else {
        console.log("InputChange");
      }
      return data;
    });
  };

  const addRow = (e) => {
    var useradd = plannerData.slice();
    useradd.push(initialActivityData);
    setPlannerData(useradd);
  };

  const addRowChild = (activityIndex, ChildactivityIndex) => {
    setPlannerData((prev) => {
      const data = [...prev];
      data[activityIndex].childArr.slice();
      data[activityIndex].childArr.push(initialChildData);
      return data;
    });
  };

  const addRowSubChild = (activityIndex, ChildactivityIndex, subChildIndex) => {
    setPlannerData((prev) => {
      const data = [...prev];
      let addNew = JSON.parse(
        JSON.stringify(
          data[activityIndex].childArr[ChildactivityIndex].subChildArr[0]
        )
      );
      for (const key in addNew) {
        addNew[key] = "";
      }
      data[activityIndex].childArr[ChildactivityIndex].subChildArr.push(addNew);
      return data;
    });
  };

  // let item = plannerData.map(startEnd);

  // for (let i = 0; i < item.length; i++) {
  //   start_time_main_var = item[i][0];
  //   end_time_main_var = item[i][1];
  // }

  // function startEnd(item) {
  //   return [item.start_time_main, item.end_time_main];
  // }

  // var childItem = plannerData.map((ele, index) => {
  //   return ele.childArr.map(ChildTime);
  // });

  // for (let i = 0; i < childItem.length; i++) {
  //   start_time_child_var = childItem[i][0];
  // }

  // function ChildTime(childItem) {
  //   return childItem.start_time_child;
  // }

  const Save = (keyName, activityIndex) => {
    let hasErrors = false;
    plannerData.forEach((mainActivityData, mainIndex) => {
      $(`.redStartMain_${mainIndex}`).css("border", "1px solid black");
      $(`.redEndMain_${mainIndex}`).css("border", "1px solid black");
      $(`.redRoomain_${mainIndex}`).css("border", "1px solid black");

      if (!hasErrors) {
        let startTimeJson =
          mainActivityData["start_time_main"]?.split(":")[0] * 60 * 60 +
          mainActivityData["start_time_main"]?.split(":")[1] * 60;

        let endTimeJson =
          mainActivityData["end_time_main"]?.split(":")[0] * 60 * 60 +
          mainActivityData["end_time_main"]?.split(":")[1] * 60;

        if (
          keyName === "saveAllActivity" &&
          ((mainActivityData.activity_main !== "Music & Crowd Description" &&
            (startTimeJson >= endTimeJson ||
              mainActivityData["start_time_main"] === "" ||
              mainActivityData["end_time_main"] === "")) ||
            mainActivityData.activity_main === "" ||
            mainActivityData.room_name === "")
        ) {
          if (!hasErrors) {
            let errorMsg = "";
            let subErrorMsg = "";
            if (mainActivityData.activity_main === "") {
              $(`.redStartActivityMain_${mainIndex}`).css(
                "border",
                "1px solid red"
              );
              errorMsg += "Select Activity&nbsp";
              subErrorMsg += "activity should not be blank ";
            }
            if (mainActivityData.room_name === "") {
              $(`.redRoomain_${mainIndex}`).css("border", "3px solid red");
              errorMsg += "Fill room name ";
              subErrorMsg += "Please fill room name";
            }
            if (
              startTimeJson >= endTimeJson ||
              mainActivityData["start_time_main"] === "" ||
              mainActivityData["end_time_main"] === ""
            ) {
              if (
                mainActivityData.activity_main !== "Music & Crowd Description"
              ) {
                $(`.redStartMain_${mainIndex}`).css("border", "1px solid red");
                $(`.redEndMain_${mainIndex}`).css("border", "1px solid red");
                errorMsg += "Select Valid Time";
                subErrorMsg += "start time should be less than end time";
              }
            }
            hasErrors = true;
            SweetAlertPopup(errorMsg, subErrorMsg, "warning", "");
          }
        }

        let childActivityData = mainActivityData["childArr"];
        childActivityData.forEach((childData, childIndex) => {
          $(`.redSelectChild_${mainIndex}_${childIndex}`).css(
            "border",
            "1px solid black"
          );

          $(`.redStartChild_${mainIndex}_${childIndex}`).css(
            "border",
            "1px solid black"
          );

          let childTimeJson =
            childData["start_time_child"]?.split(":")[0] * 60 * 60 +
            childData["start_time_child"]?.split(":")[1] * 60;
          if (
            (keyName === "saveAllActivity" &&
              (!(
                childTimeJson >= startTimeJson &&
                childTimeJson < endTimeJson &&
                mainActivityData.activity_main !== "Music & Crowd Description"
              ) ||
                (!childTimeJson &&
                  mainActivityData.activity_main !==
                    "Music & Crowd Description"))) ||
            childData.activity_child === ""
          ) {
            if (!hasErrors) {
              let errorMsg = "";
              let subErrorMsg = "";
              if (childData.activity_child === "") {
                errorMsg += `Select Activity&nbsp`;
                subErrorMsg += "activity should not be blank.";

                $(`.redSelectChild_${mainIndex}_${childIndex}`).css(
                  "border",
                  "1px solid red"
                );
              }
              if (
                !(
                  childTimeJson > startTimeJson && childTimeJson < endTimeJson
                ) ||
                !childTimeJson
              ) {
                if (
                  mainActivityData.activity_main !== "Music & Crowd Description"
                ) {
                  errorMsg += "Select Valid Time";
                  subErrorMsg +=
                    "time should be In-between start time  and end time";
                  $(`.redStartChild_${mainIndex}_${childIndex}`).css(
                    "border",
                    "1px solid red"
                  );
                }
              }
              if (errorMsg) {
                hasErrors = true;
                SweetAlertPopup(errorMsg, subErrorMsg, "warning", "");
              }
            }
          }

          let subActivityData = childData["subChildArr"];

          subActivityData.forEach((item, SubChildIndex) => {
            Object.keys(item).map((sub, index) => {
              $(
                `.redInputSubChild_${mainIndex}_${childIndex}_${SubChildIndex}`
              ).css("border", "1px solid black");
              if (item[sub] == "") {
                if (!hasErrors) {
                  hasErrors = true;
                  SweetAlertPopup(
                    "All fields must be filled out",
                    " Make sure all fields are filled out if not required delete fields",
                    "warning",
                    ""
                  );
                }
                $(
                  `.redInputSubChild_${mainIndex}_${childIndex}_${SubChildIndex}`
                ).css("border", "1px solid red");
              }
            });
          });
        });
      }
    });

    if (!hasErrors) {
      fetch(`${process.env.REACT_APP_BASE_URL}eventsplan`, {
        method: "post",
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_id: JSON.stringify(eventId),
          id: JSON.stringify(plannerId),
          data: plannerData,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.result === 1) {
            if (keyName === "deletePlannerData") {
              SweetAlertPopup(
                "Data is Deleted",
                "Deleted Data",
                "success",
                "green"
              );
              eventList();
            } else if (keyName === "saveAllActivity") {
              setIsStart(false);
              SweetAlertPopup(
                "Successfully Save",
                "Data Save",
                "success",
                "green"
              );
              eventList();
            }
          }
        })
        .catch((err) => {
          Swal.fire("Something Went Wrong", err, "error");
        });
    }
  };

  const deleteRowPlanner = (activity, activityIndex) => {
    plannerData.splice(activityIndex, 1);
    setPlannerData([...plannerData]);
    Save("deletePlannerData");
  };

  const deleteRowPlannerChild = (activityIndex, ChildactivityIndex) => {
    plannerData[activityIndex].childArr.splice(ChildactivityIndex, 1);
    setPlannerData([...plannerData]);
    Save("deletePlannerData");
  };

  const deleteRowPlannersubChild = (
    activityIndex,
    ChildactivityIndex,
    subChildIndex
  ) => {
    plannerData[activityIndex].childArr[ChildactivityIndex].subChildArr.splice(
      subChildIndex,
      1
    );
    setPlannerData([...plannerData]);
    Save("deletePlannerData");
  };

  const eventList = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}eventdetails`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        var event_planner = res?.data?.EventPlan?.event_planner;
        var occasion = res?.data.EventInfo.occasion;
        var event_type = res?.data.EventInfo.event_type;
        var id = res?.data?.EventPlan.id;
        setPlannerId(id);
        setOccasion(occasion);
        setEvent_type(event_type);
        if (event_type == "In Person" && occasion == "Sweet 16's") {
          setSelectList(sweet16Activity);
        } else if (event_type == "In Person" && occasion == "Weddings") {
          setSelectList(weddingActivity);
        } else if (event_type == "In Person" && occasion == "Mitzvah's") {
          setSelectList(mitzvahActivity);
        } else if (
          event_type == "In Person" &&
          occasion == "Default (generic)"
        ) {
          setSelectList(otherDefault);
        } else {
          return null;
        }
        if (event_planner) {
          setPlannerData(event_planner);
          setDuplicate(JSON.parse(JSON.stringify(event_planner)));
        } else {
          setPlannerData([initialActivityData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    eventList();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {loading && (
        <div className="wholeScreen">
          <div className="spinner_design">
            <BallTriangle
              heigth="100"
              width="100"
              color="white"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
      )}
      <div className="w-100">
        <div className="container">
          {plannerData.map((activity, activityIndex) => {
            return (
              <>
                <h3 className="title_planner">{activity.activity_main}</h3>
                <div className="row">
                  <Planner
                    activity={activity}
                    activityIndex={activityIndex}
                    selectList={selectList}
                    message={message}
                    activityChangeHandler={(value) =>
                      changeHandler(value, activityIndex)
                    }
                    ChildActivityChangeHandler={(value, ChildactivityIndex) => {
                      changeHandlerChild(
                        value,
                        activityIndex,
                        ChildactivityIndex
                      );
                    }}
                    subChildActivityChangeHandler={() => {}}
                    InputChange={InputChange}
                    addRowChild={addRowChild}
                    addRowSubChild={addRowSubChild}
                    deleteRowPlannerChild={deleteRowPlannerChild}
                    deleteRowPlanner={deleteRowPlanner}
                    deleteRowPlannersubChild={deleteRowPlannersubChild}
                    Save={Save}
                    isStart={isStart}
                    plannerData={plannerData}
                  />
                </div>
              </>
            );
          })}
          <button className="btn btn-primary my-2" onClick={(e) => addRow(e)}>
            Add Activity
          </button>

          <button
            className="btn btn-primary my-2"
            onClick={(e) => Save("saveAllActivity")}
          >
            Save All Activity
          </button>
        </div>
      </div>
    </div>
  );
};

export default Plannerlatest;

const Planner = ({
  activityChangeHandler,
  ChildActivityChangeHandler,
  activity,
  subChildActivityChangeHandler,
  ChildactivityIndex,
  activityIndex,
  selectList,
  InputChange,
  addRowChild,
  id,
  subChildIndex,
  addRowSubChild,
  deleteRowPlanner,
  deleteRowPlannerChild,
  deleteRowPlannersubChild,
  message,
  isSave,
  isStart,
  plannerData,
}) => {
  const activitylen = activity.childArr
    ? 12 / (Object.keys(activity).length - 1)
    : 12 / Object.keys(activity).length;
  return (
    <>
      <div className="col-12">
        <div
          id={activityIndex}
          className="form-group plannerMain  row mt-0 mb-4 addDel"
        >
          <div
            className={`col-md-6 pl-0 ${
              activity.activity_main !== "Music & Crowd Description"
                ? "col-md-6 pl-0"
                : "col-md-12 pl-0"
            }`}
          >
            <select
              value={activity?.activity_main}
              id="exampleFormControlSelect2"
              className={`w-100 redStartActivityMain_${activityIndex}`}
              onChange={(e) =>
                activityChangeHandler(e.target.value, ChildactivityIndex)
              }
            >
              <option style={{ display: "none" }}>Select Activity</option>

              {selectList
                ? selectList.map((ele, index) => {
                    return (
                      <option key={index} selected>
                        {ele.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          {/* hear we remove time functionality when  Music & Crowd Description comes  */}
          {activity.activity_main !== "Music & Crowd Description" && (
            <div className="col-md-6 pl-0">
              <div className="row">
                <div className="col-sm-6 pr-0">
                  <input
                    className={`w-100 redStartMain_${activityIndex}`}
                    placeholder="Start Time"
                    name="start_time"
                    type="time"
                    style={{ cursor: "pointer" }}
                    defaultValue={activity.start_time_main || ""}
                    // value={activity.start_time_main}
                    onChange={(e) =>
                      InputChange(
                        e.target.value,
                        e.target.name,
                        activityIndex,
                        ChildactivityIndex
                      )
                    }
                  />
                </div>

                <div className="col-sm-6 pr-0">
                  <input
                    style={{ cursor: "pointer" }}
                    className={`w-100 redEndMain_${activityIndex}`}
                    placeholder="End Time"
                    type="time"
                    defaultValue={activity.end_time_main || ""}
                    name="end_time"
                    onChange={(e) =>
                      InputChange(
                        e.target.value,
                        e.target.name,
                        activityIndex,
                        ChildactivityIndex
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}

          <span className="col-md-1  crossMain_Span">
            <button
              onClick={(e) => {
                deleteRowPlanner(activity, activityIndex);
              }}
              className="btn btn-primary my-2 crossMain "
            >
              <ImCross />
            </button>
          </span>
          {/*--------------------------------------------> room Start <-----------------------------------------------*/}
          <>
            {activity.activity_main !== "Music & Crowd Description" && (
              <div className="col-12">
                <div
                  id={activityIndex}
                  className="form-group plannerMain  backgroundRoom row"
                >
                  <div className={`col-md-6 pl-0`}>
                    <input
                      placeholder="Please Enter Room Name"
                      type="text"
                      className={`redRoomain_${activityIndex}`}
                      name="room_name"
                      value={activity.room_name}
                      onChange={(e) =>
                        InputChange(
                          e.target.value,
                          e.target.name,
                          activityIndex,
                          activityIndex
                        )
                      }
                    />
                  </div>
                  <div className="col-md-6 pl-0">
                    <div className="row">
                      <div className="col-sm-6 pr-0">
                        <div className="Indoor_Outdoor">
                          <span>In-door</span>
                          <input
                            style={{ cursor: "pointer" }}
                            className={`w-100 redEndMain_${activityIndex}`}
                            type="radio"
                            name={`room_type_${activityIndex}`}
                            value="indoor"
                            checked={activity.room_type === "indoor"}
                            onChange={(e) =>
                              InputChange(
                                e.target.value,
                                "room_type",
                                activityIndex,
                                activityIndex
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 pr-0">
                        <div className="Indoor_Outdoor">
                          <span>Out-door</span>
                          <input
                            style={{ cursor: "pointer" }}
                            className={`w-100 redEndMain_${activityIndex}`}
                            type="radio"
                            name={`room_type_${activityIndex}`}
                            value="outdoor"
                            checked={activity.room_type === "outdoor"}
                            onChange={(e) =>
                              InputChange(
                                e.target.value,
                                "room_type",
                                activityIndex,
                                activityIndex
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
          {/*--------------------------------------------> room End <-----------------------------------------------*/}
          {activity.childArr &&
            activity.childArr.map((childActivity, ChildactivityIndex) => {
              return (
                <div style={{ marginTop: "15px" }}>
                  <h5 className="title_planner_child">
                    {childActivity?.activity_child}
                  </h5>
                  <div className="p-0 plannerChild">
                    <ChildPlanner
                      childActiviesList={getChildActivityList(
                        activity.activity_main,
                        selectList
                      )}
                      ChildActivityChangeHandler={ChildActivityChangeHandler}
                      subChildActivityChangeHandler={
                        subChildActivityChangeHandler
                      }
                      childActivity={childActivity}
                      ChildactivityIndex={ChildactivityIndex}
                      InputChange={InputChange}
                      addRowSubChild={addRowSubChild}
                      addRowChild={addRowChild}
                      subChildIndex={subChildIndex}
                      activityIndex={activityIndex}
                      deleteRowPlannerChild={deleteRowPlannerChild}
                      deleteRowPlannersubChild={deleteRowPlannersubChild}
                      activity={activity}
                      plannerData={plannerData}
                    />
                  </div>
                </div>
              );
            })}
          <span className="ChildPlanner_span">
            {activity.childArr && activity.childArr.length ? (
              <button
                style={{ marginLeft: "11px" }}
                className="plusIcon"
                onClick={(e) => addRowChild(activityIndex, ChildactivityIndex)}
              >
                {/* <span style={{ fontSize: "20px" }}>New Section</span> */}
                <MdAdd style={{ fontSize: "20px" }} />
              </button>
            ) : null}
          </span>
        </div>
      </div>
    </>
  );
};

const ChildPlanner = ({
  childActiviesList = [],
  ChildActivityChangeHandler,
  activity,
  activityIndex,
  subChildActivies,
  subChildActivityChangeHandler,
  subChildActivity,
  childActivity,
  InputChange,
  subChildIndex,
  addRowChild,
  ChildactivityIndex,
  deleteRowPlannerChild,
  addRowSubChild,
  deleteRowPlannersubChild,
  plannerData,
}) => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div
            className="col-md-12 addDel planner_subChild_border"
            id={ChildactivityIndex}
          >
            <div className=" d-flex addDel">
              <div
                className={`w-50 pl-0  ${
                  activity.activity_main !== "Music & Crowd Description"
                    ? "col-md-6 "
                    : "col-md-12 "
                }`}
              >
                <select
                  className={`w-100  redSelectChild_${activityIndex}_${ChildactivityIndex}`}
                  id="exampleFormControlSelect2"
                  value={childActivity.activity_child}
                  onChange={(e) => {
                    ChildActivityChangeHandler(
                      e.target.value,
                      ChildactivityIndex
                    );
                  }}
                >
                  <option style={{ display: "none" }}>Select Activity</option>
                  {childActiviesList
                    ? childActiviesList.map((e, index) => {
                        return (
                          <option key={index} selected>
                            {e.name}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
              {/* hear we remove time functionality when  Music & Crowd Description comes  */}

              {activity.activity_main !== "Music & Crowd Description" && (
                <div className="col-md-6 p-0">
                  <input
                    className={`w-100  redStartChild_${activityIndex}_${ChildactivityIndex}`}
                    placeholder="Start Time"
                    name="start_time_child"
                    value={childActivity.start_time_child}
                    type="time"
                    style={{ cursor: "pointer" }}
                    onChange={(e) =>
                      InputChange(
                        e.target.value,
                        e.target.name,
                        activityIndex,
                        ChildactivityIndex
                      )
                    }
                  />
                </div>
              )}

              {/* <div className="col-md-6 p-0">
                <input
                  className={`w-100  redStartChild_${activityIndex}_${ChildactivityIndex}`}
                  placeholder="Start Time"
                  name="start_time_child"
                  value={childActivity.start_time_child}
                  type="time"
                  style={{ cursor: "pointer" }}
                  onChange={(e) =>
                    InputChange(
                      e.target.value,
                      e.target.name,
                      activityIndex,
                      ChildactivityIndex
                    )
                  }
                />
              </div> */}
            </div>

            <button
              style={{ width: "auto" }}
              className="btn btn-primary my-2 crossMain "
              onClick={(e) =>
                deleteRowPlannerChild(activityIndex, ChildactivityIndex)
              }
            >
              <ImCross />
            </button>

            <>
              {childActivity.subChildArr &&
                childActivity.subChildArr.map(
                  (subChildActivity, subChildIndex) => {
                    return (
                      <div className="plannerSubchild mt-4">
                        <SubChildPlanner
                          subChildActivityChangeHandler={
                            subChildActivityChangeHandler
                          }
                          subChildActivity={subChildActivity}
                          childActiviesList={childActiviesList}
                          InputChange={InputChange}
                          subChildIndex={subChildIndex}
                          activityIndex={activityIndex}
                          addRowChild={addRowChild}
                          ChildactivityIndex={ChildactivityIndex}
                          addRowSubChild={addRowSubChild}
                          deleteRowPlannersubChild={deleteRowPlannersubChild}
                        />

                        <span className="subchildDelete">
                          <button
                            className="md-2 subchildDeletebtn"
                            onClick={(e) =>
                              deleteRowPlannersubChild(
                                activityIndex,
                                ChildactivityIndex,
                                subChildIndex
                              )
                            }
                          >
                            <ImCross />
                          </button>
                        </span>
                      </div>
                    );
                  }
                )}
              <span className="text-center ">
                {childActivity.subChildArr &&
                childActivity.subChildArr.length ? (
                  <button
                    className="plusIcon"
                    onClick={() =>
                      addRowSubChild(
                        activityIndex,
                        ChildactivityIndex,
                        SubChildPlanner
                      )
                    }
                  >
                    <MdAdd style={{ fontSize: "20px" }} />
                  </button>
                ) : null}
              </span>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubChildPlanner = ({
  subChildActivity,
  childActiviesList,
  subChildActivityChangeHandler,
  InputChange,
  activityIndex,
  subChildIndex,
  ChildactivityIndex,
  addRowSubChild,
}) => {
  const objlen = 12 / Object.keys(subChildActivity).length;
  return (
    <div>
      <div className="allInputs">
        {hasOwnProperty(subChildActivity, "person_name_subChild") && (
          <input
            // className={`mr-1 col-md-${objlen}`}
            className={`mr-1 col-md-${objlen} redInputSubChild_${activityIndex}_${ChildactivityIndex}_${subChildIndex}`}
            placeholder="Person Name"
            name="person-name"
            value={subChildActivity.person_name_subChild || ""}
            onChange={(e) =>
              InputChange(
                e.target.value,
                e.target.name,
                activityIndex,
                ChildactivityIndex,
                subChildIndex
              )
            }
          />
        )}
        {hasOwnProperty(subChildActivity, "person_name_1_subChild") && (
          <input
            // className={` mr-1 col-md-${objlen}`}
            className={`mr-1 col-md-${objlen} redInputSubChild_${activityIndex}_${ChildactivityIndex}_${subChildIndex}`}
            placeholder="Person Name-1"
            name="person-name-1"
            // value={subChildActivity.person_name_1_subChild}
            value={subChildActivity.person_name_1_subChild || ""}
            onChange={(e) =>
              InputChange(
                e.target.value,
                e.target.name,
                activityIndex,
                ChildactivityIndex,
                subChildIndex
              )
            }
          />
        )}

        {hasOwnProperty(subChildActivity, "person_name_2_subChild") && (
          <input
            // className={` mr-1 col-md-${objlen}`}
            className={`mr-1 col-md-${objlen} redInputSubChild_${activityIndex}_${ChildactivityIndex}_${subChildIndex}`}
            placeholder="Person Name-2"
            name="person-name-2"
            // value={subChildActivity.person_name_2_subChild}
            value={subChildActivity.person_name_2_subChild || ""}
            onChange={(e) =>
              InputChange(
                e.target.value,
                e.target.name,
                activityIndex,
                ChildactivityIndex,
                subChildIndex
              )
            }
          />
        )}
        {hasOwnProperty(
          subChildActivity,
          "relation_to_guest_of_honors_subChild"
        ) && (
          <input
            // className={`mr-1 col-md-${objlen}`}
            className={`mr-1 col-md-${objlen} redInputSubChild_${activityIndex}_${ChildactivityIndex}_${subChildIndex}`}
            placeholder="Relation To Guest Of Honor"
            name="Relation-to-Guest-Of-Honor"
            // value={subChildActivity.RelationtoGuestofHonors}
            value={subChildActivity.relation_to_guest_of_honors_subChild || ""}
            onChange={(e) =>
              InputChange(
                e.target.value,
                e.target.name,
                activityIndex,
                ChildactivityIndex,
                subChildIndex
              )
            }
          />
        )}

        {hasOwnProperty(subChildActivity, "song_title_subChild") && (
          <input
            // className={`mr-1 col-md-${objlen}`}
            className={`mr-1 col-md-${objlen} redInputSubChild_${activityIndex}_${ChildactivityIndex}_${subChildIndex}`}
            placeholder={`Song Title ${subChildActivity.song_title_subChild}`}
            name="song-title"
            value={subChildActivity.song_title_subChild || ""}
            // defaultValue={subChildActivity.song_title_subChild || ""}
            onChange={(e) =>
              InputChange(
                e.target.value,
                e.target.name,
                activityIndex,
                ChildactivityIndex,
                subChildIndex
              )
            }
          />
        )}
        {hasOwnProperty(subChildActivity, "artist_subChild") && (
          <input
            // className={`mr-1 col-md-${objlen}`}
            className={`mr-1 col-md-${objlen} redInputSubChild_${activityIndex}_${ChildactivityIndex}_${subChildIndex}`}
            placeholder="Artist"
            name="artist"
            // value={subChildActivity.artist_subChild}
            value={subChildActivity.artist_subChild || ""}
            onChange={(e) =>
              InputChange(
                e.target.value,
                e.target.name,
                activityIndex,
                ChildactivityIndex,
                subChildIndex
              )
            }
          />
        )}
        {hasOwnProperty(subChildActivity, "youtube_link_subChild") && (
          <input
            // className={`mr-1 col-md-${objlen}`}
            className={`mr-1 col-md-${objlen} redInputSubChild_${activityIndex}_${ChildactivityIndex}_${subChildIndex}`}
            placeholder="Add Youtube Link"
            name="add-youtube-link"
            // value={subChildActivity.youtube_link_subChild}
            value={subChildActivity.youtube_link_subChild || ""}
            onChange={(e) =>
              InputChange(
                e.target.value,
                e.target.name,
                activityIndex,
                ChildactivityIndex,
                subChildIndex
              )
            }
          />
        )}
        {hasOwnProperty(subChildActivity, "notes_subChild") && (
          <input
            // className={`mr-1 col-md-${objlen}`}
            className={`col-md-${objlen} redInputSubChild_${activityIndex}_${ChildactivityIndex}_${subChildIndex}`}
            placeholder="Notes"
            // defaultValue={subChildActivity.notes_subChild}
            value={subChildActivity.notes_subChild || ""}
            name="notes"
            onChange={(e) =>
              InputChange(
                e.target.value,
                e.target.name,
                activityIndex,
                ChildactivityIndex,
                subChildIndex
              )
            }
          />
        )}
      </div>
    </div>
  );
};

const hasOwnProperty = (object, property) => {
  // console.log(object.hasOwnProperty(property), "ddhd");
  return object.hasOwnProperty(property);
};

// let class_count = 12 / Object.keys(childSelect[0]).length;

const getChildActivityList = (name, selectList, index) => {
  // alert(name);
  if (typeof index !== "undefined") {
    const child = selectList[index].childArr.find((data) => data.name === name);
    return child ? child.subChildArr : [];
  } else {
    const activity = selectList?.find((data) => data.name === name);
    return activity ? activity.childArr : [];
  }
};
