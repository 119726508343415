import React from "react";
import "./Activity.css";

function Activity() {
  return (
    <div className="container">
      <h4 className="hedding-date text-center">Wednesday</h4>
      <hr />
      <div className="row no-gutters">
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/1.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right text-muted small">7:00 AM</div>
              <h5 className="card-title">
                Day 1 Wrap-up <a href="#">Introduction and get</a>
              </h5>
              <p className="card-text">
                Welcome to the campus, introduction and get started with the
                tour.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm"></div>
      </div>

      <div className="row no-gutters">
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/2.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right small">8:30 AM</div>
              <h5 className="card-title">
                Day 2 Sessions <a href="#">Introduction and get</a>
              </h5>
              <p className="card-text">
                Sign-up for the lessons and speakers that coincide with your
                course syllabus. Meet and greet with instructors.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm"></div>
      </div>

      <div className="row no-gutters">
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/1.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right text-muted small">11:30 AM</div>
              <h5 className="card-title">
                Day 4 Wrap-up <a href="#">Introduction and get</a>
              </h5>
              <p>
                Join us for lunch in Bootsy's cafe across from the Campus
                Center.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm"></div>
      </div>

      <div className="row no-gutters">
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/2.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right text-muted small">11:30 AM</div>
              <h5 className="card-title">
                Day 4 Wrap-up <a href="#">Introduction and get</a>
              </h5>
              <p>
                Join us for lunch in Bootsy's cafe across from the Campus
                Center.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm"></div>
      </div>

      <h4 className="hedding-date text-center">Friday, 29th April</h4>
      <hr style={{ borderTop: "1px solid rgb(0 0 0 / 48%)" }} />
      <div className="row no-gutters">
        <div className="col-sm"></div>

        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>

        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/2.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right text-muted small">7:00 AM</div>
              <h5 className="card-title">
                Day 1 Wrap-up <a href="#">Introduction and get</a>
              </h5>
              <p className="card-text">
                Welcome to the campus, introduction and get started with the
                tour.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-sm"></div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/1.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right small">8:30 AM</div>
              <h5 className="card-title">
                Day 2 Sessions <a href="#">Introduction and get</a>
              </h5>
              <p className="card-text">
                Sign-up for the lessons and speakers that coincide with your
                course syllabus. Meet and greet with instructors.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-sm"></div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/2.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right text-muted small">11:30 AM</div>
              <h5 className="card-title">
                Day 4 Wrap-up <a href="#">Introduction and get</a>
              </h5>
              <p>
                Join us for lunch in Bootsy's cafe across from the Campus
                Center.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-sm"></div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/2.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right text-muted small">11:30 AM</div>
              <h5 className="card-title">
                Day 4 Wrap-up <a href="#">Introduction and get</a>
              </h5>
              <p>
                Join us for lunch in Bootsy's cafe across from the Campus
                Center.
              </p>
            </div>
          </div>
        </div>
      </div>

      <h4 className="hedding-date text-center">Monday, 1th May</h4>
      <hr style={{ borderTop: "1px solid rgb(0 0 0 / 48%)" }} />
      <div className="row no-gutters">
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/1.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right text-muted small">7:00 AM</div>
              <h5 className="card-title">
                Day 1 Wrap-up <a href="#">Introduction and get</a>
              </h5>
              <p className="card-text">
                Welcome to the campus, introduction and get started with the
                tour.
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm"></div>
      </div>

      <div className="row no-gutters">
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/2.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right small">8:30 AM</div>
              <h5 className="card-title">
                Day 2 Sessions <a href="#">Introduction and get</a>
              </h5>
              <p className="card-text">
                Sign-up for the lessons and speakers that coincide with your
                course syllabus. Meet and greet with instructors.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm"></div>
      </div>

      <div className="row no-gutters">
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/1.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right text-muted small">11:30 AM</div>
              <h5 className="card-title">
                Day 4 Wrap-up <a href="#">Introduction and get</a>
              </h5>
              <p>
                Join us for lunch in Bootsy's cafe across from the Campus
                Center.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm"></div>
      </div>

      <div className="row no-gutters">
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <a href="#">
                <img
                  src="img/2.jpg"
                  className="frofile-img float-left mr-3"
                  alt="profile"
                />
              </a>
              <div className="float-right text-muted small">11:30 AM</div>
              <h5 className="card-title">
                Day 4 Wrap-up <a href="#">Introduction and get</a>
              </h5>
              <p>
                Join us for lunch in Bootsy's cafe across from the Campus
                Center.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
          <div className="row h-50">
            <div className="col border-right">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
          <h5 className="m-1">
            <span className="badge badge-pill bg-light border">&nbsp;</span>
          </h5>
          <div className="row h-50">
            <div className="col">&nbsp;</div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
        <div className="col-sm"></div>
      </div>
    </div>
  );
}
export default Activity;
