import React from "react";
import "../App.css";
import "./Logout.css";
function Logout() {
  return (
    <div className="Main-all-project d-flex justify-content-center">
      <div className="align-items-center">
        <div className="wrapper-1">
          <div className="wrapper-2">
            <h1 className="kkkk">Thank you !</h1>
            <p>Thanks for working with us Today. </p>
            <p>Stay Well and come soon </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logout;
