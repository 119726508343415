import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { ReactSortable } from "react-sortablejs";
import { AiFillDelete } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import { MdOutlineDownloading } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";

import { BallTriangle } from "react-loader-spinner";
import "../App.css";
import Docs from "../Doccs/Docs";

function Docdoc() {
  const token = useSelector((state) => state?.userDetail?.userToken);
  const [docu, setdocu] = useState([]);
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(true);
  const accpetedDocTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const pdfDownloadLink = useRef();
  const location = useLocation();
  let eventId = location.state.id;

  const navigate = useNavigate();
  const errorHandler = (err) => {
    navigate("/Error");
  };

  document.addEventListener("DOMContentLoaded", (e) => {
    var dragSrcEl = null;

    function handleDragStart(e) {
      this.style.opacity = "1";

      dragSrcEl = this;

      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/html", this.innerHTML);
    }

    function handleDragOver(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      e.dataTransfer.dropEffect = "move";

      return false;
    }

    function handleDragEnter(e) {
      this.classList.add("over");
    }

    function handleDragLeave(e) {
      this.classList.remove("over");
    }

    function handleDrop(e) {
      if (e.stopPropagation) {
        e.stopPropagation();
      }

      if (dragSrcEl != this) {
        dragSrcEl.innerHTML = this.innerHTML;
        this.innerHTML = e.dataTransfer.getData("text/html");
      }

      return false;
    }

    function handleDragEnd(e) {
      this.style.opacity = "1";

      items.forEach(function (item) {
        item.classList.remove("over");
      });
      imageHandle(e);
    }

    let items = document.querySelectorAll(".container .item");
    items.forEach(function (item) {
      item.addEventListener("dragstart", handleDragStart, false);
      item.addEventListener("dragenter", handleDragEnter, false);
      item.addEventListener("dragover", handleDragOver, false);
      item.addEventListener("dragleave", handleDragLeave, false);
      item.addEventListener("drop", handleDrop, false);
      item.addEventListener("dragend", handleDragEnd, false);
    });
  });

  const upload_doc = () => {
    if (document.getElementById("uploadDocsId").files.length == 0) {
      toast.error("Please select Document");
    } else {
      const files = document.getElementById("uploadDocsId").files;
      const DocsformData = new FormData();
      for (let i = 0; i < files.length; i++) {
        DocsformData.append("file", files[i]);
      }
      DocsformData.append("id", eventId);

      fetch(`${process.env.REACT_APP_BASE_URL}uploadFiles`, {
        method: "post",
        headers: {
          "x-access-token": `${token}`,
        },
        body: DocsformData,
      })
        .then((response) => response.json())
        .then((res) => {
          docApi();
          setFile([]);
          document.getElementById("uploadDocsId").value = "";
          toast.success("Document uploaded Successfully");
        })
        .catch((err) => {
          errorHandler(err);
        });
    }
  };

  const onDocsChange = (event) => {
    var fileInput = document.getElementById("uploadDocsId");
    var filePath = fileInput.value;

    // var allowedExtensions = /(\.pdf|\.txt|\.doc|\.docx|\.xlsx)$/i;
    for (var i = 0; i < event.target.files.length; i++) {
      if (!accpetedDocTypes.includes(event.target.files[i].type)) {
        alert("unaccepted file type");
        fileInput.value = "";
        return false;
      } else if (event.target.files[i].size > 10485760.0) {
        alert("File is too big! Maximun 10MB File is allowed");
        fileInput.value = "";
        return false;
      } else {
        if (event.target.files && event.target.files[i]) {
          setFile(event.target.files[i]);
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
    upload_doc();
  };

  const deleteDoc = (id) => {
    let recross = "Are you sure you want to Remove";
    if (window.confirm(recross) == true) {
      var remove = {
        id: id,
        event_id: eventId,
      };
      fetch(`${process.env.REACT_APP_BASE_URL}deleteEventDocs`, {
        method: "post",
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(remove),
      })
        .then((response) => response.json())
        .then((res) => {})

        .catch((err) => {
          errorHandler(err);
        });

      let ind = docu.findIndex((i) => {
        return i.id === id;
      });
      docu.splice(ind, 1);
      setdocu([...docu]);
      docApi();
    } else {
    }
  };

  const docApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}docsListing`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event_id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        var eventDoc = res?.data?.EventDocs?.Files;
        eventDoc.forEach((i) => {
          i.event_docs_path_convert = i.event_docs_path.slice(8);
          i.event_docs_path_zip = `${
            process.env.REACT_APP_BASE_URL + i.event_docs_path.slice(1)
          }`;
        });
        // ?.Image[0]?.event_docs_path
        setLoading(false);

        setdocu(eventDoc);
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const imageHandle = (e) => {
    const ids = docu.map((ele) => {
      console.log(ele.id, "docuid");
      return ele.id;
    });

    const data = {
      doc_id: ids,
      event_id: eventId,
      type: "file",
    };

    console.log(data, "dataDoc");

    fetch(`${process.env.REACT_APP_BASE_URL}sequence`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        docApi();
        toast.success("Document Arranged Successfully");
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  function downloadAll() {
    var allData = document.getElementsByClassName("downloadAll");
    let anchor = document.createElement("a");
    if (allData.length !== 0) {
      var urls = [];
      for (let i = 0; i < allData.length; i++) {
        urls.push(allData[i].getAttribute("href"));
      }

      if (urls.length == allData.length) {
        var filename = "document";
        console.log("calling");
        if (!urls) return;

        const zip = new window.JSZip();
        const folder = zip.folder("files");

        urls.forEach((url) => {
          const blobPromise = fetch(url).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
          });
          const name = url.substring(url.lastIndexOf("/") + 1);
          folder.file(name, blobPromise);
        });

        zip
          .generateAsync({ type: "blob" })
          .then((blob) => saveAs(blob, filename));
      }
    }
  }

  useEffect(() => {
    docApi();
  }, []);

  return (
    <div className="file-upload-inner">
      {loading && (
        <div className="wholeScreen">
          <div className="blur_design"> </div>
          <div className="spinner_design">
            <BallTriangle
              heigth="100"
              width="100"
              color="white"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
      )}
      <div className="file-upload">
        <div className="tab-pane p-3 active" id="image" role="tabpanel">
          <h1>Add File</h1>
          <form
            id="imageform"
            className="dropzone"
            enctype="multipart/form-data"
          >
            <div className="input-group">
              <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
              <div className="upload_image_input">
                <label htmlFor="files">Select files</label>
                <input
                  id="uploadDocsId"
                  type="file"
                  name="uploadImage"
                  multiple
                  accept={accpetedDocTypes.join(",")}
                  onChange={(e) => {
                    onDocsChange(e);
                  }}
                />
                <span className="error">
                  <p id="image_error"></p>
                </span>
              </div>
            </div>
          </form>
          <div className="text-center mt-3 row-btn">
            {/* <button
              type="button"
              onClick={(e) => {
                upload_doc(e);
              }}
              className="btn btn-primary waves-effect waves-light"
            >
              <ToastContainer className="planner" autoClose={10} />
              Upload Doc
            </button> */}
          </div>
        </div>
        {/* <div className="text-center">
          {" "}
          Accepted Files = .pdf|.txt|.doc|.docx|.xlsx
        </div> */}
      </div>
      <div className="gallery_box">
        <div className="docdoc-main-area w-100">
          <div className="docdoc_main">
            <div>
              <ul className="one-stap">
                <li>File Name</li>
                <li>File Type</li>
                <li>Upload date/time</li>
                <li>Action</li>
              </ul>
            </div>
            <div className="container">
              <ReactSortable
                list={docu}
                setList={(newlist) => {
                  setdocu(newlist);
                }}
                preventOnFilter={true}
              >
                {docu.map((ele, index) => (
                  <div key={index} className="dragHandle item chageColorChild">
                    <ul
                      className="one-stap"
                      draggable
                      onDragEnd={() => imageHandle()}
                    >
                      <li>{ele?.event_docs_path_convert}</li>
                      <li>{ele?.file_mime_type}</li>
                      <li>{moment(ele?.event_docs_created_at).format("")}</li>
                      <li>
                        <a
                          className="me-3 downloadAll"
                          target="_blank"
                          ref={pdfDownloadLink}
                          href={`${process.env.REACT_APP_BASE_URL}${ele?.event_docs_path}`}
                          download
                          rel="noreferrer"
                        >
                          <MdOutlineDownloading className="kuchBhi_cancel" />
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          onClick={() => deleteDoc(ele?.id, index)}
                          style={{ cursor: "pointer" }}
                        >
                          <MdOutlineCancel className="kuchBhi_cancel" />
                        </a>
                      </li>
                    </ul>
                  </div>
                ))}
              </ReactSortable>
            </div>
          </div>
          {docu.length ? (
            <div className="text-center mt-3 mb-2 row-btn">
              <button
                type="button"
                onClick={downloadAll}
                className="btn btn-primary waves-effect waves-light"
              >
                <ToastContainer className="planner" autoClose={10} />
                Download All
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Docdoc;
