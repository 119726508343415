import React from "react";
import Swal from "sweetalert2";

function SweetAlertPopup(message, text, icon, iconColor) {
  return Swal.fire({
    title: message,
    text: text,
    icon: icon,
    iconColor: iconColor,
  });
}

export default SweetAlertPopup;
