import React, { useState } from "react";
import "../App.css";
import Docimage from "../ImageDoc/Docimage";
import Viddoc from "../ImageDoc/Viddoc";
import Docdoc from "../ImageDoc/Docdoc";

import { BsFillImageFill } from "react-icons/bs";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { HiDocumentDuplicate } from "react-icons/hi";

const uploadTabs = [
  {
    id: "imageinfo-tab",
    href: "imageinfo",
    controls: "imageinfo",
    name: "Image",
    selected: "true",
    active: true,
    logo: () => <BsFillImageFill />,
  },
  {
    id: "video-tab",
    href: "video-demo",
    name: "Video",
    controls: "video-demo",
    selected: "false",
    active: false,
    logo: () => <MdOutlineOndemandVideo />,
  },
  {
    id: "doc-tab",
    href: "doc",
    name: "Docs",
    controls: "doc",
    selected: "false",
    active: false,
    logo: () => <HiDocumentDuplicate />,
  },
];

let onLoaded = new Set();

function Docs() {
  const defaultActiveTab = uploadTabs.find((e) => e.active);
  const [activeTab, setActiveTab] = useState(defaultActiveTab.id);
  const handleTabChange = () => {
    setTimeout(() => {
      const currentTab = document.querySelector("#doctabs a.active");
      setActiveTab(currentTab.id);
      onLoaded.add(currentTab.id);
    }, 0);
  };

  return (
    <div className="upload-panel">
      <ul className="nav nav-tabs file-tabs" id="doctabs" role="tablist">
        {uploadTabs.map((ele, index) => (
          <li className="nav-item" key={index}>
            <a
              onClick={handleTabChange}
              className={`nav-link ${ele.active ? "active" : ""}`}
              id={ele.id}
              data-toggle="tab"
              href={`#${ele.href}`}
              role="tab"
              aria-controls={ele.controls}
              aria-selected={ele.selected}
            >
              <span className="d-none d-md-block">{ele.name}</span>
              <span className="d-block d-md-none">{ele.logo()}</span>
            </a>
          </li>
        ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="imageinfo"
          role="tabpanel"
          aria-labelledby="imageinfo-tab"
        >
          <div className="product-detail-all">
            <div className="product-inner-area">
              {activeTab === "imageinfo-tab" && <Docimage />}
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="video-demo"
          role="tabpanel"
          aria-labelledby="video-tab"
        >
          <div className="product-detail-all">
            <div className="product-inner-area">
              {activeTab === "video-tab" && <Viddoc />}
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="doc"
          role="tabpanel"
          aria-labelledby="doc-tab"
        >
          <div className="product-detail-all">
            <div className="product-inner-area">
              {activeTab === "doc-tab" && <Docdoc />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Docs;
