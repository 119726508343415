import React, { useState } from "react";
import "./Template.css";
import Temp1 from "./Temp1";
import Temp2 from "./Temp2";
import { Tabs } from "react-bootstrap";
import Temp3 from "./Temp3";

function Template() {


  const Tabs = [
    {
      id: "temp1-tab",
      href: "temp1",
      controls: "temp1",
      selected: "true",
      active: true,
      name: "Template1",
      image: "Temp1.png",
    },
    {
      id: "temp2-tab",
      href: "temp2",
      controls: "temp2",
      selected: "true",
      active: false,
      name: "Template2 Main Screen ",
      image: "Temp2.png",
    },

    {
      id: "temp3-tab",
      href: "temp3",
      controls: "temp3",
      selected: "true",
      active: false,
      name: "Template2 Waitng Screen",
      image: "Temp2.png",
    },
   
  ];


  const [BirthdayName, setBirthdayName] = useState("");
  const [sbValue, setSbvalue] = useState({});

  
  // const saveButton = () => {
  //   console.log(BirthdayName, "text");
  //   setSbvalue({
  //     Name: BirthdayName,
  //   });
  // };

  return (
    
    <div className="contai">
      <div className="row mt-4">
        <div className="col-md-2 mb-3">
          <ul className="nav nav-pills flex-row justify-content-center" id="myTabb" role="tablist">
            {Tabs.map((tab) => (
              <li className="nav-item" key={tab.id}>
                <a
                  // onClick={handleTabChange}
                  className={`nav-link ${tab.active ? "active" : ""}`}
                  id={tab.id}
                  data-toggle="tab"
                  style={{textAlign:"center"}}
                  href={`#${tab.href}`}
                  // href=""  
                  role="tab"
                  aria-controls={tab.controls}
                  aria-selected={tab.selected}
                >
                  <img className="Template_size"   src={tab.image}/>
                    <span className="d-none d-md-block">{tab.name}</span>
                </a>

              </li>
              
            ))}
            
          </ul>
        </div>


        <div className="col-md-10">
          <div className="tab-content" id="myTabContent"
            style={{ display:"flex", flexDirection:"column" ,alignItems:"center" , justifyContent:"center"}}
          >
            <div
              className="tab-pane fade show active"
              id="temp1"
              role="tabpanel"
              aria-labelledby="temp1-tab"
            >
              
            <Temp1/>
            </div>

            <div
              className="tab-pane fade"
              id="temp2"
              role="tabpanel"
              aria-labelledby="temp2-tab"
            >
              <Temp2/>
            </div>

            <div
              className="tab-pane fade"
              id="temp3"
              role="tabpanel"
              aria-labelledby="temp3-tab"
            >
              <Temp3/>
            </div>
           
          </div>
        </div>
      </div>

      {/* <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          Close
        </button>

        <button type="button" class="btn btn-primary">
          Save changes
        </button>
      </div> */}
    </div>
  );
}

export default Template;
