import React, { useState, useCallback, useRef } from "react";
import "./Template.css";
import { toPng } from "html-to-image";

const Temp3 = () => {
  const [second, setSecond] = useState("test taking strategies");
  const [first, setFirst] = useState("NCLEX REVIEW");

  const ref = useRef(null);

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-image-name.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);
  return (
    <>
     
     <div>
        <div ref={ref}>
          <div
            className="tab-pane-fade"
            id="temp3"
            role="tabpanel"
            aria-labelledby="temp3-tab"
          >
            <div className="bg-img" id="my-node-3">
              <img src="images/NCLEX---COURSE-1-Recovered.jpg" alt="img" />
              <div className="three_test">
                <div className="taking_Test">
                <h3 className="main-logo" id="main-logo2">
                {first}
                </h3>
                <h3 className="sub-title" id="sub-title2">
                  {second}
                </h3>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>

        &nbsp;
        <div className="KuchBHi_InputVALA">
          <input
            className="InputFieldTemp"
            type="text"
            placeholder="Enter Your Name"
            onChange={(e) => setFirst(e.target.value)}
            id="text"
          ></input>
          <input
            className="InputFieldTemp"
            type="text"
            placeholder="Enter Message"
            onChange={(e) => setSecond(e.target.value)}
            id="text"
          ></input>
          <button onClick={onButtonClick}>Download</button>
        </div>

  
    </>
  );
};

export default Temp3;
