import React, { useState, useCallback, useRef } from "react";
import "./Template.css";
import { toPng } from 'html-to-image';

function Temp1() {
  const [BirthdayName, setBirthdayName] = useState("Enter Your Name");
 

//   const saveButton = () => {
//     console.log(BirthdayName, "text");
//     setSbvalue({
//       Name: BirthdayName,
//     });
//   };

const ref = useRef(null)

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'my-image-name.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [ref])
  return (
    <>
    <div className="main-bada-box">
      <div ref={ref}>
      <div className="bada_box" >
        <div className="box_header">
          <img src="happy.jpg" className="Happy" alt="happy" />
           </div>
        <div className="teenvala">
          <div className="pehlaval">
            <img src="sideHappy.jpg" className="imagekiWidth" alt="logo" />
          </div>
          <div className="dusravala">
            <div className="border_boxThought">
              <p className="thought">
                The world is a little bit brighter only because of you. Happy
                Birthday!
              </p>
            </div>
            <div className="birthday_Name">
              <h2 className="BirthdayName">{BirthdayName}</h2>
            </div>
            <div className="cracker">
              <img src="cakes.png" className="crackerpic" alt="cracker" />
            </div>
          </div>
          <div className="pehlaval">
            <img src="dusriside.jpg" className="imagekiWidth" alt="logo" />
          </div>
        </div>
      </div>
     
     </div>
    </div>


     &nbsp;
      <div className="KuchBHi_InputVALA">
        <input
           className="InputFieldTemp"
          type="text"
          placeholder="Enter Your Name" 
          onChange={(e) => setBirthdayName(e.target.value)}
          id="text"
        ></input>
        <button
       onClick={onButtonClick}
         >Download</button>
      </div>
      
    </>

    
  );
}

export default Temp1;
