import React from 'react'
import "../App.css"
import { Link, useNavigate } from "react-router-dom";
// import "./Footer.css"
// import { AiOutlineInstagram , AiOutlineFacebook , AiFillYoutube} from "react-icons/ai";

function Footer() {
  // const navigate = useNavigate();



  return (
    <div className='hataofooter'>
      <footer>
        <div className="container  ">
            <p>Copyright ©2023  SuperMix Entertainment
              {/* <Link to='/' style={{marginInline:'5px',cursor:'pointer' ,color:'#fff',textDecoration:'none' }}>SuperMix Entertainment</Link> */}
               All rights reserved.</p>
        </div>
    </footer>
    </div>
  )
}

export default Footer