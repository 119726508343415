import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";

import "./Activity.css";
import moment from "moment";

function Activties({ onClick }) {
  const token = useSelector((state) => state?.userDetail?.userToken);

  const [eventlog, setEventlog] = useState({});
  const location = useLocation();
  console.log(location, "location");
  let eventId = location.state.id;
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const errorHandler = (err) => {
    navigate("/Error");
  };

  const fetchActivity = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}eventLog`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event_id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        setEventlog(res);
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const obj = eventlog;

  useEffect(() => {
    fetchActivity();
  }, []);
  return (
    <div className=" chat-sheat">
      {loading && (
        <div className="wholeScreen">
          <div className="spinner_design">
            <BallTriangle
              heigth="100"
              width="100"
              color="white"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
      )}
      {Object.keys(obj).map((item, index) => {
        let isEven = index % 2 === 0;
        let isOdd = index % 2 !== 0;
        return (
          <div key={index} className="container">
            <h4 className="hedding-date text-center">{item} </h4>
            <hr />
            {obj[item].map((ele, i) => (
              <div key={i} className="row no-gutters">
                {isOdd && (
                  <>
                    <div className="col-sm"></div>
                    <BulletSeprator />
                    <GetCard ele={ele} />
                  </>
                )}

                {isEven && (
                  <>
                    <GetCard ele={ele} />
                    <BulletSeprator />
                    <div className="col-sm"></div>
                  </>
                )}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

const GetCard = ({ ele }) => (
  <div className="col-sm">
    <div className="card">
      <div className="card-body">
        <a>
          <img
            src={`${process.env.REACT_APP_BASE_URL}${ele?.image}`}
            className="frofile-img float-left mr-3"
            alt="profile"
          />
        </a>
        <div className="float-right text-muted small">
          {moment(ele?.created_at).format("hh:mm a") || " "}
        </div>
        <h5 className="card-title">
          {ele?.type} By <a>{ele?.created_by}</a>
        </h5>
        <p className="card-text">{ele?.description}</p>
      </div>
    </div>
  </div>
);

const BulletSeprator = () => (
  <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
    <div className="row h-50">
      <div className="col">&nbsp;&nbsp;&nbsp;</div>
      <div className="col">&nbsp;</div>
    </div>
    <h5 className="m-1">
      <span className="badge badge-pill bg-light border1">&nbsp;</span>
    </h5>
    <div className="row h-50">
      <div className="col border-right">&nbsp;</div>
      <div className="col">&nbsp;</div>
    </div>
  </div>
);

export default Activties;
