import React from "react";
import { useState, useEffect, useRef } from "react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { IsLoggedOut, UserUpdate } from "../Redux/user/userAction";
import { Link, useNavigate } from "react-router-dom";
import { Notifications } from "react-push-notification";
import $ from "jquery";
import { AiOutlineContacts, AiOutlineCalendar } from "react-icons/ai";

// import { TbBellRinging } from "react-icons/tb";
// import {HiOutlineChatBubbleLeftEllipsis} from "react-icons/hi";
import { HiOutlineChatAlt } from "react-icons/hi";

import { db, storage } from "../Firebase";
import {
  collection,
  updateDoc,
  getDoc,
  doc,
  onSnapshot,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import { async } from "@firebase/util";
import addNotification from "react-push-notification";

function Header({ onChange }) {
  const [image, setimage] = useState();
  const [notiarray, setNotiarray] = useState([]);
  const [countMessage, setCountMessage] = useState(false);
  const [ChatMessage, setChatmess] = useState();
  const navigate = useNavigate();

  const clickcatch = useRef();

  const isLogin = useSelector((state) => state.isLogin);
  const dispatch = useDispatch();

  // const userPic = useSelector((state) => state?.userDetail?.data)
  const userinfo = useSelector((state) => state?.userupdate);

  // const abs =async  ()=>{

  const curreent = useSelector((state) => state?.userDetail?.data?.id);

  //   const listCount = await getDoc(
  //     doc(db , "users" , "4")
  //   )
  //   console.log(listCount.data().groupConversation,"eagwgsrsege")

  // }

  // abs();

  var userid = localStorage.getItem("userid");

  const handleCloseUserMenu = () => {
    let recross = "Are you sure you want to Logout";
    if (window.confirm(recross) == true) {
      dispatch(IsLoggedOut());
      dispatch(UserUpdate());
      navigate("/Logout");
      localStorage.removeItem("userid");
    }
  };

  setTimeout(() => {
    dispatch(IsLoggedOut());
    dispatch(UserUpdate());
    navigate("/Logout");
    // console.log("in timeout");
  }, 7200000);

  useEffect(() => {
    let imageUrl = `${
      userinfo?.image?.startsWith("http") ? "" : process.env.REACT_APP_BASE_URL
    }${userinfo?.image}`;
    if (userinfo?.image.startsWith("data")) {
      imageUrl = userinfo?.image;
    }
    setimage(imageUrl);

    // console.log(userinfo, "userinfo");
  }, [userinfo]);

  const profileHandler = () => {
    navigate("/profile");
  };

  function homepage() {
    navigate("/");
  }

  //add class and remove class from nav-item
  const addActive = () => {
    $(document).ready(function () {
      $(".head-item").click(function () {
        $(".head-item").removeClass("active-now");
        $(this).addClass("active-now");
      });

      $(".accordion_user").click(function () {
        $(this).removeClass("active");
        $(this).addClass("active");
      });
    });

    // if(clickcatch.current.click()){
    //   console.log("vwrwrgsrr")
    // }
  };

  //   if(typeof curreent === undefined){
  //     console.log("undefined vala")
  //     }
  //     else{
  //     console.log("acha vala")
  //  onSnapshot(doc(db, "users", curreent.toString()), (snapshot) => {
  //        var data = snapshot.data()?.groupConversation;
  //        console.log(data, "kjb");
  //        setNotiarray(data);
  //      })
  //     }

  //  const LoadVala =async () =>{
  //   // console.log("baharlop" , updated)
  //   if(typeof curreent === Number ){
  //     console.log("y chala andar vala")
  //   }else{
  //     console.log("y chala baharrrrr vala")

  //     await onSnapshot(doc(db, "users", curreent.toString()), (snapshot) => {
  //        var data = snapshot.data()?.groupConversation;
  //        console.log(data, "kjb");
  //        setNotiarray(data);
  //      })

  //   }

  //  }

  //  useEffect(() => {
  //   //  setTimeout(() => {

  //      LoadVala();
  //   //  },0)

  //  },[])

  //  LoadVala();

  useEffect(() => {
    if (curreent !== undefined) {
      onSnapshot(
        doc(db, "users", localStorage.getItem("userid") || curreent.toString()),
        (snapshot) => {
          var data = snapshot.data()?.groupConversation;
          var data = snapshot.data()?.groupConversation;
          var data = snapshot.data()?.groupConversation;
          var data = snapshot.data()?.groupConversation;

          setNotiarray(data);
          // data.map((ele) => {
          //   if (ele.isunread > 0) {
          //     addNotification({
          //       title: "",
          //       subtitle: "",
          //       message: "Unread Message",
          //       theme: "darkblue",
          //       native: true, // when using native, your OS will handle theming.
          //     });
          //   }
          // });
        }
      );
    }
  }, []);

  // useEffect(() => {
  //     // notiarray.map((e)=>{

  //     //   // console.log(e.isunread,"ef")

  //     //   if(e.isunread > 0){
  //     //     console.log(e.isunread,"inside")
  //     //     setCountMessage(true)
  //     //     // alert("Eev")
  //     //   }else{
  //     //     console.log(e.isunread,"outside ")
  //     //     setCountMessage(false)
  //     //   }

  //     // else {
  //     //   console.log(e.isunread,"outside")

  //     //   setCountMessage(false)
  //     //   // alert("grg")

  //     // }
  //     // })
  //   }), []);

  // var dd = notiarray.map((e) => {
  //   return e.isunread;
  // });

  // if(dd.length == 0){
  //   location.reload();
  //    Max = 0;
  // }else{

  //    Max = Math.max(...dd);
  // }

  var dd = notiarray?.map((e) => {
    return e.isunread;
  });

  const Max = dd && Math.max(...dd);
  // const Max = 0
  // var Max ;
  // for(let i = 0; i < dd.length; i++) {
  //   if(dd[i] > 0){
  //     Max = Math.max(...dd, dd[i]) ;
  //   }else{
  //     Max = dd[i] ;
  //   }

  //  }

  // console.log(dd, "total");
  // console.log(Max, "Max");

  // const Max = localStorage.getItem("Counts")
  // console.log(Max, "unread");
  // const Max =0;
  // useEffect(() => {
  // window.reload();
  // },[])

  return (
    <div>
      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            {/* {Max > 0 ? (
             <span className='bell fa fa-bell'><TbBellRinging/></span>
                  
                  ) : ""} */}
            {isLogin ? (
              <>
                <div
                  className="navbar-brand m-0 "
                  style={{ cursor: "pointer" }}
                  onClick={homepage}
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}img/logo.png`}
                    alt="logo"
                  />
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mx-auto">
                    <li className="nav-item head-item">
                      <Link
                        to="/"
                        className="nav-link"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                        onClick={addActive()}
                      >
                        My Events
                      </Link>
                    </li>
                    {/* meating shedule */}
                    <li className="nav-item head-item">
                      <div style={{ width: "100%" }}>
                        <a
                          className="nav-link"
                          target=" "
                          href="https://calendly.com/supermixentertainment/planning-meeting"
                        >
                          <button
                            type="button"
                            className={` btn btn-primary d-none d-md-block `}
                            // className={`btn btn-primary  ${disabled ? "d-none" : ""}`}

                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            SCHEDULE MEETING
                          </button>
                          <div
                            className="d-block d-md-none "
                            style={{ justifyContent: "space-between" }}
                          >
                            <button
                              type="button"
                              style={{
                                border: "none",
                                background: "transparent",
                                color: "white",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              <AiOutlineCalendar
                                className="badalogo"
                                style={{
                                  width: "21px",
                                  height: "4vh",
                                }}
                              />
                            </button>
                            <span
                              style={{
                                paddingLeft: "10px",
                              }}
                            >
                              SCHEDULE MEETING
                            </span>
                          </div>
                        </a>
                      </div>
                    </li>

                    {/* <i class="fa fa-users" aria-hidden="true"></i> */}

                    {/* for participent */}

                    {/* <li className="nav-item head-item">
                      <div style={{ width: "100%" }}>
                        <a
                          className="nav-link"
                          target=" "
                          href="https://calendly.com/supermixentertainment/planning-meeting"
                        >
                          <button
                            type="button"
                            className={` btn btn-primary d-none d-md-block `}
                            // className={`btn btn-primary  ${disabled ? "d-none" : ""}`}

                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            SCHEDULE MEETING
                          </button>
                          <div
                            className="d-block d-md-none "
                            style={{ justifyContent: "space-between" }}
                          >
                            <button
                              type="button"
                              style={{
                                border: "none",
                                background: "transparent",
                                color: "white",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              <AiOutlineCalendar
                                className="badalogo"
                                style={{
                                  width: "21px",
                                  height: "4vh",
                                }}
                              />
                            </button>
                            <span
                              style={{
                                paddingLeft: "10px",
                              }}
                            >
                              SCHEDULE MEETING
                            </span>
                          </div>
                        </a>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </>
            ) : (
              <div
                className="collapse navbar-collapse"
                style={{ color: "red" }}
              >
                <>
                  <div
                    className="navbar-brand m-0 "
                    style={{ cursor: "pointer" }}
                    onClick={homepage}
                  >
                    {/* <img
                      src={`${process.env.REACT_APP_BASE_URL}img/logo.png`}
                      alt="logo"
                    /> */}
                  </div>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mx-auto">
                      <li className=" head-item">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}img/logo.png`}
                          alt="logo"
                        />
                      </li>
                      {/* meating shedule */}
                      {/* <li className="nav-item head-item">
                        <div style={{ width: "100%" }}>
                          <a
                            className="nav-link"
                            target=" "
                            href="https://calendly.com/supermixentertainment/planning-meeting"
                          >
                            <button
                              type="button"
                              className={` btn btn-primary d-none d-md-block `}
                              // className={`btn btn-primary  ${disabled ? "d-none" : ""}`}

                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              SCHEDULE MEETING
                            </button>
                            <div
                              className="d-block d-md-none "
                              style={{ justifyContent: "space-between" }}
                            >
                              <button
                                type="button"
                                style={{
                                  border: "none",
                                  background: "transparent",
                                  color: "white",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                              >
                                <AiOutlineCalendar
                                  className="badalogo"
                                  style={{
                                    width: "21px",
                                    height: "4vh",
                                  }}
                                />
                              </button>
                              <span
                                style={{
                                  paddingLeft: "10px",
                                }}
                              >
                                SCHEDULE MEETING
                              </span>
                            </div>
                          </a>
                        </div>
                      </li> */}

                      {/* <i class="fa fa-users" aria-hidden="true"></i> */}

                      {/* for participent */}

                      {/* <li className="nav-item head-item">
                      <div style={{ width: "100%" }}>
                        <a
                          className="nav-link"
                          target=" "
                          href="https://calendly.com/supermixentertainment/planning-meeting"
                        >
                          <button
                            type="button"
                            className={` btn btn-primary d-none d-md-block `}
                            // className={`btn btn-primary  ${disabled ? "d-none" : ""}`}

                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            SCHEDULE MEETING
                          </button>
                          <div
                            className="d-block d-md-none "
                            style={{ justifyContent: "space-between" }}
                          >
                            <button
                              type="button"
                              style={{
                                border: "none",
                                background: "transparent",
                                color: "white",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              <AiOutlineCalendar
                                className="badalogo"
                                style={{
                                  width: "21px",
                                  height: "4vh",
                                }}
                              />
                            </button>
                            <span
                              style={{
                                paddingLeft: "10px",
                              }}
                            >
                              SCHEDULE MEETING
                            </span>
                          </div>
                        </a>
                      </div>
                    </li> */}
                    </ul>
                  </div>
                </>
              </div>
            )}

            {isLogin && (
              <div className="header-right">
                <div
                  className="dropdown search-box nav-link"
                  id="navbtn"
                  role="button"
                  aria-expanded="false"
                >
                  <div className="chat_Valiclass">
                    {/* vhkkgk */}
                    <Link to="/chat">
                      <HiOutlineChatAlt className="chatIcon" />
                    </Link>
                    {Max > 0 ? (
                      <span className="badge bg-secondary"></span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div onClick={onChange}>
                    <Link to="/">
                      <img
                        className="search-head-icon"
                        src={`${process.env.REACT_APP_BASE_URL}img/search.png`}
                        alt="icon"
                      />
                    </Link>
                  </div>
                </div>

                <form className="user-info">
                  <li className="nav-item dropdown d-flex justify-content-center">
                    <a
                      className="nav-link"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={image}
                        // src= {userinfo?.image}
                        // src={`${process.env.REACT_APP_BASE_URL}${userPic.image}`}
                        // src= {}
                        // src={`data:image/png;base64,${userinfo?.image}`}
                        // src={`${process.env.REACT_APP_BASE_URL}${userinfo?.image}`}
                        //  src= {userinfo ? `${process.env.REACT_APP_BASE_URL}${userinfo?.image}`: userPic ?`${process.env.REACT_APP_BASE_URL}${userPic.image}`: null}
                        alt="pik"
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <a
                        className="dropdown-item"
                        style={{ display: "flex" }}
                        onClick={profileHandler}
                      >
                        Profile
                      </a>
                      <div className="dropdown-divider"></div>
                      <a
                        className="dropdown-item"
                        onClick={() => handleCloseUserMenu()}
                        style={{ display: "flex" }}
                      >
                        Sign Out
                      </a>
                    </div>
                  </li>
                </form>
              </div>
            )}
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
