import React, { useState, useRef, useEffect, useCallback } from "react";
import "./PhotoBooth.css";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import SweetAlertPopup from "../sweetAlertPopup/SweetAlertPopup";
import moment from "moment";

// import { SketchPicker } from "react-color";
import {
  HueSlider,
  SaturationSlider,
  LightnessSlider,
  // AlphaSlider,
} from "react-slider-color-picker";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { saveAs } from "file-saver";
import mergeImages from "merge-images";

import Slider from "react-slick";

// openAir
import KioskOpenAirImage from "./OpenAir_assets/Open_Air/Kiosk.png";
import PropTableImage from "./OpenAir_assets/Open_Air/Prop_Table.png";
import Red_CarptetImage from "./OpenAir_assets/Open_Air/Red_Carptet.png";

import IncludedBlack from "./OpenAir_assets/Open_Air/Backdrop/Stretch/BlackNew.png";
import IncludedWhite from "./OpenAir_assets/Open_Air/Backdrop/Stretch/WhiteNew.png";

//UpgradedBackdrop
import black_white from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Sequins/Black_White.png";
import white_gold from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Stretch/White_Gold.png";

// default
import all_default from "./OpenAir_assets/default.png";

//subject 4x6 openAir
import subject_4 from "./OpenAir_assets/Open_Air/Layers_4/Subjects/People.png";
import PeopleFourBySix from "./OpenAir_assets/PeopleFourBySix.png";

// backgdrop 4x6 openAir
import none from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/None.png";
import Sequin_Gold from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Sequin_Gold.png";
import Sequin_Black_White from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Sequin_Black_White.png";
import Sequin_Gold_White from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Sequin_Gold_White.png";
import Sequin_Navy_Silver from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Sequin_Navy_Silver.png";
import Sequin_Red from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Sequin_Red.png";
import Sequin_Silver from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Sequin_Silver.png";
import Sequin_White from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Sequin_White.png";
import Stretch_Gold_White from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Stretch_Gold_White.png";
import Stretch_Yellow from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Stretch_Yellow.png";
import Stretch_Black from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Stretch_Black.png";
import Stretch_Green_Screen from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Stretch_Green_Screen.png";
import Stretch_Leaf from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Stretch_Leaf.png";
import Stretch_Pink from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Stretch_Pink.png";
import Stretch_White from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Stretch_White.png";
import Stretch_Wood from "./OpenAir_assets/Open_Air/Layers_4/Backdrop/Stretch_Wood.png";

//Template 4x6 openAir
import type4X6_A_B1 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B1.png";
import type4X6_A_B10 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B10.png";
import type4X6_A_B11 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B11.png";
import type4X6_A_B12 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B12.png";
import type4X6_A_B2 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B2.png";
import type4X6_A_B3 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B3.png";
import type4X6_A_B4 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B4.png";
import type4X6_A_B5 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B5.png";
import type4X6_A_B6 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B6.png";
import type4X6_A_B7 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B7.png";
import type4X6_A_B8 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B8.png";
import type4X6_A_B9 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - B9.png";
import type4X6_A_S1 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - S1.png";
import type4X6_A_S2 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - S2.png";
import type4X6_A_S3 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - S3.png";
import type4X6_A_S4 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - S4.png";
import type4X6_A_S5 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - S5.png";
import type4X6_A_S6 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - S6.png";
import type4X6_A_S7 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - S7.png";
import type4X6_A_S8 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - S8.png";
import type4X6_A_S9 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - S9.png";
import type4X6_A_W1 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W1.png";
import type4X6_A_W10 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W10.png";
import type4X6_A_W11 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W11.png";
import type4X6_A_W12 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W12.png";
import type4X6_A_W2 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W2.png";
import type4X6_A_W3 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W3.png";
import type4X6_A_W4 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W4.png";
import type4X6_A_W5 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W5.png";
import type4X6_A_W6 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W6.png";
import type4X6_A_W7 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W7.png";
import type4X6_A_W8 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W8.png";
import type4X6_A_W9 from "./OpenAir_assets/Open_Air/Layers_4/Template/4X6 - Type A - W9.png";

//subject 2 x6 Open_Air
import subject_1 from "./OpenAir_assets/Open_Air/Layers_2/Subjects/2x6 - Type A - People.png";

// backgdrop 2x6
import none_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - NONE.png";
import Sequin_Black_White_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Sequin - Black & White.png";
import Sequin_Gold_White_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Sequin - Gold & White.png";
import Sequin_Gold_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Sequin - Gold.png";
import Sequin_Navy_Silver_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Sequin - Navy & Silver.png";
import Sequin_Red_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Sequin - Red.png";
import Sequin_Silver_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Sequin - Silver.png";
import Sequin_White_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Sequin - White.png";
import Stretch_Black_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Stretch - Black.png";
import Stretch_Green_leaves from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Stretch - Green Leaves.png";
import Stretch_Green_Screen_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Stretch - Green Screen.png";
import Stretch_Pink_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Stretch - Pink.png";
import Stretch_White_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type D - Stretch - White.png";
import Stretch_Wood_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Stretch - Wood.png";
import Stretch_Yellow_typeA from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Stretch - Yellow.png";

import Stretch_WhiteNew from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Stretch - White.png";

import Stretch_WhiteGold from "./OpenAir_assets/Open_Air/Layers_2/Backdrops/2x6 - Type A - Stretch - White & Gold.png";

//Template 2x6
import typeA_B3 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - B3.png";
import typeA_B4 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - B4.png";
import typeA_B10 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - B10.png";
import typeA_B11 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - B11.png";
import typeA_W2 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - W2.png";
import typeA_S3 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - S3.png";
import typeA_S4 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - S4.png";
import typeA_S7 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - S7.png";
import typeA_S9 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - S9.png";

import typeA_W8 from "./OpenAir_assets/Open_Air/Layers_2/Template_Designs/2x6 - Type A - W8.png";

//memoryBook
import memoryBookImg from "./OpenAir_assets/Open_Air/Memory_Book/memory_book.jpg";

//led 4x6 temp

import threeSixtyBanner from "./OpenAir_assets/360/ThreeSixty.png";
import led_banner from "./OpenAir_assets/Led_Inflatable/led_default.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//props

import Aim_Logo_full from "./OpenAir_assets/Props/Aim Logo - full.jpg";
import Aim_Logo_small from "./OpenAir_assets/Props/Aim Logo - small.jpg";
import Arizona_Iced_Tea_full from "./OpenAir_assets/Props/Arizona Iced Tea - full.jpg";
import Arizona_Iced_Tea_small from "./OpenAir_assets/Props/Arizona Iced Tea - small.jpg";
import Asian_Food_full from "./OpenAir_assets/Props/Asian Food - full.jpg";
import Asian_Food_small from "./OpenAir_assets/Props/Asian Food - small.jpg";
import Avocado_Salmon_Roll_full from "./OpenAir_assets/Props/Avocado Salmon Roll - full.jpg";
import Avocado_Salmon_Roll_small from "./OpenAir_assets/Props/Avocado Salmon Roll - small.jpg";
import Bacon_Egg_Cheese_full from "./OpenAir_assets/Props/Bacon, Egg, & Cheese - full.jpg";
import Bacon_Egg_Cheese_small from "./OpenAir_assets/Props/Bacon, Egg, & Cheese - small.jpg";
import Baseball_full from "./OpenAir_assets/Props/Baseball - full.jpg";
import Baseball_small from "./OpenAir_assets/Props/Baseball - small.jpg";
import Baseball_Bat_full from "./OpenAir_assets/Props/Baseball Bat - full.jpg";
import Baseball_Bat_small from "./OpenAir_assets/Props/Baseball Bat - small.jpg";
import Beef_Empanada_full from "./OpenAir_assets/Props/Beef Empanada - full.jpg";
import Beef_Empanada_small from "./OpenAir_assets/Props/Beef Empanada - small.jpg";
import Big_Mac_full from "./OpenAir_assets/Props/Big Mac - full.jpg";
import Big_Mac_small from "./OpenAir_assets/Props/Big Mac - small.jpg";
import Book_Box_full from "./OpenAir_assets/Props/Book Box - full.jpg";
import Book_Box_small from "./OpenAir_assets/Props/Book Box - small.jpg";
import Cannoli_full from "./OpenAir_assets/Props/Cannoli - full.jpg";
import Cannoli_small from "./OpenAir_assets/Props/Cannoli - small.jpg";
import Car_Turbo_full from "./OpenAir_assets/Props/Car Turbo - full.jpg";
import Car_Turbo_small from "./OpenAir_assets/Props/Car Turbo - small.jpg";
import Chocolate_Chip_Muffin_full from "./OpenAir_assets/Props/Chocolate Chip Muffin - full.jpg";
import Chocolate_Chip_Muffin_small from "./OpenAir_assets/Props/Chocolate Chip Muffin - small.jpg";
import Chocolate_Kiss_full from "./OpenAir_assets/Props/Chocolate Kiss - full.jpg";
import Chocolate_Kiss_small from "./OpenAir_assets/Props/Chocolate Kiss - small.jpg";
import Cigar_full from "./OpenAir_assets/Props/Cigar - full.jpg";
import Cigar_small from "./OpenAir_assets/Props/Cigar - small.jpg";
import Coca_Cola_Bottle_full from "./OpenAir_assets/Props/Coca Cola Bottle - full.jpg";
import Coca_Cola_Bottle_small from "./OpenAir_assets/Props/Coca Cola Bottle - small.jpg";
import Corona_Bottle_full from "./OpenAir_assets/Props/Corona Bottle - full.jpg";
import Corona_Bottle_small from "./OpenAir_assets/Props/Corona Bottle - small.jpg";
import Cracker_Jacks_full from "./OpenAir_assets/Props/Cracker Jacks - full.jpg";
import Cracker_Jacks_small from "./OpenAir_assets/Props/Cracker Jacks - small.jpg";
import Creamsicle_full from "./OpenAir_assets/Props/Creamsicle - full.jpg";
import Creamsicle_small from "./OpenAir_assets/Props/Creamsicle - small.jpg";
import Donut_full from "./OpenAir_assets/Props/Donut - full.jpg";
import Donut_small from "./OpenAir_assets/Props/Donut - small.jpg";
import Dreidel_full from "./OpenAir_assets/Props/Dreidel - full.jpg";
import Dreidel_small from "./OpenAir_assets/Props/Dreidel - small.jpg";
import Dumbbell_full from "./OpenAir_assets/Props/Dumbbell - full.jpg";
import Dumbbell_small from "./OpenAir_assets/Props/Dumbbell - small.jpg";
import Dunkin_Ice_Coffee_full from "./OpenAir_assets/Props/Dunkin Ice Coffee - full.jpg";
import Dunkin_Ice_Coffee_small from "./OpenAir_assets/Props/Dunkin Ice Coffee - small.jpg";
import Fire_Hydrant_full from "./OpenAir_assets/Props/Fire Hydrant - full.jpg";
import Fire_Hydrant_small from "./OpenAir_assets/Props/Fire Hydrant - small.jpg";
import Firecracker_full from "./OpenAir_assets/Props/Firecracker - full.jpg";
import Firecracker_small from "./OpenAir_assets/Props/Firecracker - small.jpg";
import Fish_Bowl_full from "./OpenAir_assets/Props/Fish Bowl - full.jpg";
import Fish_Bowl_small from "./OpenAir_assets/Props/Fish Bowl - small.jpg";
import Gavel_full from "./OpenAir_assets/Props/Gavel - full.jpg";
import Gavel_small from "./OpenAir_assets/Props/Gavel - small.jpg";
import Giant_Beef_Brocceli_full from "./OpenAir_assets/Props/Giant Beef & Brocceli - full.jpg";
import Giant_Beef_Brocceli_small from "./OpenAir_assets/Props/Giant Beef & Brocceli - small.jpg";
import Giant_Magnets_full from "./OpenAir_assets/Props/Giant Magnets - full.jpg";
import Giant_Magnets_small from "./OpenAir_assets/Props/Giant Magnets - small.jpg";
import Groot_full from "./OpenAir_assets/Props/Groot - full.jpg";
import Groot_small from "./OpenAir_assets/Props/Groot - small.jpg";
import Gyro_full from "./OpenAir_assets/Props/Gyro - full.jpg";
import Gyro_small from "./OpenAir_assets/Props/Gyro - small.jpg";
import Hebrew_Doritos_full from "./OpenAir_assets/Props/Hebrew Doritos - full.jpg";
import Hebrew_Doritos_small from "./OpenAir_assets/Props/Hebrew Doritos - small.jpg";
import Hotdog_Full from "./OpenAir_assets/Props/Hotdog - Full.jpg";
import Hotdog_small from "./OpenAir_assets/Props/Hotdog - small.jpg";
import issa_benolerao_320_X_241 from "./OpenAir_assets/Props/issa benolerao - 320 X 241.jpg";
import issa_benolerao_BEFORE_AND_AFTER_1920_X_1080SAMPLE from "./OpenAir_assets/Props/issa benolerao - BEFORE AND AFTER 1920 X 1080SAMPLE.jpg";
import Boy_Block_full from "./OpenAir_assets/Props/It's A Boy Block - full.jpg";
import Boy_Block_small from "./OpenAir_assets/Props/It's A Boy Block - small.jpg";
import Girl_Block_full from "./OpenAir_assets/Props/It's A Girl Block - full.jpg";
import Girl_Block_small from "./OpenAir_assets/Props/It's A Girl Block - small.jpg";
import Lego_HeadSide_1_full from "./OpenAir_assets/Props/Lego Head-Side 1 - full.jpg";
import Lego_HeadSide_1_small from "./OpenAir_assets/Props/Lego Head-Side 1 - small.jpg";
import Lego_HeadSide_2_full from "./OpenAir_assets/Props/Lego Head-Side 2 - full.jpg";
import Lego_HeadSide_2_small from "./OpenAir_assets/Props/Lego Head-Side 2 - small.jpg";
import Lemon_full from "./OpenAir_assets/Props/Lemon - full.jpg";
import Lemon_small from "./OpenAir_assets/Props/Lemon - small.jpg";
import Lock_Key_full from "./OpenAir_assets/Props/Lock & Key - full.jpg";
import Lock_Key_small from "./OpenAir_assets/Props/Lock & Key - small.jpg";
import Mac_Brush_full from "./OpenAir_assets/Props/Mac Brush - full.jpg";
import Mac_Brush_small from "./OpenAir_assets/Props/Mac Brush - small.jpg";
import Mac_Lipstick_full from "./OpenAir_assets/Props/Mac Lipstick - full.jpg";
import Mac_Lipstick_small from "./OpenAir_assets/Props/Mac Lipstick - small.jpg";
import Macaroon_full from "./OpenAir_assets/Props/Macaroon - full.jpg";
import Macaroon_small from "./OpenAir_assets/Props/Macaroon - small.jpg";
import Matza_small from "./OpenAir_assets/Props/Matza - small.jpg";
import Matza_full from "./OpenAir_assets/Props/Matza full.jpg";
import Mets_Apple_full from "./OpenAir_assets/Props/Mets Apple - full.jpg";
import Mets_Apple_small from "./OpenAir_assets/Props/Mets Apple - small.jpg";
import Microphone_full from "./OpenAir_assets/Props/Microphone - full.jpg";
import Microphone_small from "./OpenAir_assets/Props/Microphone - small.jpg";
import Movies_Popcorn_full from "./OpenAir_assets/Props/Movies Popcorn - full.jpg";
import Movies_Popcorn_small from "./OpenAir_assets/Props/Movies Popcorn - small.jpg";
import Needle_full from "./OpenAir_assets/Props/Needle - full.jpg";
import Needle_small from "./OpenAir_assets/Props/Needle - small.jpg";
import Nutella_full from "./OpenAir_assets/Props/Nutella - full.jpg";
import Nutella_small from "./OpenAir_assets/Props/Nutella - small.jpg";
import Ornament_full from "./OpenAir_assets/Props/Ornament - full.jpg";
import Ornament_small from "./OpenAir_assets/Props/Ornament - small.jpg";
import Peanut_Butter_Cup_full from "./OpenAir_assets/Props/Peanut Butter Cup - full.jpg";
import Peanut_Butter_Cup_small from "./OpenAir_assets/Props/Peanut Butter Cup - small.jpg";
import Pencil_full from "./OpenAir_assets/Props/Pencil - full.jpg";
import Pencil_small from "./OpenAir_assets/Props/Pencil - small.jpg";
import Pig_full from "./OpenAir_assets/Props/Pig - full.jpg";
import Pig_small from "./OpenAir_assets/Props/Pig - small.jpg";
import Pill_full from "./OpenAir_assets/Props/Pill - full.jpg";
import Pill_small from "./OpenAir_assets/Props/Pill - small.jpg";
import Pill_Bottle_full from "./OpenAir_assets/Props/Pill Bottle - full.jpg";
import Pill_Bottle_small from "./OpenAir_assets/Props/Pill Bottle - small.jpg";
import Pineapple_full from "./OpenAir_assets/Props/Pineapple - full.jpg";
import Pineapple_small from "./OpenAir_assets/Props/Pineapple - small.jpg";
import Ramen_Bowl_full from "./OpenAir_assets/Props/Ramen Bowl - full.jpg";
import Ramen_Bowl_small from "./OpenAir_assets/Props/Ramen Bowl - small.jpg";
import Rubik_Cube_full from "./OpenAir_assets/Props/Rubik's Cube - full.jpg";
import Rubik_Cube_small from "./OpenAir_assets/Props/Rubik's Cube - small.jpg";
import Sambuca_Bottle_full from "./OpenAir_assets/Props/Sambuca Bottle - full.jpg";
import Sambuca_Bottle_small from "./OpenAir_assets/Props/Sambuca Bottle - small.jpg";
import Sangria_full from "./OpenAir_assets/Props/Sangria - full.jpg";
import Sangria_small from "./OpenAir_assets/Props/Sangria - small.jpg";
import Sippin_Tea_full from "./OpenAir_assets/Props/Sippin Tea - full.jpg";
import Sippin_Tea_small from "./OpenAir_assets/Props/Sippin Tea - small.jpg";
import Siracha_full from "./OpenAir_assets/Props/Siracha - full.jpg";
import Siracha_small from "./OpenAir_assets/Props/Siracha - small.jpg";
import Skittles_full from "./OpenAir_assets/Props/Skittles - full.jpg";
import Skittles_small from "./OpenAir_assets/Props/Skittles - small.jpg";
import Star_full from "./OpenAir_assets/Props/Star - full.jpg";
import Star_small from "./OpenAir_assets/Props/Star - small.jpg";
import Starbuk_Coffee_full from "./OpenAir_assets/Props/Starbuk's Coffee - full.jpg";
import Starbuk_Coffee_small from "./OpenAir_assets/Props/Starbuk's Coffee - small.jpg";
import Sushi_2_full from "./OpenAir_assets/Props/Sushi 2 - full.jpg";
import Sushi_2_small from "./OpenAir_assets/Props/Sushi 2 - small.jpg";
import Tennis_Ball_full from "./OpenAir_assets/Props/Tennis Ball - full.jpg";
import Tennis_Ball_small from "./OpenAir_assets/Props/Tennis Ball - small.jpg";
import Thor_Hammer_full from "./OpenAir_assets/Props/Thor Hammer - full.jpg";
import Thor_Hammer_small from "./OpenAir_assets/Props/Thor Hammer - small.jpg";
import Toy_Block_Neutral_full from "./OpenAir_assets/Props/Toy Block - Neutral - full.jpg";
import Toy_Block_Neutral_small from "./OpenAir_assets/Props/Toy Block - Neutral - small.jpg";
import Twinkies_full from "./OpenAir_assets/Props/Twinkies - full.jpg";
import Twinkies_small from "./OpenAir_assets/Props/Twinkies - small.jpg";
import Wine_Bottle_full from "./OpenAir_assets/Props/Wine Bottle - full.jpg";
import Wine_Bottle_small from "./OpenAir_assets/Props/Wine Bottle - small.jpg";
import * as $ from "jquery";

//bacround 2x6 and 4x6
import background2_6 from "./OpenAir_assets/background_2x6.png";
import background4_6 from "./OpenAir_assets/background_4x6.png";

import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { MdOutlineColorLens } from "react-icons/md";

import {
  Sequin_Add_Backdrop,
  stretch_Add_Backdrop,
  Green_Add_Backdrop,
} from "./ImageData";

const Backdrop_list = [
  {
    img: Sequin_Black_White_typeA,
    title: "black-white",
  },
  {
    img: Sequin_Gold_White_typeA,
    title: "gold-white",
  },
  {
    img: Sequin_Gold_typeA,
    title: "gold",
  },
  {
    img: Sequin_Navy_Silver_typeA,
    title: "navy-silver",
  },
  {
    img: Sequin_Red_typeA,
    title: "red",
  },
  // {
  //   img: Sequin_Silver_typeA,
  //   title: "sequin silver",
  // },
  // {
  //   img: Sequin_White_typeA,
  //   title: "white",
  // },

  {
    img: Stretch_Green_leaves,
    title: "green-leaves",
  },

  {
    img: Stretch_Black_typeA,
    title: "black",
  },

  {
    img: Stretch_Pink_typeA,
    title: "pink",
  },
  {
    img: Stretch_WhiteNew,
    title: "white",
  },
  {
    img: Stretch_Wood_typeA,
    title: "wood",
  },
  {
    img: Stretch_Yellow_typeA,
    title: "yellow",
  },

  {
    img: Stretch_WhiteGold,
    title: "white-gold",
  },

  {
    img: Stretch_Green_Screen_typeA,
    title: "green-screen",
  },
];

const Template_images = [
  {
    img: none_typeA,
    title: "b1",
  },
  {
    img: typeA_B3,
    title: "b1",
  },
  {
    img: typeA_B4,
    title: "b4",
  },
  {
    img: typeA_B10,
    title: "b10",
  },
  {
    img: typeA_B11,
    title: "b11",
  },
  {
    img: typeA_W2,
    title: "w2",
  },
  {
    img: typeA_S3,
    title: "s3",
  },
  {
    img: typeA_S4,
    title: "People",
  },
  {
    img: typeA_S7,
    title: "s7",
  },
  {
    img: typeA_S9,
    title: "s9",
  },
  // {
  //   img: typeA_W12,
  //   title: "w12",
  // },
  {
    img: typeA_W8,
    title: "w8",
  },
  // {
  //   img: typeB_W7,
  //   title: "w7",
  // },
  // {
  //   img: typeC_B1,
  //   title: "b1",
  // },
  // {
  //   img: typeC_B2,
  //   title: "b2",
  // },
  // {
  //   img: typeC_B6,
  //   title: "b6",
  // },
  // {
  //   img: typeC_B7,
  //   title: "b7",
  // },
  // {
  //   img: typeC_B9,
  //   title: "b9",
  // },
  // {
  //   img: typeC_B8,
  //   title: "b8",
  // },
  // {
  //   img: typeC_S2,
  //   title: "s2",
  // },
  // {
  //   img: typeC_S5,
  //   title: "s5",
  // },
  // {
  //   img: typeC_S8,
  //   title: "s8",
  // },
  // {
  //   img: typeC_W1,
  //   title: "w1",
  // },
  // {
  //   img: typeC_W3,
  //   title: "w3",
  // },
  // {
  //   img: typeC_W4,
  //   title: "w4",
  // },
  // {
  //   img: typeC_W6,
  //   title: "w6",
  // },
  // {
  //   img: typeC_W9,
  //   title: "w9",
  // },
  // {
  //   img: typeC_W10,
  //   title: "w10",
  // },
  // {
  //   img: typeC_W11,
  //   title: "w11",
  // },
  // {
  //   img: typeC_W12,
  //   title: "w12",
  // },
  // {
  //   img: typeD_S1,
  //   title: "s1",
  // },
];

const CustomePropsList = [
  {
    img: Wine_Bottle_small,
    title: "Wine Bottle small",
    id: 0,
  },
  {
    img: Aim_Logo_full,
    title: "Aim Logo-Full",
    id: 1,
  },
  {
    img: Aim_Logo_small,
    title: "Aim Logo-small",
    id: 2,
  },
  {
    img: Arizona_Iced_Tea_full,
    title: "Arizona Iced Tea-full",
    id: 3,
  },
  {
    img: Arizona_Iced_Tea_small,
    title: "Arizona Iced Tea-small",
    id: 4,
  },
  {
    img: Asian_Food_full,
    title: "Asian Food-full",
    id: 5,
  },
  {
    img: Asian_Food_small,
    title: "Asian Food-small",
    id: 6,
  },
  {
    img: Avocado_Salmon_Roll_full,
    title: "Avocado Salmon Roll-full",
    id: 7,
  },
  {
    img: Avocado_Salmon_Roll_small,
    title: "Avocado Salmon Roll-small",
    id: 8,
  },
  {
    img: Bacon_Egg_Cheese_full,
    title: "Bacon Egg Cheese-full",
    id: 9,
  },
  {
    img: Bacon_Egg_Cheese_small,
    title: "Bacon Egg Cheese-small",
    id: 10,
  },
  {
    img: Baseball_full,
    title: "Baseball full",
    id: 11,
  },
  {
    img: Baseball_small,
    title: "Baseball small",
    id: 12,
  },
  {
    img: Baseball_Bat_full,
    title: "Baseball Bat full",
    id: 13,
  },
  {
    img: Baseball_Bat_small,
    title: "Baseball Bat-small",
    id: 14,
  },
  {
    img: Beef_Empanada_full,
    title: "Beef Empanada full",
    id: 15,
  },
  {
    img: Beef_Empanada_small,
    title: "Beef_Empanada_small",
    id: 16,
  },
  {
    img: Big_Mac_full,
    title: "Big Mac full",
    id: 17,
  },
  {
    img: Big_Mac_small,
    title: "Big Mac small",
    id: 18,
  },
  {
    img: Book_Box_full,
    title: "Book  Box full",
    id: 19,
  },
  {
    img: Book_Box_small,
    title: "Book Box small",
    id: 20,
  },
  {
    img: Cannoli_full,
    title: "Cannoli full",
    id: 21,
  },
  {
    img: Cannoli_small,
    title: "Cannoli small",
    id: 22,
  },
  {
    img: Car_Turbo_full,
    title: "Car Turbo-full",
    id: 23,
  },
  {
    img: Car_Turbo_small,
    title: "Car Turbo small",
    id: 24,
  },
  {
    img: Chocolate_Chip_Muffin_full,
    title: "Chocolate Chip Muffin full",
    id: 25,
  },
  {
    img: Chocolate_Chip_Muffin_small,
    title: "Chocolate Chip Muffin small",
    id: 26,
  },
  {
    img: Chocolate_Kiss_full,
    title: "Chocolate Kiss full",
    id: 27,
  },
  {
    img: Chocolate_Kiss_small,
    title: "Chocolate Kiss small",
    id: 28,
  },
  {
    img: Cigar_full,
    title: "Cigar full",
    id: 29,
  },
  {
    img: Cigar_small,
    title: "Cigar small",
    id: 30,
  },
  {
    img: Coca_Cola_Bottle_full,
    title: "Coca Cola Bottle full",
    id: 31,
  },
  {
    img: Coca_Cola_Bottle_small,
    title: "Coca Cola Bottle small",
    id: 32,
  },
  {
    img: Corona_Bottle_full,
    title: "Corona Bottle full",
    id: 33,
  },
  {
    img: Corona_Bottle_small,
    title: "Corona Bottle-small",
    id: 34,
  },
  {
    img: Cracker_Jacks_full,
    title: "Cracker Jacks-full",
    id: 35,
  },
  {
    img: Cracker_Jacks_small,
    title: "Cracker Jacks-small",
    id: 36,
  },
  {
    img: Creamsicle_full,
    title: "Creamsicle full",
    id: 37,
  },
  {
    img: Creamsicle_small,
    title: "Creamsicle small",
    id: 37,
  },
  {
    img: Donut_full,
    title: "Donut full",
    id: 38,
  },
  {
    img: Donut_small,
    title: "Donut small",
    id: 39,
  },
  {
    img: Dreidel_full,
    title: "Dreidel full",
    id: 40,
  },
  {
    img: Dreidel_small,
    title: "Dreidel small",
    id: 41,
  },
  {
    img: Dumbbell_full,
    title: "Dumbbell full",
    id: 42,
  },
  {
    img: Dumbbell_small,
    title: "Dumbbell small",
    id: 43,
  },
  {
    img: Dunkin_Ice_Coffee_full,
    title: "Dunkin Ice Coffee full",
    id: 44,
  },
  {
    img: Dunkin_Ice_Coffee_small,
    title: "Dunkin Ice Coffee small",
    id: 45,
  },
  {
    img: Fire_Hydrant_full,
    title: "Fire Hydrant full",
    id: 46,
  },
  {
    img: Fire_Hydrant_small,
    title: "Fire Hydrant small",
    id: 47,
  },
  {
    img: Firecracker_full,
    title: "Firecracker full",
    id: 48,
  },
  {
    img: Firecracker_small,
    title: "Firecracker small",
    id: 49,
  },
  {
    img: Fish_Bowl_full,
    title: "Fish Bowl full",
    id: 50,
  },
  {
    img: Fish_Bowl_small,
    title: "Fish Bowl small",
    id: 51,
  },
  {
    img: Gavel_full,
    title: "Gavel full",
    id: 52,
  },
  {
    img: Gavel_small,
    title: "Gavel small",
    id: 53,
  },
  {
    img: Giant_Beef_Brocceli_full,
    title: "Giant Beef Brocceli full",
    id: 54,
  },
  {
    img: Giant_Beef_Brocceli_small,
    title: "Giant Beef Brocceli small",
    id: 55,
  },
  {
    img: Giant_Magnets_full,
    title: "Giant Magnets full",
    id: 56,
  },
  {
    img: Giant_Magnets_small,
    title: "Giant Magnets small",
    id: 57,
  },
  {
    img: Groot_full,
    title: "Groot full",
    id: 58,
  },
  {
    img: Groot_small,
    title: "Groot small",
    id: 59,
  },
  {
    img: Gyro_full,
    title: "Gyro full",
    id: 60,
  },
  {
    img: Gyro_small,
    title: "Gyro small",
    id: 61,
  },
  {
    img: Hebrew_Doritos_full,
    title: "Hebrew Doritos full",
    id: 62,
  },
  {
    img: Hebrew_Doritos_small,
    title: "Hebrew Doritos small",
    id: 63,
  },
  {
    img: Hotdog_Full,
    title: "Hotdog Full",
    id: 64,
  },
  {
    img: Hotdog_small,
    title: "Hotdog small",
    id: 65,
  },
  {
    img: issa_benolerao_320_X_241,
    title: "issa benolerao 320 X 241",
    id: 66,
  },
  {
    img: issa_benolerao_BEFORE_AND_AFTER_1920_X_1080SAMPLE,
    title: "issa benolerao BEFORE AND AFTER 1920 X 1080 SAMPLE",
    id: 67,
  },
  {
    img: Boy_Block_full,
    title: "Boy Block full",
    id: 68,
  },
  {
    img: Boy_Block_small,
    title: "Boy Block small",
    id: 69,
  },
  {
    img: Girl_Block_full,
    title: "Girl Block full",
    id: 70,
  },
  {
    img: Girl_Block_small,
    title: "Girl Block small",
    id: 71,
  },
  {
    img: Lego_HeadSide_1_full,
    title: "Lego HeadSide full",
    id: 72,
  },
  {
    img: Lego_HeadSide_1_small,
    title: "Lego HeadSide small",
    id: 73,
  },
  {
    img: Lego_HeadSide_2_full,
    title: "Lego HeadSide full",
    id: 74,
  },
  {
    img: Lego_HeadSide_2_small,
    title: "Lego HeadSide small",
    id: 75,
  },
  {
    img: Lemon_full,
    title: "Lemon full",
    id: 76,
  },
  {
    img: Lemon_small,
    title: "Lemon small",
    id: 77,
  },
  {
    img: Lock_Key_full,
    title: "Lock Key full",
    id: 78,
  },
  {
    img: Lock_Key_small,
    title: "Lock Key small",
    id: 79,
  },
  {
    img: Mac_Brush_full,
    title: "Mac Brush full",
    id: 80,
  },
  {
    img: Mac_Brush_small,
    title: "Mac Brush small",
    id: 81,
  },
  {
    img: Mac_Lipstick_full,
    title: "Mac Lipstick full",
    id: 82,
  },
  {
    img: Mac_Lipstick_small,
    title: "Mac Lipstick small",
    id: 83,
  },
  {
    img: Macaroon_full,
    title: "Macaroon full",
    id: 84,
  },
  {
    img: Macaroon_small,
    title: "Macaroon small",
    id: 85,
  },
  {
    img: Matza_small,
    title: "Matza small",
    id: 86,
  },
  {
    img: Matza_full,
    title: "Matza full",
    id: 87,
  },
  {
    img: Mets_Apple_full,
    title: "Mets Apple full",
    id: 88,
  },
  {
    img: Mets_Apple_small,
    title: "Mets Apple small",
    id: 89,
  },
  {
    img: Microphone_full,
    title: "Microphone full",
    id: 90,
  },
  {
    img: Microphone_small,
    title: "Microphone small",
    id: 91,
  },
  {
    img: Movies_Popcorn_full,
    title: "Movies Popcorn full",
    id: 92,
  },
  {
    img: Movies_Popcorn_small,
    title: "Movies Popcorn small",
    id: 93,
  },
  {
    img: Needle_full,
    title: "Needle full",
    id: 94,
  },
  {
    img: Needle_small,
    title: "Needle small",
    id: 95,
  },
  {
    img: Nutella_full,
    title: "Nutella full",
    id: 96,
  },
  {
    img: Nutella_small,
    title: "Nutella small",
    id: 97,
  },
  {
    img: Ornament_full,
    title: "Ornament full",
    id: 98,
  },
  {
    img: Ornament_small,
    title: "Ornament small",
    id: 99,
  },
  {
    img: Peanut_Butter_Cup_full,
    title: "Peanut Butter Cup full",
    id: 100,
  },
  {
    img: Peanut_Butter_Cup_small,
    title: "Peanut Butter Cup small",
    id: 101,
  },
  {
    img: Pencil_full,
    title: "Pencil full",
    id: 101,
  },
  {
    img: Pencil_small,
    title: "Pencil small",
    id: 102,
  },
  {
    img: Pig_full,
    title: "Pig full",
    id: 103,
  },
  {
    img: Pig_small,
    title: "Pig small",
    id: 104,
  },
  {
    img: Pill_full,
    title: "Pill full",
    id: 105,
  },
  {
    img: Pill_small,
    title: "Pill small",
    id: 106,
  },
  {
    img: Pill_Bottle_full,
    title: "Pill Bottle full",
    id: 107,
  },
  {
    img: Pill_Bottle_small,
    title: "Pill Bottle small",
    id: 108,
  },
  {
    img: Pineapple_full,
    title: "Pineapple full",
    id: 109,
  },
  {
    img: Pineapple_small,
    title: "Pineapple small",
    id: 110,
  },
  {
    img: Ramen_Bowl_full,
    title: "Ramen Bowl full",
    id: 111,
  },
  {
    img: Ramen_Bowl_small,
    title: "Ramen Bowl small",
    id: 112,
  },
  {
    img: Rubik_Cube_full,
    title: "Rubik Cube full",
    id: 113,
  },
  {
    img: Rubik_Cube_small,
    title: "Rubik Cube small",
    id: 114,
  },
  {
    img: Sambuca_Bottle_full,
    title: "Sambuca Bottle full",
    id: 115,
  },
  {
    img: Sambuca_Bottle_small,
    title: "Sambuca Bottle small",
    id: 116,
  },
  {
    img: Sangria_full,
    title: "Sangria full",
    id: 117,
  },
  {
    img: Sangria_small,
    title: "Sangria_small",
    id: 118,
  },
  {
    img: Sippin_Tea_full,
    title: "Sippin Tea full",
    id: 119,
  },
  {
    img: Sippin_Tea_small,
    title: "Sippin Tea small",
    id: 120,
  },
  {
    img: Siracha_full,
    title: "Siracha full",
    id: 121,
  },
  {
    img: Siracha_small,
    title: "Siracha small",
    id: 122,
  },
  {
    img: Skittles_full,
    title: "Skittles full",
    id: 123,
  },
  {
    img: Skittles_small,
    title: "Skittles small",
    id: 124,
  },
  {
    img: Star_full,
    title: "Star full",
    id: 125,
  },
  {
    img: Star_small,
    title: "Star small",
    id: 126,
  },
  {
    img: Starbuk_Coffee_full,
    title: "Starbuk Coffee full",
    id: 127,
  },
  {
    img: Starbuk_Coffee_small,
    title: "Starbuk Coffee small",
    id: 128,
  },
  {
    img: Sushi_2_full,
    title: "Sushi full",
    id: 129,
  },
  {
    img: Sushi_2_small,
    title: "Sushi small",
    id: 130,
  },
  {
    img: Tennis_Ball_full,
    title: "Tennis Ball full",
    id: 131,
  },
  {
    img: Tennis_Ball_small,
    title: "Tennis Ball small",
    id: 132,
  },
  {
    img: Thor_Hammer_full,
    title: "Thor Hammer full",
    id: 133,
  },
  {
    img: Thor_Hammer_small,
    title: "Thor Hammer small",
    id: 134,
  },
  {
    img: Toy_Block_Neutral_full,
    title: "Toy Block Neutral full",
    id: 135,
  },
  {
    img: Toy_Block_Neutral_small,
    title: "Toy Bloc _Neutral small",
    id: 136,
  },
  {
    img: Twinkies_full,
    title: "Twinkies_full",
    id: 137,
  },
  {
    img: Twinkies_small,
    title: "Twinkies small",
    id: 138,
  },
  {
    img: Wine_Bottle_full,
    title: "Wine Bottle full",
    id: 139,
  },
];

const Backdrop_list_FourBySix = [
  // {
  //   img: none,

  //   title: "none",
  // },
  {
    img: Sequin_Red,
    title: "red",
  },
  {
    img: Sequin_Navy_Silver,
    title: "navy-silver",
  },
  {
    img: Sequin_Gold_White,
    title: "gold-white",
  },
  {
    img: Sequin_Black_White,
    title: "black-white",
  },
  {
    img: Sequin_Gold,
    title: "gold",
  },
  // {
  //   img: Sequin_Red,
  //   title: "sequin red",
  // },
  // {
  //   img: Sequin_Silver,
  //   title: "sequin silver",
  // },
  {
    img: Sequin_White,
    title: "white",
  },
  {
    img: Stretch_Gold_White,
    title: "white-gold",
  },
  // {
  //   img: Stretch_Yellow,
  //   title: "stretch yellow",
  // },
  {
    img: Stretch_Black,
    title: "black",
  },
  {
    img: Stretch_Green_Screen,
    title: "green-screen",
  },
  {
    img: Stretch_Leaf,
    title: "green-leaves",
  },
  {
    img: Stretch_Pink,
    title: "pink",
  },
  {
    img: Stretch_White,
    title: "white",
  },
  {
    img: Stretch_Wood,
    title: "wood",
  },
];

const Template_imagesFourBySix = [
  {
    img: none,
    title: "none",
  },
  {
    img: type4X6_A_B1,
    title: "b1",
  },
  {
    img: type4X6_A_B10,
    title: "b10",
  },
  {
    img: type4X6_A_B11,
    title: "b11",
  },
  {
    img: type4X6_A_B12,
    title: "b12",
  },
  {
    img: type4X6_A_B2,
    title: "b2",
  },
  {
    img: type4X6_A_B3,
    title: "b3",
  },
  {
    img: type4X6_A_B4,
    title: "b4",
  },
  {
    img: type4X6_A_B5,
    title: "b5",
  },
  {
    img: type4X6_A_B6,
    title: "b6",
  },
  {
    img: type4X6_A_B7,
    title: "b7",
  },
  {
    img: type4X6_A_B8,
    title: "b8",
  },
  {
    img: type4X6_A_B9,
    title: "b9",
  },
  {
    img: type4X6_A_S1,
    title: "s1",
  },
  {
    img: type4X6_A_S2,
    title: "s2",
  },
  {
    img: type4X6_A_S3,
    title: "s3",
  },
  {
    img: type4X6_A_S4,
    title: "s4",
  },
  {
    img: type4X6_A_S5,
    title: "s5",
  },
  {
    img: type4X6_A_S6,
    title: "s6",
  },
  {
    img: type4X6_A_S7,
    title: "s7",
  },
  {
    img: type4X6_A_S8,
    title: "s8",
  },
  {
    img: type4X6_A_S9,
    title: "s9",
  },
  {
    img: type4X6_A_W1,
    title: "w1",
  },
  {
    img: type4X6_A_W10,
    title: "w10",
  },
  {
    img: type4X6_A_W11,
    title: "w11",
  },
  {
    img: type4X6_A_W12,
    title: "w12",
  },
  {
    img: type4X6_A_W2,
    title: "w2",
  },
  {
    img: type4X6_A_W3,
    title: "w3",
  },
  {
    img: type4X6_A_W4,
    title: "w4",
  },
  {
    img: type4X6_A_W5,
    title: "w5",
  },
  {
    img: type4X6_A_W6,
    title: "w6",
  },
  {
    img: type4X6_A_W7,
    title: "w7",
  },
  {
    img: type4X6_A_W8,
    title: "w8",
  },
  {
    img: type4X6_A_W9,
    title: "w9",
  },
];

const PhotoBooth = () => {
  const boothTypeOption = [
    {
      title: "Open air",
      value: "Open_air",
    },
    {
      title: "360",
      value: "360",
    },
    {
      title: "LED Inflatable",
      value: "LED_Inflatable",
    },
  ];

  const OccasionOptions = [
    "Anniversary",
    "Baby Shower ",
    "Birthday - Adults",
    "Birthday - Kids",
    "Black Empowerment",
    "Black Empowerment",
    "Corporate",
    "Corporate - Holiday Party",
    "Fundraiser",
    "Gender Reveal",
    " Girls Night-Bachelor Party-Couples Night",
    "Mitzvah",
    "PRIDE Event",
    "Retirement Party",
    "Reunion",
    "School - College",
    " School - Event-Dance",
    "School - Graduation",
    "School - Prom",
    "Sweet 16",
    "Wedding",
    "Other",
  ];

  const [boothType, setBoothType] = useState({
    openAirState: {
      flag: false,
      value: "",
    },
    threeSixty: {
      flag: false,
      value: "",
    },
    ledInlfatable: {
      flag: false,
      value: "",
    },
  });

  const token = useSelector((state) => state?.userDetail?.userToken);
  const location = useLocation();
  let eventId = location.state.id;

  const [activeTab, setActiveTab] = useState(0);
  const [printIndexTab, setPrintIndexTab] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [conditionPrintSize, setConditionPrintSize] = useState();

  const [chooseDesign, setChooseDesign] = useState({
    titleChange: "Add Name",
    dateChange: "Add Date",
    occationChooseDesign: "Add Occasion",
    AgeCount: "",
  });
  const { titleChange, dateChange, occationChooseDesign, AgeCount } =
    chooseDesign;

  const [upgradBackdrop, setUpdateBackdrop] = useState(IncludedBlack);
  const [upgradBackdropTitle, setUpgradBackdropTitle] = useState({});

  const [carpet, setCarpet] = useState(false);
  const [AddRemoveUpgradedBackdrop, setAddRemoveUpgradedBackdrop] =
    useState(false);

  const [memoryBook, setMemoryBook] = useState(false);
  const [chooseDesignToggle, setChooseDesignToggle] = useState(false);

  const [songName, setSongName] = useState();

  const { openAirState, threeSixty, ledInlfatable } = boothType;
  const [selectedProps, setSelectedProps] = useState([]);
  const [learnMoreSection, setLearnMoreSection] = useState(false);
  const [twoBy, setTwoBy] = useState(false);
  const [fourBy, setFourBy] = useState(false);
  const [template, setTabmplate] = useState();
  const [backdropimage, setBackdropImage] = useState("");
  const [subjectImage, setSubjectImage] = useState();
  const [TempIndex, setTempIndex] = useState(0);
  const [occation, setOccasion] = useState("");
  const [designImage, setDesignImage] = useState();
  const [designListData, setDesignListData] = useState({});

  //2x6 slider Section
  const [OpenAirtemplatePage, setOpenAirTemplatePage] = useState(1);
  const [OpenAirBackDropPage, setOpenAirBackDropPage] = useState(1);
  const [
    OpenAirUpgradeBackDropSequinPage,
    setOpenAirUpgradeBackDropSequinPage,
  ] = useState(1);

  const [
    OpenAirUpgradeBackDropStretchPage,
    setOpenAirUpgradeBackDropStretchPage,
  ] = useState(1);

  const [OpenAirBacdropFourBySixPage, setOpenAirBacdropFourBySixPage] =
    useState(1);

  const [OpenAirTempFourBySixPage, setOpenAirTempFourBySixPage] = useState(1);

  const ImageLoad = useRef(null);

  const DataPerPage = 5;
  const OpenAirTemplateSlider = Template_images;
  const fileRef = useRef(null);
  const templatePageCount = Math.ceil(Template_images.length / DataPerPage);

  const nextTemplate = () => {
    if (OpenAirtemplatePage < templatePageCount) {
      setOpenAirTemplatePage((prev) => {
        let data = prev + 1;
        return data;
      });
    }
  };

  const previousTemplate = () => {
    if (OpenAirtemplatePage > 1) {
      setOpenAirTemplatePage((prev) => {
        let data = prev - 1;
        return data;
      });
    }
  };

  const BackdropPageCount = Math.ceil(Backdrop_list.length / DataPerPage);
  const OpenAirBackdropPageLastIndex = OpenAirBackDropPage * DataPerPage;
  const OpenAirBackdropPageFirstIndex =
    OpenAirBackdropPageLastIndex - DataPerPage;

  // const OpenAirBackdropSlider = Backdrop_list.slice(
  //   OpenAirBackdropPageFirstIndex,
  //   OpenAirBackdropPageLastIndex
  // );

  const nextbackdrop = () => {
    if (OpenAirBackDropPage < BackdropPageCount) {
      setOpenAirBackDropPage((prev) => {
        let data = prev + 1;
        return data;
      });
    }
  };

  const previousBackdrop = () => {
    if (OpenAirBackDropPage > 1) {
      setOpenAirBackDropPage((prev) => {
        let data = prev - 1;
        return data;
      });
    }
  };

  const occationChange = (e) => {
    if (e.target.value === "choose_occasion") {
      setOccasion("");
    } else {
      setOccasion(e.target.value);
    }
  };

  const UpgradeBackdropSequinPageCount = Math.ceil(
    Sequin_Add_Backdrop.length / DataPerPage
  );
  let OpenAirBackdropPageSequinLastIndex =
    OpenAirUpgradeBackDropSequinPage * DataPerPage;
  const OpenAirBackdropPageSequinFirstIndex =
    OpenAirBackdropPageSequinLastIndex - DataPerPage;

  let OpenAirBackdropPageSequinSlider = Sequin_Add_Backdrop.slice(
    OpenAirBackdropPageSequinFirstIndex,
    OpenAirBackdropPageSequinLastIndex
  );

  let nextBackDropStretchItems =
    OpenAirUpgradeBackDropStretchPage * DataPerPage;
  if (nextBackDropStretchItems > stretch_Add_Backdrop.length) {
    nextBackDropStretchItems = stretch_Add_Backdrop.length;
  }
  const OpenAirBackdropPageStretchLastIndex = nextBackDropStretchItems; //10 {7}
  const OpenAirBackdropPageStretchFirstIndex =
    (OpenAirUpgradeBackDropStretchPage - 1) * DataPerPage; //5

  let OpenAirBackdropPageStretchSlider = stretch_Add_Backdrop.slice(
    OpenAirBackdropPageStretchFirstIndex,
    OpenAirBackdropPageStretchLastIndex
  );

  console.log(OpenAirBackdropPageStretchSlider);

  const UpgradeBackdropStretchPageCount = Math.ceil(
    stretch_Add_Backdrop.length / DataPerPage
  );

  const nextUpgradeBackdropStretch = () => {
    if (OpenAirUpgradeBackDropStretchPage < UpgradeBackdropStretchPageCount) {
      setOpenAirUpgradeBackDropStretchPage(
        OpenAirUpgradeBackDropStretchPage + 1
      );
    }
  };

  const previousUpgradeBackdropStretch = () => {
    if (OpenAirUpgradeBackDropStretchPage > 1) {
      setOpenAirUpgradeBackDropStretchPage((prev) => {
        let data = prev - 1;
        return data;
      });
    }
  };

  const OpenAirFourBySixPageCount = Math.ceil(
    Backdrop_list_FourBySix.length / DataPerPage
  );
  const OpenAirFourBySixBackdropPageLastIndex =
    OpenAirBacdropFourBySixPage * DataPerPage;

  const OpenAirFourBySixBackdropPageFirstIndex =
    OpenAirFourBySixBackdropPageLastIndex - DataPerPage;

  const OpenAirBacdropFourBySixSlider = Backdrop_list_FourBySix.slice(
    OpenAirFourBySixBackdropPageFirstIndex,
    OpenAirFourBySixBackdropPageLastIndex
  );

  const nextBacdropFourBySix = () => {
    if (OpenAirBacdropFourBySixPage < OpenAirFourBySixPageCount) {
      setOpenAirBacdropFourBySixPage((prev) => {
        let data = prev + 1;
        return data;
      });
    }
  };

  const previousBackdropFourBySix = () => {
    if (OpenAirBacdropFourBySixPage > 1) {
      setOpenAirBacdropFourBySixPage((prev) => {
        let data = prev - 1;
        return data;
      });
    }
  };

  const OpenAirFourBySixTempPageCount = Math.ceil(
    Template_imagesFourBySix.length / 10
  );

  const OpenAirTempFourBySixSlider = Template_imagesFourBySix;

  const nextTempFourBySix = () => {
    if (OpenAirTempFourBySixPage < OpenAirFourBySixTempPageCount) {
      setOpenAirTempFourBySixPage((prev) => {
        let data = prev + 1;
        return data;
      });
    }
  };

  const previousTempFourBySix = () => {
    if (OpenAirTempFourBySixPage > 1) {
      setOpenAirTempFourBySixPage((prev) => {
        let data = prev - 1;
        return data;
      });
    }
  };

  const nextUpgradeBackdropSequin = () => {
    if (OpenAirUpgradeBackDropSequinPage < UpgradeBackdropSequinPageCount) {
      setOpenAirUpgradeBackDropSequinPage((prev) => {
        let data = prev + 1;
        return data;
      });
    }
  };

  const previousUpgradeBackdropSequin = () => {
    if (OpenAirUpgradeBackDropSequinPage > 1) {
      setOpenAirUpgradeBackDropSequinPage((prev) => {
        let data = prev - 1;
        return data;
      });
    }
  };

  const initialDataBackDrop = [
    {
      title: "Sequins",
      content: {
        Sequin_Add_Backdrop,
      },
    },
    {
      title: "Stretch",
      content: {
        stretch_Add_Backdrop,
      },
    },
    {
      title: "Green Screen",
      content: {
        Green_Add_Backdrop,
      },
    },
  ];

  const [data, setData] = useState(initialDataBackDrop);

  // const changeBackDrop = (type) => {
  //   const tempData = [...data];
  //   tempData.forEach((ele) => {
  //     if (type === "4_6") {
  //       if ((ele.title = "Sequins")) {
  //         ele.content.OpenAirBackdropPageSequinSlider = Sequin_4_6;
  //       } else if ("Stretch") {
  //         ele.content.OpenAirBackdropPageStretchSlider = stretch_4_6;
  //       } else if ("Green Screen") {
  //         ele.content.Green_Add_Backdrop = Green_4_6;
  //       }
  //     } else if (type === "2_6") {
  //       if ((ele.title = "Sequins")) {
  //         ele.content.OpenAirBackdropPageSequinSlider = Sequin_2_6;
  //       } else if ("Stretch") {
  //         ele.content.OpenAirBackdropPageStretchSlider = stretch_2_6;
  //       } else if ("Green Screen") {
  //         ele.content.Green_Add_Backdrop = Green_2_6;
  //       }
  //     } else {
  //       if ((ele.title = "Sequins")) {
  //         ele.content.OpenAirBackdropPageSequinSlider = Sequin_Add_Backdrop;
  //       } else if ("Stretch") {
  //         ele.content.OpenAirBackdropPageStretchSlider = stretch_Add_Backdrop;
  //       } else if ("Green Screen") {
  //         ele.content.Green_Add_Backdrop = Green_Add_Backdrop;
  //       }
  //     }
  //   });

  //   setData(tempData);
  // };

  const propSelecteCLick = (item) => {
    if (selectedProps[item.id]) {
      selectedProps.splice(item.id, 1);
      setSelectedProps([...selectedProps]);
      // $(`.redStartMain_${item.id}`).css("border", "1px solid black");
    } else {
      setSelectedProps([...selectedProps, item]);
    }
  };

  const handleChangeUploadImage = (e) => {
    setTabmplate(`${URL.createObjectURL(e.target.files[0])}`);
  };

  const chooseDesignChange = (e, key) => {
    console.log(e.target.min, e.target.max, "erre");
    if (key === "dateChange") {
      setChooseDesign({ ...chooseDesign, dateChange: e.target.value });
    } else if (key === "titleChange") {
      setChooseDesign({ ...chooseDesign, titleChange: e.target.value });
    } else if (key === "AgeCount") {
      setChooseDesign({ ...chooseDesign, AgeCount: e.target.value });
    } else {
      setChooseDesign({
        ...chooseDesign,
        occationChooseDesign: e.target.value,
      });
    }
  };

  console.log(chooseDesign, "chooseDesign");

  const photoBoothTypeChange = (e) => {
    const { value } = e.target;
    if (value === "Open_air") {
      setTabmplate("");
      setBoothType({
        threeSixty: { flag: false },
        ledInlfatable: { flag: false },
        openAirState: { flag: true },
        value: value,
      });
    } else if (value === "360") {
      setBoothType({
        ledInlfatable: { flag: false },
        openAirState: { flag: false },
        threeSixty: { flag: true },
        value: value,
      });
    } else if (value === "LED_Inflatable") {
      setTabmplate("");
      setBoothType({
        openAirState: { flag: false },
        threeSixty: { flag: false },
        ledInlfatable: { flag: true },
        value: value,
      });
    } else {
      setBoothType({
        openAirState: { flag: false },
        threeSixty: { flag: false },
        ledInlfatable: { flag: false },
      });
    }
  };

  const backdropClick = (backdrop, index) => {
    if (conditionPrintSize === "4X6") {
      let backdropFourBySixTitle = Backdrop_list_FourBySix.find(
        (item) => item.title === backdrop.imgTitle
      );
      selectBackDrop(backdropFourBySixTitle);
      setUpgradBackdropTitle({ title: backdrop.imgTitle, index: index });
    } else {
      let backdropTwoBySixTitle = Backdrop_list.find(
        (item) => item.title === backdrop.imgTitle
      );
      selectBackDrop(backdropTwoBySixTitle);
      setUpgradBackdropTitle({ title: backdrop.imgTitle, index: index });
    }
    setUpdateBackdrop(backdrop.img);
  };

  const handleCarpetClick = () => {
    setCarpet(!carpet);
  };

  const handleClickAddRemoveUpgradedBackdrop = () => {
    setAddRemoveUpgradedBackdrop(!AddRemoveUpgradedBackdrop);
    // changeBackDrop();
  };

  const handleMemoryBookClick = () => {
    setMemoryBook(!memoryBook);
  };

  const templateClick = (temp, index) => {
    setTempIndex(index);
    setTabmplate(temp);
  };

  const learnMore = () => {
    setLearnMoreSection(!learnMoreSection);
  };

  function handleTabClick(index, tab) {
    setConditionPrintSize(tab.title);
    if (tab.title === "2X6") {
      setBackdropImage("");
    } else if (tab.title === "4X6") {
      setBackdropImage("");
    }
    setActiveTab(index);
    setPrintIndexTab(index);
    if (index === 0) {
      setFourBy(false);
      setTwoBy(true);
      setTabmplate(none_typeA);
      setSubjectImage(subject_1);
    } else if (index === 1) {
      setTwoBy(false);
      setFourBy(true);
      setTabmplate(none);
      setSubjectImage(PeopleFourBySix);
    } else {
      setTwoBy(false);
      setFourBy(false);
    }
  }

  const handleChooseDesignToogle = () => {
    setChooseDesignToggle(!chooseDesignToggle);
  };

  const selectBackDrop = (backdrop) => {
    setBackdropImage(backdrop.img);
  };

  const selectSubjectImage = (subjectImage) => {
    setSubjectImage(subjectImage.img);
    // fileRef.current.files[0].name = "";
  };

  const [color, setColor] = useState({ h: 0, s: 0, l: 0, a: 1 });

  const handleChangeColor = (newColor) => {
    console.log(newColor, "newColor");
    setColor(newColor);
  };
  const ref = useRef();

  const TempNextAutoChange = () => {
    document.getElementById(`templateAotoChange_${TempIndex + 1}`).click();
  };

  const TemPravAutoChange = () => {
    document.getElementById(`templateAotoChange_${TempIndex - 1}`).click();
  };

  const TemPravAutoChange_2X6 = () => {
    document
      .getElementById(`templateAotoChangeTwoBySix_${TempIndex - 1}`)
      .click();
  };

  const TempNextAutoChange_2X6 = () => {
    document
      .getElementById(`templateAotoChangeTwoBySix_${TempIndex + 1}`)
      .click();
  };

  const IncludedChange = (key) => {
    if (key === "black") {
      document.getElementsByClassName("photoBooth_area")[0].style.visibility =
        "visible";
      document.getElementsByClassName("none")[0].style.border =
        "1px solid black";
      document.getElementsByClassName("white")[0].style.border =
        "1px solid black";
      document.getElementsByClassName("black")[0].style.border =
        "3.5px solid #b402ba";
      setUpdateBackdrop(IncludedBlack);
    } else if (key === "white") {
      document.getElementsByClassName("none")[0].style.border =
        "1px solid black";
      document.getElementsByClassName("black")[0].style.border =
        "1px solid black";
      document.getElementsByClassName("white")[0].style.border =
        "3.5px solid #b402ba";
      document.getElementsByClassName("photoBooth_area")[0].style.visibility =
        "visible";
      setUpdateBackdrop(IncludedWhite);
    } else if (key === "none") {
      document.getElementsByClassName("black")[0].style.border =
        "1px solid black";
      document.getElementsByClassName("white")[0].style.border =
        "1px solid black";
      document.getElementsByClassName("none")[0].style.border =
        "3.5px solid #b402ba";
      document.getElementsByClassName("photoBooth_area")[0].style.visibility =
        "hidden";
    } else {
      document.getElementsByClassName("photoBooth_area")[0].style.visibility =
        "visible";
      document.getElementsByClassName("none")[0].style.border =
        "1px solid black";
      document.getElementsByClassName("white")[0].style.border =
        "1px solid black";
      document.getElementsByClassName("black")[0].style.border =
        "3.5px solid #b402ba";
      setUpdateBackdrop(IncludedBlack);
    }
  };

  useEffect(() => {
    if (openAirState.flag) {
      document.getElementsByClassName("black")[0].style.border =
        "3.5px solid #b402ba";
    }
  }, [openAirState.flag]);

  const [activeTabBackdropSection, setAactiveTabBackdropSection] = useState(0);

  function handleTabClickBackDropIndex(tab, index) {
    setAactiveTabBackdropSection(index);
    setUpgradBackdropTitle({ title: "", index: index });
  }

  const handleSave = () => {
    if (ref.current === null) {
      return;
    }
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        fetch(`${process.env.REACT_APP_BASE_URL}photobooth`, {
          method: "post",
          headers: {
            "x-access-token": `${token}`,
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            event_id: JSON.stringify(eventId),
            type: boothType.value,
            add_song: songName,
            occasion: occation,
            image: dataUrl,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            getDesignList();
            SweetAlertPopup(
              "Successfully Save",
              "Data Save",
              "success",
              "green"
            );
          })
          .catch((error) => console.log("error", error));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDesignList = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}getphotobooth?event_id=${eventId}`,
      {
        method: "get",
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setDesignListData(result.data.reverse()[0]);
      })
      .catch((error) => console.log("error", error));
  };

  const handleDownload = (dataUrl) => {
    const link = document.createElement("a");
    link.download = "finalDesign.png";
    link.href = dataUrl;
    link.click();
  };

  useEffect(() => {
    console.log("ImageLoad", ImageLoad);
    if (ImageLoad && ImageLoad.current) {
      ImageLoad.current.onLoad = () => {
        console.log("====================================");
        console.log("loaded");
        console.log("====================================");
      };
    }
    getDesignList();
  }, []);

  return (
    <>
      <div className="row">
        {!openAirState.flag && !threeSixty.flag && !ledInlfatable.flag && (
          <div className="main_Container right-scroll col-xl-7 col-md-6 col-12 second-box-details">
            <>
              <div className="Main_details_section second-row-images-box">
                <img
                  src={all_default}
                  alt="Open air"
                  ref={ImageLoad}
                  className="rightSide-image-section"
                />
              </div>
            </>
          </div>
        )}

        {openAirState.flag && (
          <div className="main_Container left-scroll col-xl-7 col-md-6 col-12 second-box-details p-0">
            <>
              {twoBy ? (
                <>
                  <p className="Tempprev_Paragraph">
                    <GrPrevious
                      className="Tempprev"
                      onClick={TemPravAutoChange_2X6}
                    />
                  </p>
                  <div
                    className="Main_details_section second-row-images-box"
                    id="canvasFourBySix"
                    ref={ref}
                  >
                    <div className="template_design_change_twoBySix">
                      <span
                        className="ChangeDesign_titleChange_Default_twoBySix"
                        style={{
                          color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                        }}
                      >
                        {titleChange}
                      </span>
                      &nbsp; &nbsp;
                      <span
                        className="ChangeDesign_titleChange_Default_twoBySix"
                        style={{
                          color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                        }}
                      >
                        {AgeCount}
                      </span>
                      {AgeCount && (
                        <sup
                          className="ChangeDesign_titleChange_Default_twoBySix"
                          style={{
                            color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                          }}
                        >
                          th
                        </sup>
                      )}
                      <br />
                      <span
                        className="ChangeDesign_occationChooseDesign_Default_twoBySix"
                        style={{
                          color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                        }}
                      >
                        {occationChooseDesign}
                      </span>
                      <br />
                      <span
                        className="ChangeDesign_dateChange_Default_twoBySix"
                        style={{
                          color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                        }}
                      >
                        {dateChange}
                      </span>
                    </div>
                    {subjectImage && (
                      <img
                        src={subjectImage}
                        alt="twoBySix"
                        className="mid_section subject_image "
                      />
                    )}
                    {backdropimage && (
                      <img
                        src={backdropimage}
                        alt="twoBysix"
                        className="mid_section twobySix"
                      />
                    )}
                    {template && (
                      <img
                        src={template}
                        alt="twoBysix"
                        className="mid_section twoSix"
                      />
                    )}
                    {background2_6 && (
                      <img
                        src={background2_6}
                        alt="background_image2X6"
                        className="mid_section twoBySix"
                      />
                    )}
                  </div>
                  <p className="TempNext_Paragraph">
                    <GrNext
                      className="TempNext"
                      onClick={TempNextAutoChange_2X6}
                    />
                  </p>
                </>
              ) : (
                fourBy && (
                  <div>
                    <p className="Tempprev_Paragraph">
                      <GrPrevious
                        className="Tempprev"
                        onClick={TemPravAutoChange}
                      />
                    </p>
                    <div
                      className="Main_details_section_fourBySix second-row-images-box "
                      id="canvasFourBySix"
                      ref={ref}
                    >
                      <div className="template_design_change">
                        <span
                          className="ChangeDesign_titleChange_Default"
                          style={{
                            color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                          }}
                        >
                          {titleChange}
                        </span>
                        &nbsp; &nbsp;
                        <span
                          className="ChangeDesign_titleChange_Default"
                          style={{
                            color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                          }}
                        >
                          {AgeCount}
                        </span>
                        {AgeCount && (
                          <sup
                            className="ChangeDesign_titleChange_Default"
                            style={{
                              color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                            }}
                          >
                            th
                          </sup>
                        )}
                        <br />
                        <span
                          className="ChangeDesign_occationChooseDesign_Default"
                          style={{
                            color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                          }}
                        >
                          {occationChooseDesign}
                        </span>
                        <br />
                        <span
                          className="ChangeDesign_dateChange_Default"
                          style={{
                            color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                          }}
                        >
                          {dateChange}
                        </span>
                      </div>
                      {subjectImage && (
                        <img
                          src={subjectImage}
                          id="subjectImage"
                          alt="twoBySix"
                          className="mid_section subject_imageFour subjectImage4by6"
                        />
                      )}

                      {backdropimage && (
                        <img
                          src={backdropimage}
                          id="backdropImage"
                          alt="twoBysix"
                          className="mid_section fourSix backdrop"
                        />
                      )}
                      {template && (
                        <img
                          src={template}
                          id="template"
                          alt="twoBysix"
                          className="mid_section fourSix"
                          // style={
                          //   chooseDesignToggle
                          //     ? {
                          //         backgroundColor: `hsl(${backgroundColor.h},${backgroundColor.s}%,${backgroundColor.l}%)`,
                          //       }
                          //     : { backgroundColor: "" }
                          // }
                        />
                      )}
                      {background4_6 && (
                        <img
                          src={background4_6}
                          id="background4_6"
                          alt="background_image4X6"
                          className="mid_section fourBySixBack"
                        />
                      )}
                    </div>
                    <p className="TempNext_Paragraph">
                      <GrNext
                        className="TempNext"
                        onClick={TempNextAutoChange}
                      />
                    </p>
                  </div>
                )
              )}

              {!twoBy && !fourBy && (
                <div className="Main_details_section second-row-images-box">
                  <div className="right_side_image_section">
                    <img
                      alt="Open air"
                      className="rightSide-image-section KioskOpenAirImage"
                      src={KioskOpenAirImage}
                    />

                    <img
                      alt="Open air"
                      className="rightSide-image-section PropTableImage"
                      src={PropTableImage}
                    />

                    {carpet && (
                      <img
                        alt="Open air"
                        className="rightSide-image-section Red_CarptetImage"
                        src={Red_CarptetImage}
                      />
                    )}

                    <img
                      alt="Open air"
                      className="rightSide-image-section photoBooth_area"
                      src={upgradBackdrop}
                    />
                  </div>
                </div>
              )}
            </>
            {(twoBy || fourBy) && (
              <>
                <table className="table table-striped table-hover user_Design_list_table ">
                  <thead>
                    <tr className="head_table">
                      <th>Created_At</th>
                      <th>Occasion</th>
                      <th>Final Design</th>
                    </tr>
                  </thead>
                  <tbody>
                    {designListData && (
                      <tr>
                        <td>
                          {moment(designListData?.created_at).format(
                            "DD MMM YYYY"
                          )}
                        </td>

                        <td>{designListData?.occasion}</td>
                        {designListData?.download_image && (
                          <td
                            onClick={() =>
                              handleDownload(designListData?.download_image)
                            }
                          >
                            Download Design
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        )}

        {threeSixty.flag && (
          <div className="main_Container right-scroll col-xl-7 col-md-6 col-12 second-box-details">
            <>
              <div className="Main_details_section second-row-images-box">
                <div className="right_side_image_section">
                  <img
                    src={threeSixtyBanner}
                    alt="360 banner"
                    className="rightSide-image-section image_360"
                  />
                </div>
              </div>
            </>
          </div>
        )}

        {ledInlfatable.flag && (
          <div className="main_Container left-scroll col-xl-7 col-md-6 col-12 second-box-details">
            <>
              {twoBy ? (
                <div
                  className="Main_details_section second-row-images-box"
                  id="canvasFourBySix"
                  ref={ref}
                >
                  {subjectImage && (
                    <img
                      src={subjectImage}
                      alt="twoBySix"
                      className="mid_section subject_image"
                    />
                  )}
                  {template && (
                    <img
                      src={template}
                      alt="twoBysix"
                      className="mid_section twoSix"
                    />
                  )}

                  {/* {background2_6 && (
                    
                    <img
                      src={background2_6}
                      alt="background_image2X6"
                      className="mid_section twoBySix"
                    />
                  )} */}

                  {background2_6 && (
                    <>
                      <p className="Tempprev_Paragraph">
                        <GrPrevious
                          className="Tempprev"
                          onClick={TemPravAutoChange_2X6}
                        />
                      </p>
                      <img
                        src={background2_6}
                        alt="background_image2X6"
                        className="mid_section twoBySix"
                      />
                      <p className="TempNext_Paragraph">
                        <GrNext
                          className="TempNext"
                          onClick={TempNextAutoChange_2X6}
                        />
                      </p>
                    </>
                  )}
                </div>
              ) : (
                fourBy && (
                  <div
                    className="Main_details_section_fourBySix second-row-images-box"
                    id="canvasFourBySix"
                    ref={ref}
                  >
                    {subjectImage && (
                      <img
                        src={subjectImage}
                        alt="twoBySix"
                        className="mid_section subject_imageFour subjectImage4by6"
                        // style={{
                        //   color: `hsl(${color.h},${color.s}%,${color.l}%)`,
                        // }}
                      />
                    )}

                    {/* {backdropimage && (
                      <img
                        src={backdropimage}
                        alt="twoBysix"
                        className="mid_section fourSix"
                      />
                    )} */}
                    {template && (
                      <img
                        src={template}
                        alt="twoBysix"
                        className="mid_section fourSix"
                      />
                    )}
                    {background4_6 && (
                      <>
                        <p className="Tempprev_Paragraph">
                          <GrPrevious
                            className="Tempprev"
                            onClick={TemPravAutoChange}
                          />
                        </p>
                        <img
                          src={background4_6}
                          alt="background_image4X6"
                          className="mid_section fourBySixBack"
                        />
                        <p className="TempNext_Paragraph">
                          <GrNext
                            className="TempNext"
                            onClick={TempNextAutoChange}
                          />
                        </p>
                      </>
                    )}
                  </div>
                )
              )}

              {!twoBy && !fourBy && (
                <div className="Main_details_section second-row-images-box">
                  <div className="right_side_image_section">
                    <img
                      alt="led banner"
                      className="rightSide-image-section KioskOpenAirImage"
                      src={led_banner}
                    />
                  </div>
                </div>
              )}
            </>
          </div>
        )}

        {/* -------------------------------------mid-end---------------------------------------- */}
        <div className="main_Container right-scroll col-xl-5 col-md-6 col-12">
          <>
            <div className="row">
              <div className="Main_details_section col-md-12 title_booth_type">
                <p className="title_Occasion">Booth type</p>
              </div>
              <div className="col-md-12 title_booth_main_section">
                <select
                  className={`form-select form-select-lg  selectSection selectBoothType ${
                    threeSixty.flag ? "col-md-12" : ""
                  } `}
                  aria-label=".form-select-lg example"
                  onChange={photoBoothTypeChange}
                >
                  <option>Choose Booth Type</option>
                  {boothTypeOption.map((option, index) => {
                    return (
                      <option value={option.value} key={index}>
                        {option.title}
                      </option>
                    );
                  })}
                </select>

                {!threeSixty.flag && (
                  <button
                    className={
                      learnMoreSection
                        ? `${"title_booth_btn_active"}`
                        : `${"title_booth_btn"}`
                    }
                    onClick={learnMore}
                    type="button"
                    data-toggle="modal"
                    data-target="#largeModal"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Learn More
                  </button>
                )}
              </div>
            </div>

            {/* <div className="Main_details_section">
              <p className="title_Occasion">Occasion</p>
              <select
                className="form-select form-select-lg  selectSection"
                aria-label=".form-select-lg example"
              >
                {OccasionOptions.map((option, index) => {
                  return <option key={index}>{option}</option>;
                })}
              </select>
            </div> */}

            {openAirState.flag && learnMoreSection && (
              <>
                {/* <div className="title_section">
                  <button
                    type="button"
                    className="title_BoothType pop_up"
                    data-toggle="modal"
                    data-target="#largeModal"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Details
                  </button>
                </div>
                <div className="include_backdropColor_section ">
                  <p
                    type="button"
                    className="title_BoothType pop_up m-0"
                    data-toggle="modal"
                    data-target="#largeModal"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                  >
                    Choose 3 custom props
                  </p>
                </div>

                <div className="include_backdropColor_section ">
                  <p
                    className="title_BoothType pop_up title_Occasion"
                    style={{
                      margin: "0",
                      backgroundColor: "#b402ba",
                      color: "#fff",
                    }}
                    onClick={handleMemoryBookClick}
                  >
                    Add/Remove Memory Book
                  </p>
                </div>
                <div className="d-flex align-items-start pt-1">
                  <img
                    src={memoryBookImg}
                    alt="memoryBook"
                    className="memoryBook"
                  />
                </div> */}
              </>
            )}

            {openAirState.flag && (
              <>
                <div className="include_backdropColor_section ">
                  <p className="title_Occasion">
                    <b>Backdrop</b>
                    <br />
                    Included
                  </p>
                  <div className="OpenAirRadio_Section">
                    <div className="label_title">
                      <label
                        className="labelCircle black"
                        onClick={() => IncludedChange("black")}
                      >
                        <input type="radio" name="test" />
                      </label>
                      <p>Black</p>
                    </div>

                    <div className="label_title">
                      <label
                        className="labelCircle white"
                        onClick={() => IncludedChange("white")}
                      >
                        <input type="radio" name="test" />
                      </label>
                      <p>White</p>
                    </div>

                    <div className="label_title">
                      <label
                        className="labelCircle none red_line"
                        onClick={() => IncludedChange("none")}
                      >
                        <input type="radio" name="test" />
                      </label>
                      <p>None</p>
                    </div>
                  </div>
                </div>

                <div className="add_remove_update_background pt-2">
                  <p
                    className={
                      AddRemoveUpgradedBackdrop
                        ? `${"title_BoothType pop_up title_Occasion active_carpet"}`
                        : `${"title_BoothType pop_up title_Occasion inActive_carpet"}`
                    }
                    onClick={handleClickAddRemoveUpgradedBackdrop}
                  >
                    Add/Remove Upgraded Backdrop
                  </p>
                  {AddRemoveUpgradedBackdrop && (
                    <div className="tabs_Compo">
                      <Tabs
                        tabs={data}
                        backdropClick={backdropClick}
                        previousUpgradeBackdropSequin={
                          previousUpgradeBackdropSequin
                        }
                        nextUpgradeBackdropSequin={nextUpgradeBackdropSequin}
                        nextUpgradeBackdropStretch={nextUpgradeBackdropStretch}
                        previousUpgradeBackdropStretch={
                          previousUpgradeBackdropStretch
                        }
                        activeTabBackdropSection={activeTabBackdropSection}
                        handleTabClickBackDropIndex={
                          handleTabClickBackDropIndex
                        }
                        upgradBackdropTitle={upgradBackdropTitle}
                      />
                    </div>
                  )}
                </div>

                <div className="include_backdropColor_section ">
                  <p
                    className={
                      carpet
                        ? `${"title_BoothType pop_up title_Occasion active_carpet"}`
                        : `${"title_BoothType pop_up title_Occasion inActive_carpet"}`
                    }
                    onClick={handleCarpetClick}
                  >
                    Add/Remove red carpet
                  </p>
                </div>

                <div className="add_remove_update_background pt-2">
                  <p className="title_Occasion "> Print Size</p>
                  <div className="tabs_Compo">
                    <TabsTwoNdSix
                      tabs={[
                        {
                          title: "2X6",
                          content: {
                            data: [
                              // {
                              //   title: "Subjects Images",
                              //   Subjects_Image: [
                              //     {
                              //       img: subject_1,
                              //       title: "type A - people",
                              //     },
                              //     {
                              //       img: subject_2,
                              //       title: "type B - people",
                              //     },
                              //     {
                              //       img: subject_3,
                              //       title: "type C - people",
                              //     },
                              //     {
                              //       img: subject_5,
                              //       title: "type D - people",
                              //     },
                              //   ],
                              // },

                              // {
                              //   title: "Backdrop Images",
                              //   OpenAirBackdropSlider,
                              // },

                              {
                                title: "Template Images",
                                OpenAirTemplateSlider,
                              },
                            ],
                          },
                        },
                        {
                          title: "4X6",
                          content: {
                            data: [
                              // {
                              //   title: "Subjects Images",
                              //   Subjects_Image: [
                              //     {
                              //       img: PeopleFourBySix,
                              //       title: "people",
                              //     },
                              //   ],
                              // },

                              // {
                              //   title: "Backdrop Images",
                              //   OpenAirBacdropFourBySixSlider,
                              // },
                              {
                                title: "Template Images",
                                OpenAirTempFourBySixSlider,
                              },
                            ],
                          },
                        },
                      ]}
                      templateClick={templateClick}
                      handleTabClick={handleTabClick}
                      selectBackDrop={selectBackDrop}
                      selectSubjectImage={selectSubjectImage}
                      activeTab={activeTab}
                      previousTemplate={previousTemplate}
                      nextTemplate={nextTemplate}
                      previousBackdrop={previousBackdrop}
                      nextbackdrop={nextbackdrop}
                      previousBackdropFourBySix={previousBackdropFourBySix}
                      nextBacdropFourBySix={nextBacdropFourBySix}
                      nextTempFourBySix={nextTempFourBySix}
                      previousTempFourBySix={previousTempFourBySix}
                      printIndexTab={printIndexTab}
                      TempNextAutoChange_2X6={TempNextAutoChange_2X6}
                      TemPravAutoChange_2X6={TemPravAutoChange_2X6}
                      TempNextAutoChange={TempNextAutoChange}
                      TemPravAutoChange={TemPravAutoChange}
                      twoBy={twoBy}
                    />
                  </div>
                </div>

                <div className="Main_details_section col-md-12">
                  {/* <p className="title_Occasion">Occasion</p> */}
                  <select
                    className="form-select form-select-lg  selectSection occasionSelect"
                    aria-label=".form-select-lg example"
                    onChange={occationChange}
                  >
                    <option value="choose_occasion">Choose occasion</option>
                    {OccasionOptions.map((option, index) => {
                      return (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                  {!occation && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginTop: "3px",
                      }}
                    >
                      please select occasion
                    </p>
                  )}
                </div>

                <div className="include_backdropColor_section ">
                  <p
                    onClick={handleChooseDesignToogle}
                    className={
                      chooseDesignToggle
                        ? `${"title_BoothType pop_up title_Occasion active_Choose_Design"}`
                        : `${"title_BoothType pop_up title_Occasion inActive_Choose_Design"}`
                    }
                  >
                    Choose Design
                  </p>

                  {chooseDesignToggle && (
                    <>
                      <div className="container">
                        <div className="row">
                          <input
                            placeholder="Name"
                            className="col-7 Choose_Design_title"
                            name="titleChange"
                            value={titleChange}
                            onChange={(e) =>
                              chooseDesignChange(e, "titleChange")
                            }
                          />

                          <input
                            placeholder="Age Count"
                            className="col-4 ml-1 Choose_Design_title"
                            name="AgeCount"
                            type="number"
                            min="1"
                            max="3"
                            value={AgeCount}
                            onChange={(e) => chooseDesignChange(e, "AgeCount")}
                          />

                          {/* <p className="col-2">
                            <MdOutlineColorLens className="MdOutlineColorLens_icon" />
                          </p> */}
                        </div>
                      </div>

                      <div className="container">
                        <div className="row">
                          <input
                            placeholder="Occation Name"
                            className="col-11 Choose_Design_title"
                            name="dateChange"
                            value={occationChooseDesign}
                            onChange={(e) =>
                              chooseDesignChange(e, "occationChooseDesign")
                            }
                          />
                          {/* <p className="col-2">
                            <MdOutlineColorLens className="MdOutlineColorLens_icon" />
                          </p> */}
                        </div>
                      </div>

                      <div className="container">
                        <div className="row">
                          <input
                            placeholder="Date"
                            className="col-11 Choose_Design_title"
                            name="dateChange"
                            value={dateChange}
                            onChange={(e) =>
                              chooseDesignChange(e, "dateChange")
                            }
                          />
                          {/* <p className="col-2">
                            <MdOutlineColorLens className="MdOutlineColorLens_icon" />
                          </p> */}
                        </div>
                      </div>

                      <div>
                        <p className="Choose_Design">Change Text Color</p>

                        <div className="row">
                          <div
                            className="col-2 colorOutput"
                            style={{
                              background: `hsl(${color.h},${color.s}%,${color.l}%)`,
                            }}
                          ></div>
                          <div className="col-7 colorSlider">
                            <HueSlider
                              handleChangeColor={handleChangeColor}
                              color={color}
                            />

                            <SaturationSlider
                              handleChangeColor={handleChangeColor}
                              color={color}
                            />
                            <LightnessSlider
                              handleChangeColor={handleChangeColor}
                              color={color}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div>
                        <p className="Choose_Design">Background</p>
                        <div className="row">
                          <div
                            className="col-2 colorOutput"
                            style={{
                              backgroundColor: `hsl(${backgroundColor.h},${backgroundColor.s}%,${backgroundColor.l}%)`,
                            }}
                          ></div>
                          <div className="col-7 colorSlider">
                            <HueSlider
                              handleChangeColor={handleChangeBackground}
                              color={backgroundColor}
                            />

                            <SaturationSlider
                              handleChangeColor={handleChangeBackground}
                              color={backgroundColor}
                            />
                            <LightnessSlider
                              handleChangeColor={handleChangeBackground}
                              color={backgroundColor}
                            />
                          </div>
                        </div>
                      </div> */}
                    </>
                  )}
                </div>

                {(fourBy || twoBy) && (
                  <>
                    <div className="include_backdropColor_section Upload_fileRef">
                      <p className="Upload_btn title_BoothType pop_up title_Occasion">
                        Upload My Design image
                      </p>

                      <input
                        ref={fileRef}
                        type="file"
                        onChange={handleChangeUploadImage}
                        className="fileRef_Input"
                      />
                    </div>

                    {/* <div className="include_backdropColor_section ">
                      <div className="Upload_btn title_BoothType pop_up title_Occasion">
                        <button
                          onClick={handleDownload}
                          className="DownloadButton"
                        >
                          Download
                        </button>
                      </div>
                    </div> */}

                    <div>
                      <button
                        className={
                          !occation
                            ? "DownloadButton_Disable"
                            : "DownloadButton"
                        }
                        disabled={!occation}
                        onClick={handleSave}
                      >
                        Save Final Design
                      </button>
                    </div>
                  </>
                )}
              </>
            )}

            {threeSixty.flag && (
              <div style={{ marginTop: "10px" }}>
                <label>Add Songs</label>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "100px" }}
                    value={songName}
                    name="songName"
                    onChange={(e) => setSongName(e.target.value)}
                  />
                </div>

                {/* <div className="Upload_btn title_BoothType pop_up title_Occasion mt-4">
                  <button className="DownloadButton" onClick={handleSave}>
                    Save Song
                  </button>
                </div> */}
              </div>
            )}

            {ledInlfatable.flag && (
              <>
                <>
                  <div className="add_remove_update_background pt-2">
                    <p className="title_Occasion "> Print Size</p>
                    <div className="tabs_Compo">
                      <TabsTwoNdSix
                        tabs={[
                          {
                            title: "2X6",
                            content: {
                              data: [
                                {
                                  title: "Template Images",
                                  OpenAirTemplateSlider,
                                },
                              ],
                            },
                          },
                          {
                            title: "4X6",
                            content: {
                              data: [
                                {
                                  title: "Template Images",
                                  OpenAirTempFourBySixSlider,
                                },
                              ],
                            },
                          },
                        ]}
                        templateClick={templateClick}
                        handleTabClick={handleTabClick}
                        selectBackDrop={selectBackDrop}
                        selectSubjectImage={selectSubjectImage}
                        activeTab={activeTab}
                        previousTemplate={previousTemplate}
                        nextTemplate={nextTemplate}
                        previousBackdrop={previousBackdrop}
                        nextbackdrop={nextbackdrop}
                        previousBackdropFourBySix={previousBackdropFourBySix}
                        nextBacdropFourBySix={nextBacdropFourBySix}
                        nextTempFourBySix={nextTempFourBySix}
                        previousTempFourBySix={previousTempFourBySix}
                        printIndexTab={printIndexTab}
                        TempNextAutoChange_2X6={TempNextAutoChange_2X6}
                        TemPravAutoChange_2X6={TemPravAutoChange_2X6}
                        TempNextAutoChange={TempNextAutoChange}
                        TemPravAutoChange={TemPravAutoChange}
                        twoBy={twoBy}
                      />
                    </div>
                  </div>

                  <div className="Main_details_section col-md-12">
                    {/* <p className="title_Occasion">Occasion</p> */}
                    <select
                      className="form-select form-select-lg  selectSection occasionSelect"
                      aria-label=".form-select-lg example"
                    >
                      {OccasionOptions.map((option, index) => {
                        return <option key={index}>{option}</option>;
                      })}
                    </select>
                  </div>

                  <div className="include_backdropColor_section ">
                    <p
                      onClick={handleChooseDesignToogle}
                      className={
                        chooseDesignToggle
                          ? `${"title_BoothType pop_up title_Occasion active_Choose_Design"}`
                          : `${"title_BoothType pop_up title_Occasion inActive_Choose_Design"}`
                      }
                    >
                      Choose Design
                    </p>

                    {chooseDesignToggle && (
                      <>
                        <div className="container">
                          <div className="row">
                            <input
                              placeholder="Title"
                              className="col-10 Choose_Design_title"
                            />
                            <p className="col-2">
                              <MdOutlineColorLens className="MdOutlineColorLens_icon" />
                            </p>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <input
                              placeholder="Date"
                              className="col-10 Choose_Design_title"
                              // type="date"
                            />
                            <p className="col-2">
                              <MdOutlineColorLens className="MdOutlineColorLens_icon" />
                            </p>
                          </div>
                        </div>
                        {/* <div>
                          <p className="Choose_Design">Change Text Color</p>
                          <div className="row">
                            <div
                              className="col-2 colorOutput"
                              style={{
                                backgroundColor: `hsl(${color.h},${color.s}%,${color.l}%)`,
                              }}
                            ></div>
                            <div className="col-7 colorSlider">
                              <HueSlider
                                handleChangeColor={handleChangeColor}
                                color={color}
                              />

                              <SaturationSlider
                                handleChangeColor={handleChangeColor}
                                color={color}
                              />
                              <LightnessSlider
                                handleChangeColor={handleChangeColor}
                                color={color}
                              />
                            </div>
                          </div>
                        </div> */}

                        {/*
                        <div>
                          <p className="Choose_Design">Background</p>
                          <div className="row">
                            <div
                              className="col-2 colorOutput"
                              style={{
                                backgroundColor: `hsl(${backgroundColor.h},${backgroundColor.s}%,${backgroundColor.l}%)`,
                              }}
                            ></div>
                            <div className="col-7 colorSlider">
                              <HueSlider
                                handleChangeColor={handleChangeBackground}
                                color={backgroundColor}
                              />

                              <SaturationSlider
                                handleChangeColor={handleChangeBackground}
                                color={backgroundColor}
                              />
                              <LightnessSlider
                                handleChangeColor={handleChangeBackground}
                                color={backgroundColor}
                              />
              
                            </div>
                          </div>
                        </div> */}
                      </>
                    )}
                  </div>
                  {(fourBy || twoBy) && (
                    <>
                      <div className="include_backdropColor_section Upload_fileRef">
                        <p className="Upload_btn title_BoothType pop_up title_Occasion">
                          Upload My Design image
                        </p>

                        <input
                          ref={fileRef}
                          type="file"
                          onChange={handleChangeUploadImage}
                          className="fileRef_Input"
                        />
                      </div>

                      {/* <div className="include_backdropColor_section ">
                        <div className="Upload_btn title_BoothType pop_up title_Occasion">
                          <button
                            onClick={handleDownload}
                            className="DownloadButton"
                          >
                            Download
                          </button>
                        </div>
                      </div> */}

                      {/* <div className="include_backdropColor_section ">
                        <div className="Upload_btn title_BoothType pop_up title_Occasion">
                          <button
                            className="DownloadButton"
                            onClick={handleSave}
                          >
                            Save Final Design
                          </button>
                        </div>
                      </div> */}
                    </>
                  )}
                </>

                {/* <div className="include_backdropColor_section ">
                  <p className="title_Occasion">Choose Design</p>
                  <div className="OpenAirRadio_Section"></div>
                </div>

                <div className="include_backdropColor_section ">
                  <p
                    type="button"
                    className="title_BoothType pop_up m-0"
                    data-toggle="modal"
                    data-target="#largeModal"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                  >
                    Choose 3 custom props
                  </p>
                </div>
                <div className="include_backdropColor_section ">
                  <p
                    className="title_BoothType pop_up title_Occasion"
                    style={{
                      margin: "0",
                      backgroundColor: "#b402ba",
                      color: "#fff",
                    }}
                    onClick={handleMemoryBookClick}
                  >
                    Add/Remove Memory Book
                  </p>
                </div>
                <div className="d-flex align-items-start pt-1">
                  <img
                    src={memoryBookImg}
                    alt="memoryBook"
                    className="memoryBook"
                  />
                </div> */}
                {/* <div className="add_remove_update_background pt-2">
                  <p className="title_Occasion ">
                    Add/Remove Custom Printed Backdrop
                  </p>
                  <div className="tabs_Compo">
                    <button className="title_section li_style active m-0 border-none">
                      <span className="title_BoothType-section">
                        Add / Remove
                      </span>
                    </button>
                  </div>
                </div> */}
              </>
            )}
          </>
        </div>
      </div>

      {openAirState.flag && (
        <>
          <Modal
            title="Open Air Photobooth"
            FEATURES="FEATURES"
            Backdrops="Backdrops"
            backdrop_text="You can choose from our many options or If you wish to provide your own backdrop , you can. If you’d like to use our backdrop stands, ensure your backdrop max size is 8ft x 8ft with a top pocket hole."
            Prints="Prints"
            Prints_text="2x6 or 4x6"
            Filters="Filters"
            Filters_text="Black & white or color"
            Instant_mobile_sharing="Instant mobile sharing"
            Instant_mobile_sharing_text="Gifs of the pictures are emailed to guests after each session (Wi-Fi dependant)"
            Picture_Delivery="Picture Delivery"
            Picture_Delivery_text="All pictures are emailed to you on the morning after the event in a viewable, shareable, and downloadable link"
            DETAILS="DETAILS"
            Setup_Size="Setup Size"
            Setup_Size_text="(adjustable): L(6ft-8ft) x W(6ft-8ft) x H(5ft-8ft)"
            Capacity="Capacity"
            Capacity_text=" Fits up to about 10-15 mid sized people in a picture"
            Protection="Protection"
            Protection_text="Metal Base bottoms - with floor protectors if requested"
            Delivery="Delivery"
            Delivery_text="Arrival is 1hr prior to contracted start time. Setup
                        time is about 15-20 minutes. Equipment is rolled in on a
                        6ft x 2ft cart. If there are more than 1 small flight of
                        stairs to enter the location and no elevator, please
                        contact us. There could be an additional $100 charge for
                        an earlier arrival time"
            EQUIPMENT="EQUIPMENT"
            EQUIPMENT_1="Professional DSLR camera"
            EQUIPMENT_2="External flash"
            EQUIPMENT_3="                      Props are set up in a bin on the floor inside the
                      inflatable or on a provided table outside the inflatable"
            EQUIPMENT_4="Touch screen kiosk"
            EQUIPMENT_5="High speed printer"
            REQUIREMENTS="REQUIREMENTS"
            REQUIREMENTS_text="Standard power/power outlet"
          />
          <PropCustomeModal
            CustomePropsList={CustomePropsList}
            propSelecteCLick={propSelecteCLick}
          />
        </>
      )}

      {ledInlfatable.flag && (
        <>
          <Modal
            title="LED Inflatable Photobooth"
            FEATURES="FEATURES"
            Prints="Prints"
            Prints_text="2x6 or 4x6"
            Filters="Filters"
            Filters_text="Black & white or color"
            Props="Props"
            Props_text="1 full bin and your choice of any 3 custom props"
            Instant_mobile_sharing="Instant mobile sharing"
            Instant_mobile_sharing_text="Gifs of the pictures are emailed to guests after each
          session (Wi-Fi dependant)"
            Picture_Delivery="Picture Delivery"
            Picture_Delivery_text="All pictures are emailed to you on the morning after the
          event in a viewable, shareable, and downloadable link"
            DETAILS="DETAILS"
            Setup_Size="Setup Size"
            Setup_Size_text="(adjustable): L(6ft-8ft) x W(6ft-8ft) x H(5ft-8ft)"
            Capacity="Capacity"
            Capacity_text="Fits up to about 5-8 mid sized people in a picture "
            Protection="Protection"
            Protection_text="Metal Base bottoms - with floor protectors if requested"
            Delivery="Delivery"
            Delivery_text=" Equipment is rolled in on a 6ft x 2ft cart. If there are more than 1 small flight of stairs to enter the location and no elevator, please contact us.  There could be an additional $100 charge for an earlier arrival time. "
            EQUIPMENT="EQUIPMENT"
            EQUIPMENT_0="LED inflatable"
            EQUIPMENT_1="Professional DSLR camera"
            EQUIPMENT_2="External flash"
            EQUIPMENT_3="Props are set up in a bin on the floor inside the
                      inflatable or on a provided table outside the inflatable"
            EQUIPMENT_4="Touch screen kiosk"
            EQUIPMENT_5="High speed printer"
            REQUIREMENTS="REQUIREMENTS"
            REQUIREMENTS_text="Standard power/power outlet"
          />
          <PropCustomeModal
            CustomePropsList={CustomePropsList}
            propSelecteCLick={propSelecteCLick}
          />
        </>
      )}
    </>
  );
};

export default PhotoBooth;

export const Tabs = ({
  tabs,
  backdropClick,
  nextUpgradeBackdropSequin,
  previousUpgradeBackdropSequin,
  previousUpgradeBackdropStretch,
  nextUpgradeBackdropStretch,
  upgradBackdropTitle,
  activeTabBackdropSection,
  handleTabClickBackDropIndex,
}) => {
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  var settingsGreen = {
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <ul className="d-flex ml-0 pl-0 add_remove_list">
        {tabs &&
          tabs.map((tab, index) => (
            <li
              style={{ cursor: "pointer" }}
              key={index}
              onClick={() => handleTabClickBackDropIndex(tab, index)}
              className={`title_section li_style ${
                index === activeTabBackdropSection ? "active" : ""
              }`}
            >
              <span className="title_BoothType-section">{tab.title}</span>
            </li>
          ))}
      </ul>

      <div className="Slider-Wrapper">
        <Slider {...settings}>
          {activeTabBackdropSection === 0 &&
            tabs[activeTabBackdropSection].content.Sequin_Add_Backdrop &&
            tabs[activeTabBackdropSection].content.Sequin_Add_Backdrop.map(
              (item, index) => {
                return (
                  <div className="OpenAirRadio_Section">
                    <div className="label_title">
                      <label
                        className="labelCircle"
                        onClick={() => backdropClick(item, index)}
                      >
                        <input type="radio" name="test" />
                        <img src={item.circleImage} alt="img" />
                      </label>
                      <p>
                        {index === upgradBackdropTitle?.index &&
                          upgradBackdropTitle?.title}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
        </Slider>
      </div>

      <div className="Slider-Wrapper">
        <Slider {...settings}>
          {activeTabBackdropSection === 1 &&
            tabs[activeTabBackdropSection].content.stretch_Add_Backdrop &&
            tabs[activeTabBackdropSection].content.stretch_Add_Backdrop.map(
              (item, index) => {
                return (
                  <div className="OpenAirRadio_Section">
                    <div className="label_title">
                      <label
                        className="labelCircle"
                        onClick={() => backdropClick(item, index)}
                      >
                        <input type="radio" name="test" />
                        <img src={item.circleImage} alt="img" />
                      </label>
                      <p>
                        {index === upgradBackdropTitle?.index &&
                          upgradBackdropTitle?.title}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
        </Slider>
      </div>
      <div className="Slider-Wrapper">
        <Slider {...settingsGreen}>
          {activeTabBackdropSection === 2 &&
            tabs[activeTabBackdropSection].content.Green_Add_Backdrop &&
            tabs[activeTabBackdropSection].content.Green_Add_Backdrop.map(
              (item, index) => {
                return (
                  <div className="OpenAirRadio_Section">
                    <div className="label_title">
                      <label
                        className="labelCircle"
                        onClick={() => backdropClick(item, index)}
                      >
                        <input type="radio" name="test" />
                        <img src={item.circleImage} alt="img" />
                      </label>
                      <p>
                        {index === upgradBackdropTitle?.index &&
                          upgradBackdropTitle?.title}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
        </Slider>
      </div>
    </div>
  );
};

export const TabsTwoNdSix = ({
  tabs,
  templateClick,
  handleTabClick,
  activeTab,
  selectBackDrop,
  selectSubjectImage,
  nextTemplate,
  previousTemplate,
  nextbackdrop,
  previousBackdrop,
  nextBacdropFourBySix,
  previousBackdropFourBySix,
  previousTempFourBySix,
  nextTempFourBySix,
  printIndexTab,
  TemPravAutoChange_2X6,
  TempNextAutoChange_2X6,
  TempNextAutoChange,
  TemPravAutoChange,
  twoBy,
}) => {
  return (
    <div>
      <ul className="d-flex ml-0 pl-0 add_remove_list two_four_section">
        {tabs.map((tab, index) => (
          <li
            style={{ cursor: "pointer" }}
            key={index}
            onClick={() => handleTabClick(index, tab)}
            className={`title_section li_style ${
              index === printIndexTab ? "active" : ""
            }`}
          >
            <span className="title_BoothType-section">{tab.title}</span>
          </li>
        ))}
      </ul>
      <div>
        <div>
          {tabs[activeTab].content.data.map((item, index) => {
            return (
              <>
                <div className="d-flex align-items-start  backdrop_4x6 ">
                  {item.OpenAirTemplateSlider && (
                    <>
                      {/* <div className="PrintSize_ButtonSection">
                        <button
                          className="ChangeDesign_button"
                          onClick={TemPravAutoChange_2X6}
                        >
                          Previous
                        </button>
                        <button
                          className="ChangeDesign_button"
                          onClick={TempNextAutoChange_2X6}
                        >
                          Next
                        </button>
                      </div> */}

                      <div className="PrintSize_ButtonSection">
                        <p onClick={TemPravAutoChange_2X6}>
                          <GrPrevious className="pagination_prevAnd_Next" />
                        </p>

                        <p className="title_Occasion width_changeDesign">
                          Change Design
                        </p>

                        <p onClick={TempNextAutoChange_2X6}>
                          <GrNext className="pagination_prevAnd_Next" />
                        </p>
                      </div>

                      <div className="templateDisplayFlex_Section">
                        <p onClick={previousTemplate}>
                          <GrPrevious className="pagination_prevAnd_Next" />
                        </p>
                        {item.OpenAirTemplateSlider.map((template, index) => {
                          return (
                            <>
                              <div>
                                <div className="OpenAirRadio_Section">
                                  <div className="label_title">
                                    <label
                                      className="labelCircle"
                                      onClick={() =>
                                        templateClick(template.img, index)
                                      }
                                      id={`templateAotoChangeTwoBySix_${index}`}
                                    >
                                      <input type="radio" name="test" />
                                      <img src={template.img} alt="img" />
                                    </label>
                                    <p className="tabTitle">{template.title}</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                        <p onClick={nextTemplate}>
                          <GrNext className="pagination_prevAnd_Next" />
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <div className="d-flex align-items-start ">
                  {item.OpenAirTempFourBySixSlider && (
                    <>
                      <div className="PrintSize_ButtonSection">
                        <p onClick={TemPravAutoChange}>
                          <GrPrevious className="pagination_prevAnd_Next" />
                        </p>

                        <p className="title_Occasion width_changeDesign">
                          Change Design
                        </p>

                        <p onClick={TempNextAutoChange}>
                          <GrNext className="pagination_prevAnd_Next" />
                        </p>
                      </div>
                      <div className="templateDisplayFlex_Section">
                        <p onClick={previousTempFourBySix}>
                          <GrPrevious className="pagination_prevAnd_Next" />
                        </p>
                        {item.OpenAirTempFourBySixSlider.map(
                          (template, index) => {
                            console.log(template, "template");
                            return (
                              <>
                                <div>
                                  <div className="OpenAirRadio_Section">
                                    <div className="label_title">
                                      <label
                                        className="labelCircle"
                                        id={`templateAotoChange_${index}`}
                                        onClick={() =>
                                          templateClick(template.img, index)
                                        }
                                      >
                                        <input type="radio" name="test" />
                                        <img src={template.img} alt="img" />
                                      </label>
                                      <p className="tabTitle">
                                        {template.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                        <p onClick={nextTempFourBySix}>
                          <GrNext className="pagination_prevAnd_Next" />
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// export const TabOpenAirSectionBackDrop = ({ tabs }) => {
//   const [activeTab, setActiveTab] = useState(0);

//   function handleTabClick(index) {
//     setActiveTab(index);
//   }
//   return (
//     <div>
//       <ul className=" d-flex ml-0 pl-0">
//         {tabs.map((tab, index) => (
//           <li
//             style={{ cursor: "pointer" }}
//             key={index}
//             onClick={() => handleTabClick(index)}
//             className={`title_section li_style ${
//               index === activeTab ? "active" : ""
//             }`}
//           >
//             <span className="title_BoothType-section">{tab.title}</span>
//           </li>
//         ))}
//       </ul>
//       <div className="d-flex align-items-start openairMenu_Type">
//         {tabs[activeTab].content.data.map((item, index) => {
//           return (
//             <div className="OpenAirRadio_Section">
//               <div className="label_title">
//                 <label className="labelCircle">
//                   <input type="radio" name="test" />
//                   <img src={item.img} alt="img" />
//                 </label>
//                 <p>{item.title}</p>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

export const TabsLedPrintSize = ({ tabs, templateClick }) => {
  const [activeTab, setActiveTab] = useState(0);

  function handleTabClick(index) {
    setActiveTab(index);
  }
  return (
    <div>
      <ul className="d-flex ml-0 pl-0">
        {tabs.map((tab, index) => (
          <li
            style={{ cursor: "pointer" }}
            key={index}
            onClick={() => handleTabClick(index)}
            className={`title_section li_style ${
              index === activeTab ? "active" : ""
            }`}
          >
            <span className="title_BoothType-section">{tab.title}</span>
          </li>
        ))}
      </ul>
      <div>
        <div>
          {tabs[activeTab].content.data.map((item, index) => {
            return (
              <>
                <p
                  className="title_BoothType-section img-round-title"
                  style={{ fontWeight: "bold" }}
                >
                  {item.title}
                </p>
                <div className="d-flex align-items-start">
                  {item.Subjects_Image && (
                    <>
                      <>
                        {item.Subjects_Image.map((subject, index) => {
                          return (
                            <>
                              <div className="d-flex align-items-start">
                                <div className="OpenAirRadio_Section">
                                  <div className="label_title">
                                    <label className="labelCircle">
                                      <input type="radio" name="test" />
                                      <img src={subject.img} alt="img" />
                                    </label>
                                    <p className="tabTitle">{subject.title}</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    </>
                  )}
                </div>

                <div className="d-flex align-items-start  backdrop_4x6">
                  {item.Backdrop_list && (
                    <>
                      {item.Backdrop_list.map((backdrop, index) => {
                        return (
                          <>
                            <div>
                              <div className="OpenAirRadio_Section">
                                <div className="label_title">
                                  <label className="labelCircle">
                                    <input type="radio" name="test" />
                                    <img src={backdrop.img} alt="img" />
                                  </label>
                                  <p className="tabTitle">{backdrop.title}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}
                </div>

                <div className="d-flex align-items-start  backdrop_4x6">
                  {item.Template_images && (
                    <>
                      {item.Template_images.map((template, index) => {
                        return (
                          <>
                            <div>
                              <div className="OpenAirRadio_Section">
                                <div className="label_title">
                                  <label
                                    className="labelCircle"
                                    onClick={() => templateClick(template.img)}
                                  >
                                    <input type="radio" name="test" />
                                    <img src={template.img} alt="img" />
                                  </label>
                                  <p className="tabTitle">{template.title}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const PropCustomeModal = ({ CustomePropsList, propSelecteCLick }) => {
  return (
    <div class="modal-dialog modal-dialog-centered ">
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="row pop_modal_data">
                {CustomePropsList.map((item, index) => {
                  return (
                    <div
                      onClick={() => propSelecteCLick(item)}
                      className="col-md-3 my-3 p-2 customer_details"
                    >
                      <img className="w-100" src={item.img} alt={item.title} />
                      <p className="text-center">{item.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Modal = ({
  title,
  FEATURES,
  Backdrops,
  backdrop_text,
  Prints,
  Prints_text,
  Filters,
  Filters_text,
  Props,
  Props_text,
  Instant_mobile_sharing,
  Instant_mobile_sharing_text,
  Picture_Delivery,
  Picture_Delivery_text,
  DETAILS,
  Setup_Size,
  Setup_Size_text,
  Capacity,
  Capacity_text,
  Protection,
  Protection_text,
  Delivery,
  Delivery_text,
  EQUIPMENT,
  EQUIPMENT_0,
  EQUIPMENT_1,
  EQUIPMENT_2,
  EQUIPMENT_3,
  EQUIPMENT_4,
  EQUIPMENT_5,
  REQUIREMENTS,
  REQUIREMENTS_text,
}) => {
  return (
    <div class="modal-dialog modal-dialog-centered">
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="opem_title">{title}</div>
              <div className="row pop_modal_data">
                <div className="feature col-lg-4 col-md-6 col-12">
                  <p className="modal_title">{FEATURES}</p>
                  <p>
                    <b className="bold_title">{Backdrops}</b>
                    <span className="text">{backdrop_text}</span>
                  </p>
                  <p>
                    <b className="bold_title">{Prints}:</b>
                    <span className="text"> {Prints_text}</span>
                  </p>

                  <p>
                    <b className="bold_title">{Filters}</b>
                    <span className="text"> {Filters_text}</span>
                  </p>

                  <p>
                    <b className="bold_title">{Props}</b>
                    <span className="text">{Props_text}</span>
                  </p>

                  <p>
                    <b className="bold_title">{Instant_mobile_sharing}:</b>
                    <span className="text">{Instant_mobile_sharing_text}</span>
                  </p>

                  <p>
                    <b className="bold_title">{Picture_Delivery}:</b>
                    <span className="text">{Picture_Delivery_text}</span>
                  </p>
                </div>

                <div className="detail col-lg-4 col-md-6 col-12">
                  <p className="modal_title">{DETAILS}</p>
                  <p>
                    <b className="bold_title">{Setup_Size} :</b>
                    <span className="text">{Setup_Size_text}</span>
                  </p>
                  <p>
                    <b className="bold_title">{Capacity}:</b>
                    <span className="text">{Capacity_text}</span>
                  </p>
                  <p>
                    <b className="bold_title">{Protection}:</b>
                    <span className="text">{Protection_text}</span>
                  </p>
                  <p>
                    <b className="bold_title">{Delivery}:</b>
                    <span className="text">{Delivery_text}</span>
                  </p>
                </div>

                <div className="equipment col-lg-4 col-md-6 col-12">
                  <p className="modal_title">{EQUIPMENT}</p>
                  {/* <span className="text">{EQUIPMENT_0}</span>
                  <br /> */}
                  <li>
                    <span className="text">{EQUIPMENT_1}</span>
                  </li>
                  <li>
                    <span className="text">{EQUIPMENT_2}</span>
                  </li>
                  <li>
                    <span className="text">{EQUIPMENT_3}</span>
                  </li>
                  <li>
                    <span className="text">{EQUIPMENT_4}</span>
                  </li>
                  <li>
                    <span className="text">{EQUIPMENT_5}</span>
                  </li>

                  <div className="requirment mt-3">
                    <span className="modal_title">{REQUIREMENTS} </span>
                    <li>
                      <span className="text">{REQUIREMENTS}</span>
                    </li>
                    <li>
                      <span className="text">{REQUIREMENTS_text}</span>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
