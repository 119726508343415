import React, { useState, useRef } from "react";
import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import MainContainer from "./MainContainer/MainContainer";
import Users from "./Users/Users";
import Todo from "./Todo/Todo";
import Activity from "./Activity/Activity";
import Contact from "./Contact/Contact";
import EventDetail from "./EventDetail/EventDetail";
// import Communication from "./Chatting/ChatRoom/Login"
import Profile from "./Profile/Profile";
// import RoomList from './Chatting/ChatRoom/RoomList';
// import AddRoom from './Chatting/ChatRoom/AddRoom';
// import ChatRoom from './Chatting/ChatRoom/ChatRoom';
import { Notifications } from "react-push-notification";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./ChatSection/Login";
import ChatRoom from "./ChatSection/ChatRoom";
import Chat from "./Chat/Chat";
import Sc from "./Chat/sc";

import Errors from "./MainContainer/Errros";
import Logout from "./MainContainer/Logout";

function App() {
  const [toggleSearch, setToggleSearch] = useState(false);

  return (
    <div>
      <BrowserRouter>
        <Notifications />
        <Header onChange={() => setToggleSearch(!toggleSearch)} />
        <Routes>
          <Route
            path="/"
            element={<MainContainer toggleSearch={toggleSearch} />}
          />
          <Route path="/users/login/:user_id" element={<Users />} />
          <Route path="logout" element={<Logout />} />
          <Route path="/Todo" element={<Todo />} />
          <Route path="/Error" element={<Errors />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/Activity" element={<Activity />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/event" element={<EventDetail />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
