import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useLocation } from "react-router-dom";
import "../App.css";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import Eventslist from "../Eventslist/Eventslist";
// import Mainplan from "../Planner/Mainplan";
import Docs from "../Doccs/Docs";
import Iternary from "../Iternary/Iternary";
import Editor from "../Editor/Editor";
import Activities from "../Activity/Activties";
import ChatRoom from "../ChatSection/ChatRoom";
import Demos from "../Editor/Demo";
import Template from "../Editor/Template";
import { AiOutlineContacts, AiOutlineCalendar } from "react-icons/ai";
import { BiPhotoAlbum } from "react-icons/bi";

import { GrContactInfo } from "react-icons/gr";

import { RiGalleryFill } from "react-icons/ri";
import { DiMagento } from "react-icons/di";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdOutlineVolunteerActivism, MdEditLocationAlt } from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FiDownloadCloud } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";

import { BallTriangle } from "react-loader-spinner";

import Activties from "../Activity/Activties";

import * as $ from "jquery";
import Demo from "../Editor/Demo";
import Plannerlatest from "../PlannerNew/Plannerlatest";
import LocationContact from "../LocationContact/LocationContact";
// import Planner from "../Planner/Planner";

import PhotoBooth from "../PhotoBooth/PhotoBooth";

const Tabs = [
  {
    id: "eventinfo-tab",
    href: "eventinfo",
    controls: "eventinfo",
    selected: "true",
    active: true,
    name: "Event Info",
    logo: () => <AiOutlineContacts />,
  },

  {
    id: "location-tab",
    href: "location",
    controls: "location",
    selected: "true",
    active: false,
    name: "LOCATIONS & CONTACTS",
    logo: () => <MdEditLocationAlt />,
  },
  {
    id: "planner-tab",
    href: "planner",
    controls: "planner",
    selected: "true",
    active: false,
    name: "PLANNER",
    logo: () => <GrContactInfo />,
  },

  {
    id: "photoBooth-tab",
    href: "photoBooth",
    controls: "photoBooth",
    selected: "true",
    active: false,
    name: "Photo Booth",
    logo: () => <BiPhotoAlbum />,
  },

  {
    id: "docs-tab",
    href: "docs",
    controls: "docs",
    selected: "true",
    active: false,
    name: "UPLOAD FILES",
    logo: () => <RiGalleryFill />,
  },
  {
    id: "itinerary-tab",
    href: "itinerary",
    controls: "itinerary",
    selected: "true",
    active: false,
    name: "THEME LAYOUT",
    logo: () => <FiDownloadCloud />,
  },

  {
    id: "contact-tab",
    href: "contact",
    controls: "contact",
    selected: "true",
    active: false,
    name: "ITINERARY",
    logo: () => <SiMicrosoftexcel />,
  },

  {
    id: "ac-tab",
    href: "ac",
    controls: "ac",
    selected: "true",
    active: false,
    name: "ACTIVITY",
    logo: () => <MdOutlineVolunteerActivism />,
  },
];
let onLoaded = new Set();

function EventDetails() {
  const userdetail = useSelector((state) => state?.userDetail?.data);
  const [fetchresults, setFecthresults] = useState(false);

  //participent Start section

  const token = useSelector((state) => state?.userDetail?.userToken);
  const username = useSelector((state) => state?.userDetail?.data?.username);
  const [party, setParty] = useState([]);
  const [search, setSearch] = useState("");
  const [user_id, setUser_id] = useState();
  const [selectAllOn, setselectAllOn] = useState(true);
  const location = useLocation();
  // const [elRefs, setElRefs] = React.useState([]);
  const [newValue, setnewValue] = useState([]);
  const [checked, setChecked] = useState();
  const [checkedAll, setCheckedAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOwner, setIsowner] = useState(false);
  const [isOwnerSelected, setIsOwnerSelected] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isEvent, setIsEvent] = useState();
  const [isOccation, setIsOccation] = useState();

  let arrLength = party.length;
  let eventId = location.state?.id;

  const navigate = useNavigate();
  const errorHandler = (err) => {
    navigate("/Error");
  };

  const addParticipent = (e) => {
    setIsAdd(true);
    const selected = Object.keys(checked).filter((e) => checked[e]);
    const selectedUsers = party.filter((user) =>
      selected.includes(`${user.id}`)
    );

    const data = selectedUsers.map((e) => ({
      user_id: e.id,
      event_id: eventId,
    }));

    fetch(`${process.env.REACT_APP_BASE_URL}addParticipant`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        const temp = selectedUsers.filter((item) => item.is_owner == "0");
        toast.success("Updated Successfully");
        setIsOwnerSelected(temp);
        window.location.reload();
      })
      .catch((err) => {
        alert("error");
        errorHandler(err);
      });
    setIsAdd(false);
  };

  const eventDetail = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}eventdetails`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res, "rs");
        var event_type = res?.data.EventInfo.event_type;
        var occation = res?.data.EventInfo.occasion;
        setIsEvent(event_type);
        setIsOccation(occation);
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const geteventlist = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}eventParticipant`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event_id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        var parties = res?.data;
        setParty(parties);
        setUser_id(parties);
        setIsOwnerSelected(parties.filter((item) => item.is_owner == "0"));
        arrLength = party.length;
        const initialState = parties.reduce(
          (o, key) => ({ ...o, [key.id]: key.is_owner == 0 ? true : false }),
          {}
        );

        parties.map((e) => {
          var myself = localStorage.getItem("Owner");
          setIsowner(username != myself);
        });
        setChecked(initialState);
        setLoading(false);
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  useEffect(() => {
    geteventlist();
    eventDetail();
  }, []);

  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
    setCheckedAll(value);
  };

  const toggleCheck = (inputName) => {
    setIsAdd(true);
    setChecked((prevState) => {
      const selectedvalue = { ...prevState };
      selectedvalue[inputName] = !prevState[inputName];
      var checkLength = document.getElementsByClassName("checking-box");

      for (let a = 0; a < checkLength.length; a++) {
        if (checkLength[a].checked) {
          setCheckedAll(true);
        } else {
          setCheckedAll(false);
          break;
        }
      }

      setIsAdd(true);
      return selectedvalue;
    });
    setIsAdd(false);
  };

  const onModelClose = (inputName) => {
    setChecked((prevState) => {
      const selectedvalue = { ...prevState };
      selectedvalue[inputName] = !prevState[inputName];
      return selectedvalue;
    });
  };

  function changeHandler(e) {
    setSearch(e.target.value);
  }

  useEffect(() => {
    if (search.length == 0) {
      setselectAllOn(true);
    } else {
      setselectAllOn(false);
    }
  }, [search]);

  const filteredContacts =
    search.length === 0
      ? party
      : party.filter((data) =>
          data.username.toLowerCase().includes(search.toLowerCase())
        );

  //end particepent

  const history = useNavigate();
  const [creds, setCreds] = useState({
    nickname: userdetail.username,
    id: userdetail?.id,
  });

  const onChange = (e) => {
    e.persist();
    setCreds({ ...creds, [e.target.name]: e.target.value });
  };

  const defaultActiveTab = Tabs.find((e) => e.active);
  const [activeTab, setActiveTab] = useState(defaultActiveTab.id);

  const handleTabChange = () => {
    setTimeout(() => {
      const currentTab = document.querySelector("#myTab a.active");
      setActiveTab(currentTab.id);
      onLoaded.add(currentTab.id);
    }, 0);
  };

  const hasLoaded = (id) => {
    return onLoaded.has(id);
  };

  $(function () {
    $('a[data-toggle="tab"]').on("shown.tab", function (e) {
      localStorage.setItem("lastTab", $(this).attr("href"));
    });
    var lastTab = localStorage.getItem("lastTab");

    if (lastTab) {
      $('[href="' + lastTab + '"]').tab("show");
    }
  });
  // let arr = [];

  // party.map((item) => {
  //   if (item.is_owner == "0") {
  //     arr.push(item);
  //   }
  // });

  const image = {
    border: "1px solid #b402ba",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    textAlign: "Center",
    margin: "5px",
  };

  const addbtn = {
    border: "1px solid #b402ba",
    borderRedius: "30px",
  };
  const ImageWrap = {
    display: "flex",
    justifyContent: "end",
    textAlign: "center",
    marginRight: "10px",
    flexWrap: "wrap",
  };

  const imageswidhth = {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    borderRadius: "50%",
  };

  return (
    <div>
      <div className="Chat-login-main  d-none">
        <section>
          <h3>Welcome</h3>
          <form className="form g-3 needs-validation form-wrapper">
            <div className="input_container form-col">
              <label className="form-label" htmlFor="nickname">
                Name
              </label>
              <input
                className="form-control"
                type="text"
                name="nickname"
                placeholder="Enter Your Nickname"
                value={creds.nickname}
                onChange={onChange}
              />
            </div>

            <div className="btn_container">
              <button className="btn btn-primary" type="submit">
                Register<div className="Toastify"></div>
              </button>
            </div>
          </form>
        </section>
      </div>

      <section className="Main-all-project Events">
        <div className="product-inner-area">
          <div className="container">
            {/* Participent Start Section */}
            <div>
              <div className="MainPlannerImgAdd" style={{ marginTop: "47px" }}>
                <div style={ImageWrap}>
                  <button
                    type="button"
                    className="btn"
                    style={addbtn}
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    Set up people
                  </button>
                  {isOwnerSelected &&
                    isOwnerSelected.map((ele, index) => {
                      return (
                        <div key={index}>
                          <div style={image}>
                            <div htmlFor="Organization" className="form-label">
                              <div data-tip={ele.name} data-for="test">
                                <img
                                  style={imageswidhth}
                                  src={`${process.env.REACT_APP_BASE_URL}${ele.image}`}
                                  alt="img"
                                />
                                <ReactTooltip type="warning" id="test" />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="participent-modalbox">
                  <div
                    className="modal fade "
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-body">
                          <div>
                            {loading && (
                              <div className="wholeScreen">
                                <div className="blur_design"> </div>
                                <div className="spinner_design">
                                  <BallTriangle
                                    heigth="100"
                                    width="100"
                                    color="white"
                                    ariaLabel="loading-indicator"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="Serachbar">
                              {search && search.length ? (
                                <input
                                  className="head-inpu"
                                  type="search"
                                  name="search"
                                  value={search}
                                  onChange={(e) => changeHandler(e)}
                                  id="search"
                                  placeholder="Search..."
                                />
                              ) : (
                                <>
                                  <input
                                    className="head-inpu"
                                    type="search"
                                    name="search"
                                    value={search}
                                    onChange={(e) => changeHandler(e)}
                                    id="search"
                                    placeholder="Search..."
                                  />
                                  <button className="search_btn" href="#">
                                    <img
                                      className="searchbar-icon"
                                      src="img/search.png"
                                      alt="icon"
                                    />
                                  </button>
                                </>
                              )}
                            </div>

                            <ul className="row">
                              <li
                                className={`col-sm-6  ${
                                  selectAllOn ? "" : "d-none"
                                } list_of_participant checkbox-set`}
                              >
                                <input
                                  type="checkbox"
                                  onChange={(event) =>
                                    selectAll(event.target.checked)
                                  }
                                  checked={checkedAll}
                                />

                                <label
                                  style={{ fontSize: 18, fontWeight: 700 }}
                                >
                                  Select All
                                </label>
                              </li>
                              {filteredContacts.length == 0 ? (
                                <div className="no-data">
                                  <p>No Users found</p>
                                </div>
                              ) : (
                                <div>
                                  {filteredContacts.map((ele, index) => {
                                    return (
                                      <>
                                        <li
                                          key={index}
                                          className="col-md-6 list_of_participant checkbox-set"
                                        >
                                          <input
                                            className="form-check-input checking-box"
                                            type="checkbox"
                                            name="languages"
                                            id="flexCheckDefault"
                                            onChange={(e) =>
                                              toggleCheck(ele.id)
                                            }
                                            checked={checked[ele.id] || false}
                                          />
                                          <label>{ele.username}</label>
                                        </li>
                                      </>
                                    );
                                  })}
                                </div>
                              )}
                            </ul>
                            <div className="addbutton participentBtnDiv">
                              {isAdd ? (
                                <button
                                  className="btn btn-md btn-primary"
                                  type="button"
                                  // disabled={isOwner}
                                  onClick={(e) => {
                                    addParticipent(e);
                                  }}
                                >
                                  {/* <ToastContainer
                                  className="planner"
                                  autoClose={1000}
                                /> */}
                                  Add
                                </button>
                              ) : null}
                              <button
                                type="button"
                                className="btn btn-primary ml-2"
                                data-dismiss="modal"
                                onClick={(e) => {
                                  onModelClose(e);
                                }}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Participent End Section */}
            {/* Participent End Section */}
            <ul
              className="nav nav-tabs justify-content-between"
              id="myTab"
              role="tablist"
            >
              {Tabs.map((tab, index) =>
                (isEvent === "In Person" && tab.name == "PLANNER") ||
                (isEvent === "In Person" &&
                  tab.name == "LOCATIONS & CONTACTS") ||
                (tab.name !== "PLANNER" &&
                  tab.name !== "LOCATIONS & CONTACTS") ? (
                  <>
                    <li className="nav-item" key={index}>
                      <a
                        onClick={handleTabChange}
                        className={`nav-link ${tab.active ? "active" : ""}`}
                        id={tab.id}
                        data-toggle="tab"
                        href={`#${tab.href}`}
                        // href=""

                        role="tab"
                        aria-controls={tab.controls}
                        aria-selected={tab.selected}
                      >
                        <span className="d-none d-md-block">{tab.name}</span>
                        <span className="d-block d-md-none">{tab.logo()}</span>
                      </a>
                    </li>
                  </>
                ) : (
                  <></>
                )
              )}
            </ul>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="eventinfo"
                role="tabpanel"
                aria-labelledby="eventinfo-tab"
              >
                <div className="product-detail-all">
                  <div className="product-inner-area">
                    {(activeTab === "eventinfo-tab" ||
                      hasLoaded("eventinfo-tab")) && <Eventslist />}
                  </div>
                </div>
              </div>

              {/* planner Section */}

              <div
                className="tab-pane fade"
                id="planner"
                role="tabpanel"
                aria-labelledby="planner-tab"
              >
                <div className="product-detail-all">
                  <div className="product-inner-area">
                    {(activeTab === "planner-tab" ||
                      hasLoaded("planner-tab")) && <Plannerlatest />}
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="location"
                role="tabpanel"
                aria-labelledby="location-tab"
              >
                <div className="product-detail-all">
                  <div className="product-inner-area">
                    {(activeTab === "location-tab" ||
                      hasLoaded("location-tab")) && <LocationContact />}
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="photoBooth"
                role="tabpanel"
                aria-labelledby="photoBooth-tab"
              >
                <div className="product-detail-all">
                  <div className="product-inner-area">
                    {(activeTab === "photoBooth-tab" ||
                      hasLoaded("photoBooth-tab")) && <PhotoBooth />}
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="docs"
                role="tabpanel"
                aria-labelledby="docs-tab"
              >
                <div className="product-detail-all">
                  <div className="product-inner-area">
                    {(activeTab === "docs-tab" || hasLoaded("docs-tab")) && (
                      <Docs />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="itinerary"
                role="tabpanel"
                aria-labelledby="itinerary-tab"
              >
                <div className="product-detail-all">
                  <div className="product-inner-area">
                    <div className="tui-image-editor">
                      <div>
                        {(activeTab === "itinerary-tab" ||
                          hasLoaded("itinerary-tab")) && <Template />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div className="product-detail-all">
                  <div className="product-inner-area">
                    {(activeTab === "contact-tab" ||
                      hasLoaded("contact-tab")) && <Iternary />}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="ac"
                role="tabpanel"
                aria-labelledby="ac-tab"
              >
                <div className="product-detail-all">
                  <div className="product-inner-area">
                    {activeTab === "ac-tab" && (
                      <Activities
                        onClick={() => setFecthresults(!fetchresults)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="chat"
                role="tabpanel"
                aria-labelledby="chat-tab"
              >
                <div className="product-detail-all">
                  <div className="product-inner-area">
                    {(activeTab === "chat-tab" || hasLoaded("chat-tab")) && (
                      <ChatRoom />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EventDetails;
