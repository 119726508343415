import { actionTypes } from "./types";

export const userReducer = (state = { isLogin: false }, action) => {
  switch (action.type) {
    case actionTypes.IsLoggedIn:
      return { ...state, isLogin: true, userDetail: action.payload };
    case actionTypes.IsLoggedOut:
      return { ...state, isLogin: false, userDetail: action.payload };
    case actionTypes.UserUpdate:
      return {
        ...state,
        userupdate: action.payload,
      };
    default: {
      return state;
    }
  }
};
