import React from "react";
import "./Todo.css";

function Todo(props) {
  return (
    <div className="demo">
      <h1>From A Small House Party To A Grand Event… {props.name}</h1>
      <p>
        Virtual DJ services and event live streaming services… Using our amazing
        team of experienced audio engineers, professional videographers, and
        virtual dj services, we put togehter so many online options for you,
        including live event streaming. We use almost all well known patforms
        like Zoom, Instagram, Facebook, Twitch, Youtube, and many more.
      </p>
      <br />
      <h1>About our in person DJ services…</h1>
      <p>
        While offering the right mix of music and entertainment through tailored
        DJ Services and MC Services for all cultures to drive any occasion
        (holidays, corporate events, weddings, school events, Sweet 16s, Bar
        Mitzvahs, Bat Mitzvahs, and Birthday Parties), we also provide elegant
        photography services, as well as a personally guided photo booth where
        we handcraft our custom props. We pride ourselves on being musically
        educated and culturally diverse within our own team. As a result, this
        gives us the edge to psychology understand you and your guests musically
        almost as if we grew up with you. When we first started, we only offered
        our DJ services which we received amazing feedback one event after
        another so we took that same energy and invested it in some of our other
        great services. All in all, whatever we touch, we aim for nothing but
        the best!
      </p>
      <h1>Photo booths…</h1>
      <p>
        Our photo booth kiosks come with DSLR cameras and external an external
        flash setup by professional photographers to get perfect studio quality
        photos. The cool part about our booth experience is our large selection
        of custom props made by our talented art team. We have over 80 you can
        choose from to build out your own prop bin. With our Photoshop skills,
        we’ve designed many print templates you can choose from and we’re
        capable of designing one from scratch just for your event.
      </p>
    </div>
  );
}

export default Todo;
