import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BallTriangle } from "react-loader-spinner";
import { ReactSortable } from "react-sortablejs";
import "../App.css";
import $ from "jquery";

function Viddoc() {
  const token = useSelector((state) => state?.userDetail?.userToken);
  const [video, setvideo] = useState([]);
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  let eventId = location.state.id;

  // const onDragDropEnds = (oldIndex, newIndex) => {
  //   setvideo((prev) =>{
  //      const data = [...prev];
  //      console.log([data[oldIndex],data[newIndex]] = [data[newIndex],data[oldIndex]]);
  //      {[data[oldIndex],data[newIndex]] = [data[newIndex],data[oldIndex]];
  //       console.log(data, "d");
  //       return [...data]}
  //   });
  // };

  const accpetedDocTypes = [
    "video/mp4",
    " video/MPEG-4",
    "video/mkv",
    "video/webm",
  ];
  document.addEventListener("DOMContentLoaded", (e) => {
    var dragSrcEl = null;

    function handleDragStart(e) {
      this.style.opacity = "1";

      dragSrcEl = this;

      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/html", this.innerHTML);
    }

    function handleDragOver(e) {
      // console.log("call over");
      // document.getElementsByClassName('select_drop')[0].style.display = 'block';
      if (e.preventDefault) {
        e.preventDefault();
      }

      e.dataTransfer.dropEffect = "move";

      return false;
    }

    function handleDragEnter(e) {
      // console.log("call enter");
      this.classList.add("over");
    }

    function handleDragLeave(e) {
      // console.log("call leave");
      this.classList.remove("over");
    }

    function handleDrop(e) {
      // console.log('====================================');
      // console.log("drop");
      // console.log('====================================');
      if (e.stopPropagation) {
        e.stopPropagation(); // stops the browser from redirecting.
      }

      if (dragSrcEl != this) {
        dragSrcEl.innerHTML = this.innerHTML;
        this.innerHTML = e.dataTransfer.getData("text/html");
      }

      return false;
    }

    function handleDragEnd(e) {
      // console.log(e, "e");

      this.style.opacity = "1";

      items.forEach(function (item) {
        item.classList.remove("over");
      });
      // saveOrder()
      imageHandle(e);
    }

    let items = document.querySelectorAll(".container .item");
    items.forEach(function (item) {
      item.addEventListener("dragstart", handleDragStart, false);
      item.addEventListener("dragenter", handleDragEnter, false);
      item.addEventListener("dragover", handleDragOver, false);
      item.addEventListener("dragleave", handleDragLeave, false);
      item.addEventListener("drop", handleDrop, false);
      item.addEventListener("dragend", handleDragEnd, false);
    });
  });

  const upload_video = (e) => {
    if (document.getElementById("uploadVideoId").files.length == 0) {
      toast.error("Please Select Video");
    } else {
      const files = document.getElementById("uploadVideoId").files;
      const formDataVideo = new FormData();
      for (let i = 0; i < files.length; i++) {
        formDataVideo.append("video", files[i]);
      }
      formDataVideo.append("id", eventId);
      console.log(formDataVideo, "formDataVideo");
      fetch(`${process.env.REACT_APP_BASE_URL}uploadVideos`, {
        method: "post",
        headers: {
          "x-access-token": `${token}`,
        },
        body: formDataVideo,
      })
        .then((response) => response.json())
        .then((res) => {
          videoApi();
          setFile([]);
          document.getElementById("uploadVideoId").value = "";
          toast.success("Video Uploaded Successfully");
        });
    }
  };

  const onVideoChange = (event) => {
    var fileInput = document.getElementById("uploadVideoId");
    var filePath = fileInput.value;

    // var allowedExtensions = /(\.mp4|\.webm)$/i;
    for (var i = 0; i < event.target.files.length; i++) {
      if (!accpetedDocTypes.includes(event.target.files[i].type)) {
        alert("Invalid Video type");
        fileInput.value = "";
        return false;
      } else if (event.target.files[i].size > 10485760.0) {
        alert("File is too big! Maximun 10MB File is allowed");
        fileInput.value = "";
        return false;
      } else {
        // Image preview
        if (event.target.files && event.target.files[i]) {
          setFile(event.target.files[i]);
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
    upload_video();
  };

  const deleteImage = (id) => {
    let recross = "Are you sure you want to Remove";
    if (window.confirm(recross) == true) {
      var remove = {
        id: id,
        event_id: eventId,
      };
      // console.log(remove, "fefef");

      fetch(`${process.env.REACT_APP_BASE_URL}deleteEventDocs`, {
        method: "post",
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(remove),
      })
        .then((response) => response.json())
        .then((res) => console.log(res, "gwevsdcdwfwef"))

        .catch((err) => {
          // console.log("|.................|.....................|");
          console.log(err);
        });

      let ind = video.findIndex((i) => {
        return i.id === id;
      });
      video.splice(ind, 1);
      setvideo([...video]);
      videoApi();
    } else {
    }
  };

  const videoApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}docsListing`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event_id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        var eventDoc = res?.data?.EventDocs?.Video;
        setLoading(false);
        setvideo(eventDoc);
      });
  };

  const imageHandle = (e) => {
    // console.log(e, "e");

    var list;
    const ids = video.map((ele) => ele.id);

    const data = {
      doc_id: ids,
      event_id: eventId,
      type: "video",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}sequence`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        videoApi();
        toast.success("Video Arranged Successfully");
      });
  };

  useEffect(() => {
    videoApi();
  }, []);

  var videos = document.querySelectorAll(".dragHandle video");
  for (var i = 0; i < videos.length; i++)
    videos[i].addEventListener(
      "play",
      function () {
        pauseAll(this);
      },
      true
    );

  function pauseAll(elem) {
    for (var i = 0; i < videos.length; i++) {
      //Is this the one we want to play?
      if (videos[i] == elem) continue;
      //Have we already played it && is it already paused?
      if (videos[i].played.length > 0 && !videos[i].paused) {
        // Then pause it now
        videos[i].pause();
      }
    }
  }

  return (
    <div className="file-upload-inner">
      {/* {loading && (
        <div className="wholeScreen">
          <div className="blur_design"> </div>
          <div className="spinner_design">
            <BallTriangle
              heigth="100"
              width="100"
              color="white"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
      )} */}
      <div className="file-upload">
        <div className="tab-pane p-3 active" id="image" role="tabpanel">
          <h1>Add Video</h1>
          <form
            id="imageform"
            className="dropzone"
            encType="multipart/form-data"
          >
            <div className="input-group">
              <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
              <div className="upload_image_input">
                <label htmlFor="files">Select files</label>
                <input
                  id="uploadVideoId"
                  type="file"
                  multiple
                  accept={accpetedDocTypes}
                  onChange={(e) => {
                    onVideoChange(e);
                  }}
                  name="uploadVideoId"
                />
                <ToastContainer className="planner" autoClose={10} />
                <span className="error">
                  <p id="image_error"></p>
                </span>
              </div>
            </div>
          </form>
          {/* <div className="text-center mt-3 row-btn">
            <a
              href="javascript:void(0)"
              className="btn btn-primary "
              type="button"
              onClick={(e) => {
                upload_video(e);
              }}
            >
             
              Upload Video
            </a>
          </div> */}
          <div></div>
        </div>
        {/* <div className="text-center">
          {" "}
          Accepted Files = .mp4 | .MPEG-4 | .mkv | .webm
        </div> */}
      </div>

      <div className="gallery_box doc_image grid-items container row ml-0 mr-0">
        <ReactSortable
          style={{ display: "flex", flexWrap: "wrap" }}
          list={video}
          setList={(newlist) => {
            setvideo(newlist);
            // console.log('newlist',video);
          }}
          ghostClass="dropArea"
          handle=".dragHandle"
          filter="ignoreDrag"
          preventOnFilter={true}
          // onEnd={({ oldIndex, newIndex }) => onDragDropEnds(oldIndex, newIndex)}
        >
          {video.map((ele, index) => (
            <div
              className="gallery_video dragHandle item col-md-3"
              key={ele?.id}

              // onDragEnd={(e) => {
              //   imageHandle(e);
              // }}
            >
              <div className="item" draggable onDragEnd={() => imageHandle()}>
                <a
                  href={`${process.env.REACT_APP_BASE_URL}${ele?.event_docs_path}`}
                  target=" "
                  style={{ cursor: "pointer" }}
                >
                  <video
                    // draggable
                    // onDragEnd={() => imageHandle()}
                    style={{ width: "320px", height: "200px" }}
                    // allowFullScreen={true}
                    // webkitallowFullscreen= {true}
                    // mozallowfullscreen="true"
                    // autobuffer="true" poster="true" controls="true" type="audio_file_type" muted="tue"
                    src={`${process.env.REACT_APP_BASE_URL}${ele?.event_docs_path}`}
                    className="dragHandle"
                    alt="profile"
                    // controls
                  ></video>
                </a>
              </div>
              <span>
                <a onClick={() => deleteImage(ele?.id, index)}>
                  <img src={`${process.env.REACT_APP_BASE_URL}img/cross.svg`} />
                </a>
              </span>
            </div>
          ))}
        </ReactSortable>
        {/* <button
              type="button"
              className="btn btn-primary waves-effect waves-light doc_btn"
              onClick={(e)=>{
                imageHandle(e);
              }}
          >
                <ToastContainer className="planner" autoClose={1}  />
             Sequence Submit
            </button> */}
      </div>
    </div>
  );
}

export default Viddoc;
