import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BallTriangle } from "react-loader-spinner";
import { ReactSortable } from "react-sortablejs";
import { useNavigate } from "react-router-dom";

import "../App.css";

function Docimage() {
  const token = useSelector((state) => state?.userDetail?.userToken);
  const [image, setimage] = useState([]);
  const [file, setFile] = useState([]);
  const [uploadImage, setupLoadImage] = useState([]);

  const [loading, setLoading] = useState(true);

  const location = useLocation();
  let eventId = location.state.id;

  const navigate = useNavigate();
  const errorHandler = (err) => {
    navigate("/Error");
  };

  const accpetedDocTypes = [
    "image/gif",
    "image/png",
    "image/jpeg",
    "image/jpg",
  ];

  document.addEventListener("DOMContentLoaded", (e) => {
    var dragSrcEl = null;

    function handleDragStart(e) {
      this.style.opacity = "1";

      dragSrcEl = this;

      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/html", this.innerHTML);
    }

    function handleDragOver(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      e.dataTransfer.dropEffect = "move";

      return false;
    }

    function handleDragEnter(e) {
      this.classList.add("over");
    }

    function handleDragLeave(e) {
      this.classList.remove("over");
    }

    function handleDrop(e) {
      if (e.stopPropagation) {
        e.stopPropagation();
      }

      if (dragSrcEl != this) {
        dragSrcEl.innerHTML = this.innerHTML;
        this.innerHTML = e.dataTransfer.getData("text/html");
      }

      return false;
    }

    function handleDragEnd(e) {
      this.style.opacity = "1";

      items.forEach(function (item) {
        item.classList.remove("over");
      });
      imageHandle(e);
    }

    let items = document.querySelectorAll(".container .item");
    items.forEach(function (item) {
      item.addEventListener("dragstart", handleDragStart, false);
      item.addEventListener("dragenter", handleDragEnter, false);
      item.addEventListener("dragover", handleDragOver, false);
      item.addEventListener("dragleave", handleDragLeave, false);
      item.addEventListener("drop", handleDrop, false);
      item.addEventListener("dragend", handleDragEnd, false);
    });
  });

  const upload_image = (e) => {
    if (document.getElementById("uploadImageId").files.length == 0) {
      toast.error("Please select image");
    } else {
      const files = document.getElementById("uploadImageId").files;
      const imageformData = new FormData();
      for (let i = 0; i < files.length; i++) {
        imageformData.append("image", files[i]);
      }
      imageformData.append("id", eventId);

      console.log(imageformData, "imageformData");
      fetch(`${process.env.REACT_APP_BASE_URL}uploadImages`, {
        method: "post",
        headers: {
          "x-access-token": `${token}`,
        },
        body: imageformData,
      })
        .then((response) => response.json())
        .then((res) => {
          imageApi();
          setFile([]);
          document.getElementById("uploadImageId").value = "";
          toast.success("Image Uploaded Successfully");
        })
        .catch((err) => {
          errorHandler(err);
        });
    }
  };

  const onImageChange = (event) => {
    var fileInput = document.getElementById("uploadImageId");
    var filePath = fileInput.value;
    // var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    for (var i = 0; i < event.target.files.length; i++) {
      if (!accpetedDocTypes.includes(event.target.files[i].type)) {
        alert("unaccepted Image type");
        fileInput.value = "";
        return false;
      } else if (event.target.files[i].size > 10485760.0) {
        alert("File is too big! Maximun 10MB File is allowed");
        fileInput.value = "";
        return false;
      } else {
        if (event.target.files) {
          setFile(event.target.files);
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
    upload_image();
  };
  // console.log(file, "file");
  const deleteImage = (id) => {
    let recross = "Are you sure you want to Remove";
    if (window.confirm(recross) == true) {
      var remove = {
        id: id,
        event_id: eventId,
      };
      fetch(`${process.env.REACT_APP_BASE_URL}deleteEventDocs`, {
        method: "post",
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(remove),
      })
        .then((response) => response.json())
        .then((res) => console.log(res, "gwevsdcdwfwef"))

        .catch((err) => {
          // console.log("====================================");
          errorHandler(err);
          // console.log(err,"err");
          // console.log("====================================");
        });

      let ind = image.findIndex((i) => {
        return i.id === id;
      });
      // image.splice(ind, 1);
      console.log(image);
      setimage([...image]);
      // console.log(image, "eedf");

      imageApi();
    } else {
    }

    // if (id === null){
    //   image.splice(index,1)
    //   setimage([...image])
    // }
    // else
    // {
    // setimage([])

    // let len = image.findIndex((i)=>{
    //  if(i.id ===id){
    //    return([...i])
    //  }else{
    //    return i ;
    //  }
    // })
    // len.splice(len ,1 )
    // setimage([...image])

    // const deleteImageFromAlbum = photoID => {
    //   setimage(image.filter(photo => photo.id !== photoID));
    // }
    // deleteImageFromAlbum();
  };

  const imageApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}docsListing`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event_id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        var eventDoc = res?.data?.EventDocs?.Image;
        setLoading(false);
        setimage(eventDoc);
        // console.log(image,"wdwkjbd")
        // setTimeout(() => {setLoading(false);
        // } ,10000)
      })
      .catch((err) => {
        // console.log("====================================");
        errorHandler(err);
        // console.log(err,"err");
        // console.log("====================================");
      });
  };

  //sequence
  const imageHandle = (e) => {
    var list;
    const ids = image.map((ele) => {
      return ele.id;
    });
    const data = {
      doc_id: ids,
      event_id: eventId,
      type: "image",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}sequence`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        imageApi();
        toast.success("Image Arranged Successfully");
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  useEffect(() => {
    imageApi();
  }, []);

  return (
    <div className="file-upload-inner">
      {loading && (
        <div className="wholeScreen">
          <div className="blur_design"> </div>
          <div className="spinner_design">
            <BallTriangle
              heigth="100"
              width="100"
              color="white"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
      )}
      <div className="file-upload">
        <div className="tab-pane p-3 active" id="image" role="tabpanel">
          <h1>Add Image</h1>
          <form
            id="imageform"
            className="dropzone"
            encType="multipart/form-data"
          >
            <div className="input-group">
              <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
              <div className="upload_image_input">
                <label htmlFor="files">Select files</label>
                <input
                  id="uploadImageId"
                  type="file"
                  multiple
                  accept={accpetedDocTypes}
                  onChange={(e) => {
                    onImageChange(e);
                  }}
                />
                <ToastContainer className="planner" autoClose={1} />
                <span className="error">
                  <p id="image_error"></p>
                </span>
              </div>
            </div>
          </form>
          {/* <div className="text-center mt-3 row-btn">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={(e) => {
                upload_image(e);
              }}
            >
              <ToastContainer className="planner" autoClose={1} />
              Upload Image
            </button>
          </div> */}
          <div></div>
        </div>
        {/* <div className="text-center">Accepted Files = .jpg|.jpeg|.png|.gif</div> */}
      </div>
      <div className="gallery_box doc_image grid-items container row mr-0 ml-0">
        <ReactSortable
          style={{ display: "flex", flexWrap: "wrap" }}
          list={image}
          setList={(newlist) => {
            setimage(newlist);
          }}
          ghostClass="dropArea"
          handle=".dragHandle"
          filter="ignoreDrag"
          preventOnFilter={true}
        >
          {image
            ? image.map((ele, index) => (
                <div
                  className="gallery-img  dragHandle item col-md-3"
                  key={ele.id}
                >
                  <div
                    className="item"
                    draggable
                    onDragEnd={() => imageHandle()}
                  >
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}${ele?.event_docs_path}`}
                      target=" "
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${ele?.event_docs_path}`}
                        alt="profile"
                        className="dragHandle"
                      />
                    </a>
                  </div>
                  <span>
                    <a onClick={() => deleteImage(ele?.id, index)}>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}img/cross.svg`}
                      />
                    </a>{" "}
                  </span>
                </div>
              ))
            : null}
        </ReactSortable>
      </div>
    </div>
  );
}

export default Docimage;
