import React from 'react'
import "./Contact.css"
function Contact() {
  return (
    <div className="demo0">
        <img src={`${process.env.REACT_APP_BASE_URL}contact.png`} alt="contact" />
    </div>
  )
}

export default Contact