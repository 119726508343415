import React, { useState, useEffect } from "react";
import "../App.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IsLoggedIn, UserUpdate, IsLoggedOut } from "../Redux/user/userAction";
import { Audio } from "react-loader-spinner";
import { BallTriangle } from "react-loader-spinner";

function Users() {
  const dispatch = useDispatch();

  const loginHandler = (result) => {
    dispatch(IsLoggedIn(result));
    // navigate("/chat")
    // setTimeout(() => {

    navigate("/");
    // },10)
  };

  var { user_id } = useParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // const re = /^[0-9\b]+$/;
  user_id = atob(user_id);
  const [name, setName] = useState(user_id);
  //  localStorage.removeItem("userid")
  localStorage.setItem("userid", name);
  // console.log(name,"userid")
  //  if( re.test(user_id)){

  //    console.log(user_id,"rdgdrbr")
  //  }
  const fetchData = () => {
    dispatch(IsLoggedOut());
    fetch(`${process.env.REACT_APP_BASE_URL}userDetails?user_id=${user_id}`, {
      method: "post",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "ugiub");
        // console.log(result.result, "Sunil");
        if (result.result) {
          var datas = {
            name: result.data.name,
            image: result.data.image,
            address: result.data.address,
            email: result.data.email,
            phone: result.data.phone,
            username: result.data.username,
            password: result.data.password,
          };
          loginHandler(result);
          dispatch(UserUpdate(datas));
        } else {
          dispatch(IsLoggedOut());
          setError(true);
        }
        setLoading(false);
        //  setTimeout(() => {setLoading(false);
        // } ,1000)
      });
  };

  useEffect(() => {
    setError(false);
    user_id && fetchData();
    // console.log(process.env.REACT_APP_BASE_URL,"gdr");
  }, [user_id]);

  return (
    <div className="Main-all-project">
      {loading && (
        <div className="wholeScreen">
          <div className="blur_design"> </div>
          <div className="spinner_design">
            <BallTriangle
              heigth="100"
              width="100"
              color="white"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
      )}
      {error && (
        <div className="d-feex-center red">
          <h3>User Not found</h3>
        </div>
      )}
    </div>
  );
}

export default Users;
