import React, { useState, useEffect } from "react";
import "./LocationContact.css";
import Swal from "sweetalert2";
import SweetAlertPopup from "../sweetAlertPopup/SweetAlertPopup";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdAdd } from "react-icons/md";
import { ImCross } from "react-icons/im";
import * as $ from "jquery";
import { BallTriangle } from "react-loader-spinner";

const LocationContact = () => {
  const initialDataPoint = {
    email: "",
    phone: "",
    relation_to_guest_of_honors: "",
    person_name: "",
  };

  const initialDataLocation = {
    location: "",
    arrvial_time: "",
    address: "",
    purpose: "",
  };

  const initialDataStaff = {
    phone: "",
    name: "",
  };

  const initialDataClient = {
    phone: "",
    role: "",
    name: "",
  };

  const location = useLocation();
  let eventId = location.state.id;

  const navigate = useNavigate();
  const errorHandler = (err) => {
    navigate("/Error");
  };

  const token = useSelector((state) => state?.userDetail?.userToken);
  console.log(token, "#$t3");

  const [locationsPoints, setLocationsPoints] = useState([initialDataPoint]);
  const [locations, setLocations] = useState([initialDataLocation]);
  const [staff, setStaff] = useState([initialDataStaff]);
  const [client, setClient] = useState([initialDataClient]);
  const [eventType, setEventyType] = useState();
  const [occation, setOccation] = useState();
  const [locationId, setLocationId] = useState();
  const [loading, setLoading] = useState(true);
  const [mainObj, setMainObj] = useState({
    services: "",
    venue_address: "",
    venue_name: "",
    event_date: "",
    start_time: "",
    end_time: "",
    number_of_guests: "",
  });

  const {
    venue_address,
    services,
    venue_name,
    event_date,
    start_time,
    end_time,
    number_of_guests,
  } = mainObj;

  const [errors, setErrors] = useState({});

  const PointChange = (value, key, pointIndex) => {
    setLocationsPoints((prev) => {
      let data = [...prev];
      data[pointIndex][key] = value;
      return data;
    });
  };

  const changeHandlerMain = (e, key) => {
    setMainObj({ ...mainObj, [key]: e.target.value });
  };

  const LocationsChange = (value, key, locationIndex) => {
    setLocations((prev) => {
      let data = [...prev];
      data[locationIndex][key] = value;
      return data;
    });
  };

  const StaffChange = (value, key, StaffIndex) => {
    let data = [...staff];
    data[StaffIndex][key] = value;
    setStaff(data);
  };

  const clientChange = (value, key, clientIndex) => {
    let data = [...client];
    data[clientIndex][key] = value;
    setClient(data);
  };

  const AddPointRow = (e) => {
    setLocationsPoints((prev) => {
      const addPoint1 = [...prev];
      const addPoint = JSON.parse(JSON.stringify(addPoint1.slice()));
      addPoint.push(initialDataPoint);
      return addPoint;
    });
  };

  const AddLocationRow = (e) => {
    setLocations((prev) => {
      const addLocation1 = [...prev];
      const addLocation = JSON.parse(JSON.stringify(addLocation1.slice()));
      addLocation.push(initialDataLocation);
      return addLocation;
    });
  };

  const AddStaffRow = (e) => {
    setStaff((prev) => {
      const addStaff1 = [...prev];
      const addStaff = JSON.parse(JSON.stringify(addStaff1.slice()));
      addStaff.push(initialDataStaff);
      return addStaff;
    });
  };

  const AddClientRow = (e) => {
    setClient((prev) => {
      const addClient1 = [...prev];
      const addClient = JSON.parse(JSON.stringify(addClient1.slice()));
      addClient.push(initialDataClient);
      return addClient;
    });
  };

  const deletePointRow = (pointIndex) => {
    locationsPoints.splice(pointIndex, 1);
    setLocationsPoints([...locationsPoints]);
  };

  const deleteLocationRow = (locationIndex) => {
    locations.splice(locationIndex, 1);
    setLocations([...locations]);
  };

  const deleteStaffRow = (staffIndex) => {
    staff.splice(staffIndex, 1);
    setStaff([...staff]);
  };

  const deleteClientRow = (clientIndex) => {
    client.splice(clientIndex, 1);
    setClient([...client]);
  };

  let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  let phone =
    /^\d{3}(-|\s)\d{3}(-|\s)\d{4}$|^\d{10}$|^1\s\d{3}(-|\s)\d{3}(-|\s)\d{4}$|^(1\s?)?\(\d{3}\)(\s|\-)?\d{3}\-\d{4}$/;
  let num = /^\d+$/;
  let time = new RegExp(/^([01]\d|2[0-3]):?([0-5]\d)$/);

  const validation = () => {
    let startTimeJson =
      start_time?.split(":")[0] * 60 * 60 + start_time?.split(":")[1] * 60;

    let endTimeJson =
      end_time?.split(":")[0] * 60 * 60 + end_time?.split(":")[1] * 60;

    let error = {};
    let hasErrors = false;
    console.log(event_date, services, venue_address, venue_name);
    if (event_date === "") {
      error.event_date = "Please fill event date";
      hasErrors = true;
    }
    if (services === "") {
      error.services = "Please fill services";
      hasErrors = true;
    }
    // if (venue_address === "") {
    //   error.venue_address = "Please fill venue address";
    //   hasErrors = true;
    // }
    // if (venue_name === "") {
    //   error.venue_name = "Please fill venue name";
    //   hasErrors = true;
    // }

    if (
      (startTimeJson >= endTimeJson || end_time === "") &&
      occation == "Sweet 16's" &&
      eventType == "In Person"
    ) {
      error.end_time = "End time is less than start time";
      hasErrors = true;
    }
    if (
      (startTimeJson >= endTimeJson || start_time === "") &&
      occation == "Sweet 16's" &&
      eventType == "In Person"
    ) {
      error.start_time = "Start time is grater than snd time";
      hasErrors = true;
    }
    if (
      (num.test(number_of_guests) === false || number_of_guests == "") &&
      eventType == "In Person" &&
      occation == "Sweet 16's"
    ) {
      error.number_of_guests = "Please fill number of guests	";
      hasErrors = true;
    }
    setErrors(error);
    console.log();
    return hasErrors;
  };

  const Save = (key) => {
    let isValid = !validation();
    console.log(isValid);
    const data = {
      mainObj: mainObj,
      locationsPoints: locationsPoints,
      staff: staff,
      client: client,
      locations: locations,
    };

    let errorMsg = "";
    let subErrorMsg = "";

    locationsPoints.forEach((poits, pointsIndex) => {
      $(`.point_${pointsIndex}`).css("border", "1px solid black");
      if (key === "saveField" && poits.person_name === "") {
        isValid = false;
        $(`.point_${pointsIndex}`).css("border", "1px solid red");
        // errorMsg += "Please Fill Person Name&nbsp";
        // subErrorMsg += " Person Name should not be blank ";
      }
      if (key === "saveField" && poits.relation_to_guest_of_honors === "") {
        isValid = false;
        $(`.point_${pointsIndex}`).css("border", "1px solid red");
        // errorMsg += "Please Fill Relation To Guest Of Honors&nbsp";
        // subErrorMsg += "Relation To Guest Of Honors should not be blank ";
      }

      if (key === "saveField" && phone.test(poits.phone) === false) {
        isValid = false;
        $(`.point_${pointsIndex}`).css("border", "1px solid red");
        // errorMsg += "Please Fill Phone Number&nbsp";
        // subErrorMsg += "phone number should be 10 char and not be blank ";
      }
      if (key === "saveField" && regEmail.test(poits.email) === false) {
        alert("Please Fill valid   Email Id");
        $(`.point_${pointsIndex}`).css("border", "1px solid red");
        // errorMsg += "Please Fill Email Id&nbsp";
        // subErrorMsg += "email should  abc@gmail.com like that";
        isValid = false;
      }
    });

    // eslint-disable-next-line no-lone-blocks
    {
      eventType == "In Person" &&
        occation !== "Default (generic)" &&
        locations.forEach((location, locationIndex) => {
          $(`.location_${locationIndex}`).css("border", "1px solid black");

          if (key === "saveField" && location.location === "") {
            isValid = false;
            $(`.location_${locationIndex}`).css("border", "1px solid red");
            // errorMsg += "Please Fill location&nbsp";
            // subErrorMsg += "Location Name should not be blank ";
          }
          if (
            key === "saveField" &&
            time.test(location.arrvial_time) === false
          ) {
            isValid = false;
            $(`.location_${locationIndex}`).css("border", "1px solid red");
            // errorMsg += "Please Fill Valid Arrival Time&nbsp";
            // subErrorMsg += "Arrival Time should be 00:00  Format";
          }

          if (key === "saveField" && location.address == "") {
            isValid = false;
            $(`.location_${locationIndex}`).css("border", "1px solid red");
            // errorMsg += "Please Fill Address&nbsp";
            // subErrorMsg += " Address should not be blank ";
          }
          if (key === "saveField" && location.purpose == "") {
            isValid = false;
            $(`.location_${locationIndex}`).css("border", "1px solid red");
            // errorMsg += "Please Fill Purpose&nbsp";
            // subErrorMsg += " Purpose Name should not be blank ";
          }
        });
    }
    console.log(isValid);
    staff.forEach((staff, staffIndex) => {
      $(`.staff_${staffIndex}`).css("border", "1px solid black");

      if (key === "saveField" && !staff.name) {
        isValid = false;
        $(`.staff_${staffIndex}`).css("border", "1px solid red");
        // errorMsg += "Please Fill Name&nbsp";
        // subErrorMsg += "Name should not be blank ";
      }
      if (key === "saveField" && !phone.test(staff.phone)) {
        isValid = false;
        $(`.staff_${staffIndex}`).css("border", "1px solid red");
        // errorMsg += "Please fill valid phone&nbsp";
        // subErrorMsg += "Phone should not be blank ";
      }
    });
    client.forEach((client, clientIndex) => {
      $(`.client_${clientIndex}`).css("border", "1px solid black");
      if (key === "saveField" && client.name === "") {
        isValid = false;
        $(`.client_${clientIndex}`).css("border", "1px solid red");
        // errorMsg += "Please Fill Name&nbsp";
        // subErrorMsg += "Name should not be blank ";
      }
      if (key === "saveField" && phone.test(client.phone) === false) {
        isValid = false;
        $(`.client_${clientIndex}`).css("border", "1px solid red");
        // errorMsg += "Please fill valid phone&nbsp";
        // subErrorMsg += "Phone should not be blank ";
      }

      if (key === "saveField" && phone.test(client.phone) === false) {
        isValid = false;
        $(`.client_${clientIndex}`).css("border", "1px solid red");
        // errorMsg += "Please fill valid phone&nbsp";
        // subErrorMsg += "Phone should not be blank ";
      }
    });
    if (!isValid) {
      SweetAlertPopup(
        "Please fill empty field",
        "All field should  be required",
        "warning",
        ""
      );
    }
    if (isValid) {
      fetch(`${process.env.REACT_APP_BASE_URL}updatelocation`, {
        method: "post",
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_id: eventId,
          id: locationId,
          data: data,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.result === 1) {
            eventDetail();
            SweetAlertPopup(
              "Successfully Save",
              "Data Save",
              "success",
              "green"
            );
          }
        })
        .catch((err) => {
          Swal.fire("Something Went Wrong", err, "error");
        });
    }
  };

  const eventDetail = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}eventdetails`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        var event_type = res?.data.EventInfo.event_type;
        var occation = res?.data.EventInfo.occasion;
        var id = res?.data?.EventPlan?.id;
        var allData = JSON.parse(res?.data?.EventPlan?.event_location);
        setLocationId(id);
        if (allData !== null) {
          console.log(allData);
          setLoading(false);
          let obj = allData?.mainObj;
          console.log(obj);
          setMainObj(obj);
          let PointContact = allData?.locationsPoints;
          setLocationsPoints(PointContact);

          let locationData = allData?.locations;
          setLocations(locationData);

          let staffData = allData?.staff;
          setStaff(staffData);

          let client = allData?.client;
          setClient(client);

          setEventyType(event_type);
          setOccation(occation);
        } else {
          staffClient_ApiCall();
        }
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const staffClient_ApiCall = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}getemployeecleint`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        let employee = res?.employeeStaff;
        setStaff(employee);
        let clientList = res?.client;

        setClient(clientList);
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  useEffect(() => {
    eventDetail();
  }, []);

  return (
    <div className="locationMain">
      <div className="container">
        {/* {loading && (
          <div className="wholeScreen">
            <div className="spinner_design">
              <BallTriangle
                heigth="100"
                width="100"
                color="white"
                ariaLabel="loading-indicator"
              />
            </div>
          </div>
        )} */}
        <div className="row">
          <div className="col-12 col-md-7 mx-auto">
            <div className="locationInput">
              <input
                placeholder="Event Date"
                type="date"
                name="event_date"
                min={disablePastDate()}
                max="2030-12-31"
                value={event_date || ""}
                onChange={(e) => changeHandlerMain(e, "event_date")}
              />
              <span className="errorRed">{errors.event_date}</span>
            </div>
          </div>
          <div className="col-12 col-md-7 mx-auto">
            <div className="locationInput">
              <input
                placeholder="Venue Name"
                name="venue_name"
                value={venue_name || ""}
                onChange={(e) => changeHandlerMain(e, "venue_name")}
              />
              {/* <span className="errorRed">{errors.venue_name}</span> */}
            </div>
          </div>
          {occation == "Sweet 16's" && eventType == "In Person" && (
            <div className="col-12 col-md-7 mx-auto row px-0">
              <div className="locationInput col_6 col-12 col-md-6">
                <input
                  placeholder="Start Time	"
                  name="start_time"
                  type="time"
                  value={start_time || ""}
                  onChange={(e) => changeHandlerMain(e, "start_time")}
                />
                <span className="errorRed">{errors.start_time}</span>
              </div>
              <div className="locationInput col_6 col-12 col-md-6">
                <input
                  type="time"
                  placeholder="End Time"
                  name="end_time"
                  value={end_time || ""}
                  onChange={(e) => changeHandlerMain(e, "end_time")}
                />
                <span className="errorRed">{errors.end_time}</span>
              </div>
            </div>
          )}

          <div className="col-12 col-md-7 mx-auto">
            <div className="locationInput">
              <textarea
                placeholder="Venue Address"
                rows="2"
                cols="50"
                name="venue_address"
                value={venue_address || ""}
                onChange={(e) => changeHandlerMain(e, "venue_address")}
              />
              {/* <span className="errorRed">{errors.venue_address}</span> */}
            </div>
          </div>
          <div className="col-12 col-md-7 mx-auto">
            <div className="locationInput">
              <input
                placeholder="Services"
                name="services"
                value={services || ""}
                onChange={(e) => changeHandlerMain(e, "services")}
              />
              <span className="errorRed mb-0">{errors.services}</span>
            </div>
          </div>
          {occation == "Sweet 16's" && eventType == "In Person" && (
            <div className="col-12 col-md-7 mx-auto">
              <div className="locationInput">
                <input
                  type="text"
                  placeholder="Number of Guests"
                  name="number_of_guests"
                  value={number_of_guests}
                  onChange={(e) => changeHandlerMain(e, "number_of_guests")}
                />
                <span className="errorRed">{errors.number_of_guests}</span>
              </div>
            </div>
          )}
        </div>

        <>
          <h4 className="locationTitle">Point of Contact</h4>
          <div className="row locationBorder">
            {locationsPoints.map((points, pointIndex) => {
              return (
                <div className="PointofContact row">
                  <div className="col-md-3 mb-1">
                    <div className="locationInputPoint">
                      <input
                        placeholder="Person Name"
                        name="person_name"
                        // className={`${"person_name"}_${pointIndex}`}
                        className={`point_${pointIndex}`}
                        type="text"
                        value={points.person_name}
                        onChange={(e) => {
                          PointChange(
                            e.target.value,
                            "person_name",
                            pointIndex
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="locationInputPoint">
                      <input
                        placeholder="Relation to Guest of Honors"
                        name="relation_to_guest_of_honors"
                        type="text"
                        // className={`${"relation_to_guest_of_honors"}_${pointIndex}`}
                        className={`point_${pointIndex}`}
                        value={points.relation_to_guest_of_honors}
                        onChange={(e) => {
                          PointChange(
                            e.target.value,
                            "relation_to_guest_of_honors",
                            pointIndex
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="locationInputPoint">
                      <input
                        placeholder="Phone"
                        // className={`${"phone"}_${pointIndex}`}
                        className={`point_${pointIndex}`}
                        type="text"
                        name="phone"
                        value={points.phone}
                        onChange={(e) => {
                          PointChange(e.target.value, "phone", pointIndex);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-1">
                    <div className="locationInputPoint">
                      <input
                        placeholder="Email"
                        className={`point_${pointIndex}`}
                        // className={`${"email"}_${pointIndex}`}
                        type="email"
                        name="email"
                        value={points.email}
                        onChange={(e) => {
                          PointChange(e.target.value, "email", pointIndex);
                        }}
                      />
                    </div>
                  </div>

                  <button
                    className="PointofCross"
                    onClick={(e) => {
                      deletePointRow(pointIndex);
                    }}
                  >
                    <ImCross />
                  </button>
                </div>
              );
            })}

            <div className="col-md-12 pl-0">
              <button className="plusIcon" onClick={(e) => AddPointRow(e)}>
                <MdAdd style={{ fontSize: "20px" }} />
              </button>
            </div>
          </div>
        </>

        <>
          {eventType == "In Person" && occation !== "Default (generic)" && (
            <>
              <h4 className="locationTitle">Locations</h4>
              <div className="row locationBorder">
                {locations.map((locate, locationIndex) => {
                  return (
                    <div className="PointofContact row">
                      <div className="col-md-3 mb-1">
                        <div className="locationInputPoint">
                          <input
                            className={`location_${locationIndex}`}
                            placeholder="Location"
                            name="location"
                            type="text"
                            value={locate?.location || ""}
                            onChange={(e) =>
                              LocationsChange(
                                e.target.value,
                                "location",
                                locationIndex
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-1">
                        <div className="locationInputPoint">
                          <input
                            className={`location_${locationIndex}`}
                            placeholder="Arrvial Time"
                            name="arrvial_time"
                            type="time"
                            value={locate.arrvial_time || ""}
                            onChange={(e) =>
                              LocationsChange(
                                e.target.value,
                                "arrvial_time",
                                locationIndex
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-1">
                        <div className="locationInputPoint">
                          <input
                            className={`location_${locationIndex}`}
                            placeholder="Address"
                            type="text"
                            name="address"
                            value={locate.address || ""}
                            onChange={(e) =>
                              LocationsChange(
                                e.target.value,
                                "address",
                                locationIndex
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-1">
                        <div className="locationInputPoint">
                          <input
                            className={`location_${locationIndex}`}
                            placeholder="Purpose"
                            type="text"
                            name="purpose"
                            value={locate.purpose || ""}
                            onChange={(e) =>
                              LocationsChange(
                                e.target.value,
                                "purpose",
                                locationIndex
                              )
                            }
                          />
                        </div>
                      </div>
                      <button
                        className="PointofCross"
                        onClick={(e) => {
                          deleteLocationRow(locationIndex);
                        }}
                      >
                        <ImCross />
                      </button>
                    </div>
                  );
                })}

                <div className="col-md-12 pl-0">
                  <button
                    className="plusIcon"
                    onClick={(e) => AddLocationRow(e)}
                  >
                    <MdAdd style={{ fontSize: "20px" }} />
                  </button>
                </div>
              </div>
            </>
          )}
          {/* )} */}
        </>

        <div className="row mt-4">
          <div className="col-md-6 ">
            <h4 className="locationTitle text-start-one">
              Entertainers & Staff
            </h4>
            <div className="row locationBorder mr-0">
              {staff.map((staff, staffIndex) => {
                console.log(staff, "srt");
                return (
                  <div className="PointofContact row">
                    <div className="col-md-7 mb-1">
                      <input
                        placeholder="Name"
                        className={`staff_${staffIndex}`}
                        name="name"
                        value={staff.name}
                        onChange={(e) =>
                          StaffChange(e.target.value, "name", staffIndex)
                        }
                      />
                    </div>
                    <div className="col-md-5 mb-1">
                      <input
                        placeholder="Phone"
                        className={`staff_${staffIndex}`}
                        name="phone"
                        value={staff.phone}
                        onChange={(e) =>
                          StaffChange(e.target.value, "phone", staffIndex)
                        }
                      />
                    </div>

                    <button
                      className="PointofCross"
                      onClick={(e) => {
                        deleteStaffRow(staffIndex);
                      }}
                    >
                      <ImCross />
                    </button>
                  </div>
                );
              })}

              <div className="col-md-12 pl-0">
                <button className="plusIcon" onClick={(e) => AddStaffRow(e)}>
                  <MdAdd style={{ fontSize: "20px" }} />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 ">
            <h4 className="locationTitle text-start">Clients</h4>
            <div className="row locationBorder ml-0">
              {client.map((client, clientIndex) => {
                return (
                  <div className="PointofContact row">
                    <div className="col-md-5 mb-1">
                      <input
                        className={`client_${clientIndex}`}
                        placeholder="Name"
                        name="name"
                        value={client.name}
                        onChange={(e) =>
                          clientChange(e.target.value, "name", clientIndex)
                        }
                      />
                    </div>
                    <div className="col-md-3  mb-1">
                      <input
                        className={`client_${clientIndex}`}
                        placeholder="Role"
                        name="role"
                        value={client.role}
                        onChange={(e) =>
                          clientChange(e.target.value, "role", clientIndex)
                        }
                      />
                    </div>
                    <div className="col-md-4  mb-1">
                      <input
                        placeholder="Phone"
                        className={`client_${clientIndex}`}
                        name="phone"
                        value={client.phone}
                        onChange={(e) =>
                          clientChange(e.target.value, "phone", clientIndex)
                        }
                      />
                    </div>

                    <button
                      className="PointofCross"
                      onClick={(e) => {
                        deleteClientRow(clientIndex);
                      }}
                    >
                      <ImCross />
                    </button>
                  </div>
                );
              })}
              <div className="col-md-12 pl-0">
                <button className="plusIcon" onClick={(e) => AddClientRow(e)}>
                  <MdAdd style={{ fontSize: "20px" }} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="saveBtnDiv">
          <button
            className="plusIconSave savePadding "
            onClick={(e) => Save("saveField")}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationContact;
