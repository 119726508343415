import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";

import { ImCross } from "react-icons/im";
import $, { data } from "jquery";

import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import { formatRFC7231 } from "date-fns/fp";
import swal from "sweetalert2";
import Notification from "../Toaster/Notification";

function Iternary() {
  const token = useSelector((state) => state?.userDetail?.userToken);
  const location = useLocation();
  let eventId = location.state.id;

  const userdata = {
    event_id: eventId,
    start_time: "",
    activity: "",
    speaker_name: "",
    speaker_notes: " ",
    id: null,
    created_by: "",
    created_at: "",
    updated_at: null,
    deleted_at: null,
    upload_file: "",
    isCustom: "",
  };

  const ActivityInPerson = [
    {
      name: "Host Inroduction",
      value: "Host Inroduction",
    },
    {
      name: "Game: Tik-Tok",
      value: "Game: Tik-Tok",
    },
    {
      name: "Rules & Regulations",
      value: "Rules & Regulations",
    },
    {
      name: "Game: Icebreaker - Blinfold Pointer",
      value: "Game: Icebreaker - Blinfold Pointer",
    },
    {
      name: "Game: Name That Tune",
      value: "Game: Name That Tune",
    },
    {
      name: "Party Break",
      value: "Party Break",
    },
    {
      name: "Show Pictures",
      value: "Show Pictures",
    },
    {
      name: "Score Check",
      value: "Score Check",
    },
    {
      name: "Game: Icebreaker - 5 Second Rule",
      value: "Game: Icebreaker - 5 Second Rule",
    },
    {
      name: "Game: Chatrageous",
      value: "Game: Chatrageous",
    },
    {
      name: "Winners Announced",
      value: "Winners Announced",
    },
    {
      name: "Victory Speech",
      value: "Victory Speech",
    },
    {
      name: "Speaker",
      value: "Speaker",
    },
    {
      name: "Thank you from guest of honor(s)",
      value: "Thank you from guest of honor(s)",
    },
    {
      name: "Video",
      value: "Video",
    },
    {
      name: "Game: Corporate Card Revoked",
      value: "Game: Corporate Card Revoked",
    },
    {
      name: "Game: Word Search",
      value: "Game: Word Search",
    },

    {
      name: "Game: Sing Happy Birthday",
      value: "Game: Sing Happy Birthday",
    },
    {
      name: "Game: Mini Birthday Interview",
      value: "Game: Mini Birthday Interview",
    },
    {
      name: "Game: Price Is Right",
      value: "Game: Price Is Right",
    },
    {
      name: "Game: Icebreaker - Blindfold Pointer",
      value: "Game: Icebreaker - Blindfold Pointer",
    },
    {
      name: "Game: Adult Diaper Challenge",
      value: "Game: Adult Diaper Challenge",
    },
    {
      name: "Game: Toilet Paper Squares",
      value: "Game: Toilet Paper Squares",
    },

    {
      name: "Game: Guess Who?",
      value: "Game: Guess Who?",
    },
    {
      name: "Game: 2 Truths and a Lie",
      value: "Game: 2 Truths and a Lie",
    },
    {
      name: "Game: Trivia",
      value: "Game: Trivia",
    },
    {
      name: "Game: 3 level Scavenger Hunt",
      value: "Game: 3 level Scavenger Hunt",
    },
    {
      name: "Game: Mad Gab",
      value: "Game: Mad Gab",
    },
    {
      name: "Game: Family Feud",
      value: "Game: Family Feud",
    },
    {
      name: "Game: Charades",
      value: "Game: Charades",
    },
    {
      name: "Game: Song Association",
      value: "Game: Song Association",
    },
    {
      name: "Game: Word Search",
      value: "Game: Word Search",
    },
    {
      name: "Game: 3 Level Scavenger Hunt",
      value: "Game: 3 Level Scavenger Hunt",
    },
    {
      name: "Custom",
      value: "Custom",
    },
  ];

  const [loading, setLoading] = useState(true);

  const [datas, setData] = useState([]);
  const [view, setView] = useState(false);

  const [val, setVal] = useState(true);
  const [isUpload, setIsUpload] = useState(false);
  const [eventType, setEventType] = useState();
  const [occation, setOccation] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [diffrence, setDiffrence] = useState();
  const [customData, setCustomData] = useState([...ActivityInPerson]);

  var babyShowerVirtual;
  var birthdayVirtual;
  var CorporateVirtual;

  if (diffrence >= 1.5) {
    babyShowerVirtual = [
      {
        name: "Music",
        value: "Music",
      },
      {
        name: "Host Introduction",
        value: "Host Introduction",
      },
      {
        name: "Rules & Regulations",
        value: "Rules & Regulations",
      },
      {
        name: "Game: Icebreaker - Blindfold Pointer",
        value: "Game: Icebreaker - Blindfold Pointer",
      },
      {
        name: "Game: Name That Tune",
        value: "Game: Name That Tune",
      },
      {
        name: "Party Break/Show Pictures",
        value: "Party Break/Show Pictures",
      },
      {
        name: "Score Check",
        value: "Score Check",
      },
      {
        name: "GGame: Icebreaker - 5 Second Rule",
        value: "Game: Icebreaker - 5 Second Rule",
      },
      {
        name: "Game: 3 Level Scavenger Hunt",
        value: "Game: 3 Level Scavenger Hunt",
      },
      {
        name: "Game: Toilet Paper Squares",
        value: "Game: Toilet Paper Squares",
      },
      {
        name: "Winners Announced",
        value: "Winners Announced",
      },
      {
        name: "Victory Speech",
        value: "Victory Speech",
      },
      {
        name: "Speaker",
        value: "Speaker",
      },
    ];

    birthdayVirtual = [
      {
        name: "Music",
        value: "Music",
      },
      {
        name: "Host Inroduction",
        value: "Host Inroduction",
      },
      {
        name: "Mini Birthday Interview",
        value: "Mini Birthday Interview",
      },
      {
        name: "Rules & Regulations",
        value: "Rules & Regulations",
      },

      {
        name: "Game: Icebreaker - 5 Second Rule",
        value: "Game: Icebreaker - 5 Second Rule",
      },
      {
        name: "Game: Word Search",
        value: "Game: Word Search",
      },
      {
        name: "Party Break",
        value: "Party Break",
      },
      {
        name: "Score Check",
        value: "Score Check",
      },
      {
        name: "Game: Icebreaker - Celebrity Alphabet",
        value: "Game: Icebreaker - Celebrity Alphabet",
      },
      {
        name: "Game: 3 Level Scavenger Hunt",
        value: "Game: 3 Level Scavenger Hunt",
      },
      {
        name: "Game: Chatrageous",
        value: "Game: Chatrageous",
      },
      {
        name: "Winners Announced",
        value: "Winners Announced",
      },
      {
        name: "Victory Speech",
        value: "Victory Speech",
      },
      {
        name: "Sing Happy Birthday",
        value: "Sing Happy Birthday",
      },
      {
        name: "Speaker",
        value: "Speaker",
      },
      {
        name: "Thank you from guest of honor(s)",
        value: "Thank you from guest of honor(s)",
      },
    ];

    CorporateVirtual = [
      {
        name: "Music",
        value: "Music",
      },
      {
        name: "Host Inroduction",
        value: "Host Inroduction",
      },
      {
        name: "Speaker",
        value: "Speaker",
      },
      {
        name: "Rules & Regulations",
        value: "Rules & Regulations",
      },
      {
        name: "Game: Icebreaker - 5 Second Rule",
        value: "Game: Icebreaker - 5 Second Rule",
      },
      {
        name: "Game: Name That Tune",
        value: "Game: Name That Tune",
      },
      {
        name: "Party Break",
        value: "Party Break",
      },
      {
        name: "Score Check",
        value: "Score Check",
      },
      {
        name: "Game: Icebreaker - Celebrity Alphabet",
        value: "Game: Icebreaker - Celebrity Alphabet",
      },

      {
        name: "Game: 3 Level Scavenger Hunt",
        value: "Game: 3 Level Scavenger Hunt",
      },
      {
        name: "Game: Chatrageous",
        value: "Game: Chatrageous",
      },
      {
        name: "Winners Announced",
        value: "Winners Announced",
      },
      {
        name: "Victory Speech",
        value: "Victory Speech",
      },
      {
        name: "Speaker",
        value: "Speaker",
      },
    ];
  } else {
    babyShowerVirtual = [
      {
        name: "Music",
        value: "Music",
      },
      {
        name: "Host Inroduction",
        value: "Host Inroduction",
      },
      {
        name: "Rules & Regulations",
        value: "Rules & Regulations",
      },
      {
        name: "Game: Icebreaker - Blinfold Pointer",
        value: "Game: Icebreaker - Blinfold Pointer",
      },
      {
        name: "Game: Name That Tune",
        value: "Game: Name That Tune",
      },
      {
        name: "Party Break/Show Pictures",
        value: "Party Break/Show Pictures",
      },
      {
        name: "Score Check",
        value: "Score Check",
      },
      {
        name: "Game: Icebreaker - 5 Second Rule",
        value: "Game: Icebreaker - 5 Second Rule",
      },
      {
        name: "Game: Chatrageous",
        value: "Game: Chatrageous",
      },
      {
        name: "Winners Announced",
        value: "Winners Announced",
      },
      {
        name: "Victory Speech",
        value: "Victory Speech",
      },
      {
        name: "Speaker",
        value: "Speaker",
      },
      {
        name: "Thank you from guest of honor(s)",
        value: "Thank you from guest of honor(s)",
      },
    ];
    birthdayVirtual = [
      {
        name: "Music",
        value: "Music",
      },
      {
        name: "Host Inroduction",
        value: "Host Inroduction",
      },
      {
        name: "Mini Birthday Interview",
        value: "Mini Birthday Interview",
      },
      {
        name: "Rules & Regulations",
        value: "Rules & Regulations",
      },

      {
        name: "Game: Icebreaker - 5 Second Rule",
        value: "Game: Icebreaker - 5 Second Rule",
      },
      {
        name: "Game: Word Search",
        value: "Game: Word Search",
      },
      {
        name: "Party Break",
        value: "Party Break",
      },
      {
        name: "Score Check",
        value: "Score Check",
      },
      {
        name: "Game: 3 Level Scavenger Hunt",
        value: "Game: 3 Level Scavenger Hunt",
      },
      {
        name: "Game: Chatrageous",
        value: "Game: Chatrageous",
      },
      {
        name: "Winners Announced",
        value: "Winners Announced",
      },
      {
        name: "Victory Speech",
        value: "Victory Speech",
      },
      {
        name: "Sing Happy Birthday",
        value: "Sing Happy Birthday",
      },
      {
        name: "Speaker",
        value: "Speaker",
      },
      {
        name: "Thank you from guest of honor(s)",
        value: "Thank you from guest of honor(s)",
      },
    ];
    CorporateVirtual = [
      {
        name: "Music",
        value: "Music",
      },
      {
        name: "Host Inroduction",
        value: "Host Inroduction",
      },
      {
        name: "Speaker",
        value: "Speaker",
      },
      {
        name: "Rules & Regulations",
        value: "Rules & Regulations",
      },
      {
        name: "Game: Icebreaker - 5 Second Rule",
        value: "Game: Icebreaker - 5 Second Rule",
      },
      {
        name: "Game: Name That Tune",
        value: "Game: Name That Tune",
      },
      {
        name: "Party Break",
        value: "Party Break",
      },
      {
        name: "Score Check",
        value: "Score Check",
      },
      {
        name: "Game: Icebreaker - Celebrity Alphabet",
        value: "Game: Icebreaker - Celebrity Alphabet",
      },
      {
        name: "Game: Chatrageous",
        value: "Game: Chatrageous",
      },
      {
        name: "Winners Announced",
        value: "Winners Announced",
      },
      {
        name: "Victory Speech",
        value: "Victory Speech",
      },
    ];
  }

  const [activitybind, setActivitybind] = useState("");
  const [finalOption, setFinalOption] = useState([...ActivityInPerson]);

  // const notify = () => toast.success("Upated Successfully");
  // const notifyDelete = () => toast.success("Delete Successfully");

  const notifyDelete = () => {};
  const navigate = useNavigate();
  const errorHandler = (err) => {
    navigate("/Error");
  };

  const customInput = (value, key, index) => {
    datas[index][key] = value;
  };

  const changeHandler = (value, key, index) => {
    const data = [...datas];
    if (value === "Custom") {
      data[index].isCustom = true;
      data[index][key] = value;
    } else {
      setVal(false);
      data[index].isCustom = false;
      data[index][key] = value;
    }
    setData(data);
  };

  const imageHandle = (e) => {
    const ids = datas.map((ele) => {
      return ele.id;
    });

    const data = {
      doc_id: ids,
      event_id: eventId,
      type: "file",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}sequenceItinerary`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.result === 1) {
          iternarylist();
          toast.success("Document Arranged Successfully");
        }
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const submitHandler = async (e) => {
    // e.preventDefault();
    let data = datas.slice();
    var form_data = new FormData();
    for (let i = 0; i <= data.length; i++) {
      let element = data[i];
      for (var key in element) {
        if (key === "upload_file") {
          let file = "";
          if (element[key]) {
            if (typeof element[key] === "string") {
              const path = getImagePath(element[key]);
              try {
                const blob = await fetch(path).then((res) => res.blob()); // Gets the response and returns it as a blob
                file = new File(
                  [blob],
                  `${new Date().getTime()}_${i}.${element[key]?.split(".")[1]}`
                );
                form_data.append("image", file);
              } catch (error) {
                console.log(error);
              }
            } else {
              file = new File(
                [element[key]],
                `${new Date().getTime()}_${i}.${
                  element[key]?.name?.split(".")[1]
                }`
              );
              form_data.append("image", file);
            }
          }
        } else {
          form_data.append(key, element[key]);
        }
      }
    }

    fetch(`${process.env.REACT_APP_BASE_URL}eventItinerary`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
      },
      body: form_data,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.result === 1) {
          swal.fire("Successfully Save", "Data Save", "success");
          iternarylist();
          setIsUpload(true);
          setView(true);
        }
      })
      .catch((err) => {
        swal.fire("Something Went Wrong", err, "error");
        // errorHandler(err);
      });
  };

  $(function () {
    $('a[data-toggle="tab"]').on("shown.tab", function (e) {
      localStorage.setItem("lastTab", $(this).attr("href"));
    });
    var lastTab = localStorage.getItem("lastTab");

    if (lastTab) {
      $('[href="' + lastTab + '"]').tab("show");
    }
  });

  const addRow = (e) => {
    setIsUpload(false);
    var useradd = datas.slice();
    useradd.push(userdata);
    // setCustomData([...customData])
    setData(useradd);
  };

  const deleteRowItebary = (id, index) => {
    let recross = "Are you sure you want to Remove";
    if (window.confirm(recross) == true) {
      var remove = {
        id: id,
        event_id: eventId,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}deleteEventItinerary`, {
        method: "post",
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(remove),
      })
        .then((response) => response.json())
        .then((res) => {
          notifyDelete();
        })

        .catch((err) => {
          errorHandler(err);
        });
      if (id === null) {
        datas.splice(index, 1);
        setData([...datas]);
      } else {
        let ind = datas.findIndex((i) => {
          return i.id === id;
        });
        datas.splice(ind, 1);
        setData([...datas]);
      }
    }
  };

  const iternarylist = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}eventdetails`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        var occasion = res?.data.EventInfo.occasion;
        var event_type = res?.data.EventInfo.event_type;
        var eventiternary = res?.data?.EventItinerary;
        var starttime = res?.data?.EventInfo?.event_start_time;
        var endtime = res?.data?.EventInfo?.event_end_time;
        var customActivity = res?.data?.CustomActivity;
        setLoading(false);
        if (eventiternary.length === 0) {
          setData([userdata]);
        } else {
          setData(eventiternary);
        }
        setStartTime(starttime);
        setEndTime(endtime);
        setEventType(event_type);
        setOccation(occasion);
        gettime(startTime, endTime);
        setCustomData([...customActivity]);
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  function gettime(startTime, endTime) {
    if (startTime && endTime) {
      let time =
        startTime.split(":")[0] * 60 * 60 +
        startTime.split(":")[1] * 60 +
        startTime.split(":")[2];
      let time2 =
        endTime.split(":")[0] * 60 * 60 +
        endTime.split(":")[1] * 60 +
        endTime.split(":")[2];

      let difference = (time2 - time) / 360000;
      setDiffrence(difference);
    }
  }

  document.addEventListener("DOMContentLoaded", (e) => {
    var dragSrcEl = null;

    function handleDragStart(e) {
      this.style.opacity = "1";

      dragSrcEl = this;

      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/html", this.innerHTML);
    }

    function handleDragOver(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      e.dataTransfer.dropEffect = "move";

      return false;
    }

    function handleDragEnter(e) {
      this.classList.add("over");
    }

    function handleDragLeave(e) {
      this.classList.remove("over");
    }

    function handleDrop(e) {
      if (e.stopPropagation) {
        e.stopPropagation();
      }

      if (dragSrcEl != this) {
        dragSrcEl.innerHTML = this.innerHTML;
        this.innerHTML = e.dataTransfer.getData("text/html");
      }

      return false;
    }

    function handleDragEnd(e) {
      this.style.opacity = "1";

      items.forEach(function (item) {
        item.classList.remove("over");
      });
      imageHandle(e);
    }

    let items = document.querySelectorAll(".container .item");
    items.forEach(function (item) {
      item.addEventListener("dragstart", handleDragStart, false);
      item.addEventListener("dragenter", handleDragEnter, false);
      item.addEventListener("dragover", handleDragOver, false);
      item.addEventListener("dragleave", handleDragLeave, false);
      item.addEventListener("drop", handleDrop, false);
      item.addEventListener("dragend", handleDragEnd, false);
    });
  });

  function checkSequenceId(sequence) {
    return sequence.id;
  }
  let sequenceData = datas.every(checkSequenceId);
  console.log(datas, "customData");

  useEffect(() => {
    iternarylist();
  }, []);

  const getImagePath = (file) => {
    if (file) {
      if (typeof file === "string") {
        return `${process.env.REACT_APP_BASE_URL}itinerary/${file}`;
      } else {
        return URL.createObjectURL(file);
      }
    }
  };

  return (
    <div className="itinerary-box">
      {loading && (
        <div className="wholeScreen">
          <div className="spinner_design">
            <BallTriangle
              heigth="100"
              width="100"
              color="white"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
      )}

      <div className="ov-auto">
        <table className="table table-striped container" id="tbody">
          <div style={{ border: "none" }}>
            <li
              style={{
                border: "none",
                listStyle: "none",
              }}
            >
              <thead
                style={{
                  border: "none",
                  display: "flex",
                }}
              >
                <tr className="one-stap" style={{ border: "none" }}>
                  <th scope="col" className="col-md-2">
                    Start Time
                  </th>
                  <th scope="col" className="col-md-1">
                    Activity
                  </th>
                  <th scope="col" className="col-md-2">
                    Speaker's Name
                  </th>
                  <th scope="col" className="col-md-2">
                    Speaker's Note
                  </th>
                  <th scope="col" className="col-md-2">
                    View Uploaded File
                  </th>
                </tr>
              </thead>
            </li>
          </div>
          <ReactSortable
            list={datas}
            setList={(newlist) => {
              setData(newlist);
            }}
            preventOnFilter={true}
          >
            {sequenceData === true
              ? datas.map((ele, index) => {
                  return (
                    <div
                      key={index}
                      className="dragHandle item "
                      style={{ border: "none" }}
                    >
                      <li
                        className="draggable item"
                        style={{
                          marginTop: "20px",
                          border: "none",
                          listStyle: "none",
                        }}
                        draggable
                        onDragEnd={(e) => imageHandle(e)}
                      >
                        <tbody
                          style={{
                            border: "none",
                            cursor: "pointer",
                            marginTop: "40px",
                          }}
                          key={ele.id}
                          id={ele.id}
                        >
                          <tr
                            className="one-stap itenary "
                            style={{ border: "none" }}
                          >
                            <td style={{ width: "20%" }}>
                              <input
                                placeholder="Start Time"
                                type="time"
                                name="start_time"
                                defaultValue={ele?.start_time || ""}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    "start_time",
                                    index
                                  )
                                }
                              />
                            </td>

                            <td style={{ width: "20%" }}>
                              <select
                                // className="form-control"
                                name="activity"
                                defaultValue={ele.activity || ""}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    "activity",
                                    index
                                  )
                                }
                              >
                                {eventType === "virtual" ? (
                                  occation === "Baby Showers" ? (
                                    babyShowerVirtual.map((opt, index) => {
                                      return (
                                        <option
                                          selected={opt.name === ele.activity}
                                          value={opt.value}
                                        >
                                          {opt.name}
                                        </option>
                                      );
                                    })
                                  ) : occation === "Birthday" ? (
                                    birthdayVirtual.map((opt, index) => {
                                      return (
                                        <option
                                          selected={opt.name === ele.activity}
                                          value={opt.value}
                                        >
                                          {opt.name}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    CorporateVirtual.map((opt, index) => {
                                      return (
                                        <option
                                          selected={opt.name === ele.activity}
                                          value={opt.value}
                                        >
                                          {opt.name}
                                        </option>
                                      );
                                    })
                                  )
                                ) : (
                                  <>
                                    {ActivityInPerson.map((opt, index) => {
                                      return (
                                        <>
                                          <option
                                            selected={opt.name === ele.activity}
                                            value={opt.value}
                                          >
                                            {opt.name}
                                          </option>
                                          {/* <option>{ele.custom_activity}</option> */}
                                        </>
                                      );
                                    })}
                                    {customData?.length > 0 &&
                                      customData.map((activity, index) => {
                                        return (
                                          activity.custom_activity !== null && (
                                            <option
                                              value={activity.custom_activity}
                                            >
                                              {activity.custom_activity}
                                            </option>
                                          )
                                        );
                                      })}
                                  </>
                                )}
                              </select>
                              {ele.isCustom ? (
                                <input
                                  placeholder="Custome Activity"
                                  style={{ marginTop: "6px" }}
                                  onChange={(e) =>
                                    customInput(
                                      e.target.value,
                                      "custom_activity",
                                      index
                                    )
                                  }
                                />
                              ) : null}
                            </td>
                            <td style={{ width: "20%" }} className="pl-0 pr-0">
                              <input
                                placeholder="Speaker Name"
                                type="text"
                                name="speaker_name"
                                defaultValue={ele.speaker_name || ""}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    "speaker_name",
                                    index
                                  )
                                }
                              />
                            </td>
                            <td style={{ width: "20%" }}>
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder="Speaker Notes"
                                type="text"
                                style={{ border: "none" }}
                                name="speaker_notes"
                                defaultValue={ele.speaker_notes || ""}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    "speaker_notes",
                                    index
                                  )
                                }
                              />
                            </td>

                            {ele.upload_file && ele.id ? (
                              <td style={{ width: "20%" }}>
                                <input
                                  type="file"
                                  placeholder="Upload File"
                                  id="uploadDocsId"
                                  className="UploadItenaryInput"
                                  // defaultValue={ele.upload_file || ""}
                                  style={{ wordBreak: "break-all" }}
                                  name="upload_file"
                                  onChange={(e) => {
                                    changeHandler(
                                      e.target.files[0],
                                      "upload_file",
                                      index
                                    );
                                  }}
                                />
                                <br />

                                <a
                                  target="_blank"
                                  href={getImagePath(ele?.upload_file)}
                                  download
                                  rel="noreferrer"
                                  title={ele?.upload_file}
                                >
                                  View
                                </a>
                              </td>
                            ) : (
                              <td style={{ width: "20%" }}>
                                <input
                                  type="file"
                                  id="uploadDocsId"
                                  className="UploadItenaryInput"
                                  // defaultValue={ele.upload_file || ""}
                                  style={{ wordBreak: "break-all" }}
                                  name="upload_file"
                                  onChange={(e) => {
                                    changeHandler(
                                      e.target.files[0],
                                      "upload_file",
                                      index
                                    );
                                  }}
                                />
                              </td>
                            )}
                            <span>
                              <ImCross
                                onClick={(e) => {
                                  deleteRowItebary(ele.id, index);
                                }}
                              />
                            </span>
                          </tr>
                        </tbody>
                      </li>
                    </div>
                  );
                })
              : datas.map((ele, index) => {
                  return (
                    <div key={index} style={{ border: "none" }}>
                      <li
                        style={{
                          marginTop: "20px",
                          border: "none",
                          listStyle: "none",
                        }}
                      >
                        <tbody
                          style={{
                            border: "none",
                            cursor: "pointer",
                            marginTop: "40px",
                          }}
                          key={ele.id}
                          id={ele.id}
                        >
                          <tr
                            className="one-stap itenary "
                            style={{ border: "none" }}
                          >
                            <td style={{ width: "20%" }}>
                              <input
                                placeholder="Start Time"
                                type="time"
                                name="start_time"
                                defaultValue={ele?.start_time || ""}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    "start_time",
                                    index
                                  )
                                }
                              />
                            </td>

                            <td style={{ width: "20%" }}>
                              <select
                                // className="form-control"
                                name="activity"
                                defaultValue={ele.activity || ""}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    "activity",
                                    index
                                  )
                                }
                              >
                                {eventType === "virtual" ? (
                                  occation === "Baby Showers" ? (
                                    babyShowerVirtual.map((opt, index) => {
                                      return (
                                        <option
                                          selected={opt.name === ele.activity}
                                          value={opt.value}
                                        >
                                          {opt.name}
                                        </option>
                                      );
                                    })
                                  ) : occation === "Birthday" ? (
                                    birthdayVirtual.map((opt, index) => {
                                      return (
                                        <option
                                          selected={opt.name === ele.activity}
                                          value={opt.value}
                                        >
                                          {opt.name}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    CorporateVirtual.map((opt, index) => {
                                      return (
                                        <option
                                          selected={opt.name === ele.activity}
                                          value={opt.value}
                                        >
                                          {opt.name}
                                        </option>
                                      );
                                    })
                                  )
                                ) : (
                                  <>
                                    {ActivityInPerson.map((opt, index) => {
                                      return (
                                        <option
                                          selected={opt.name === ele.activity}
                                          value={opt.value}
                                        >
                                          {opt.name}
                                        </option>
                                      );
                                    })}
                                    {customData?.length > 0 &&
                                      customData.map((activity, index) => {
                                        console.log(activity);
                                        return (
                                          activity.custom_activity !== null && (
                                            <option
                                              value={activity.custom_activity}
                                            >
                                              {activity.custom_activity}
                                            </option>
                                          )
                                        );
                                      })}
                                  </>
                                )}
                              </select>

                              {ele.isCustom ? (
                                <input
                                  placeholder="Custome Activity"
                                  style={{ marginTop: "6px" }}
                                  onChange={(e) =>
                                    customInput(
                                      e.target.value,
                                      "custom_activity",
                                      index
                                    )
                                  }
                                />
                              ) : null}
                            </td>
                            <td style={{ width: "20%" }} className="pl-0 pr-0">
                              <input
                                placeholder="Speaker Name"
                                type="text"
                                name="speaker_name"
                                defaultValue={ele.speaker_name || ""}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    "speaker_name",
                                    index
                                  )
                                }
                              />
                            </td>
                            <td style={{ width: "20%" }}>
                              <textarea
                                placeholder="Speaker Notes"
                                type="text"
                                style={{ border: "none" }}
                                name="speaker_notes"
                                defaultValue={ele.speaker_notes || ""}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    "speaker_notes",
                                    index
                                  )
                                }
                              />
                            </td>

                            {ele.upload_file && view ? (
                              <td style={{ width: "20%" }}>
                                <input
                                  type="file"
                                  placeholder="Upload File"
                                  id="uploadDocsId"
                                  className="UploadItenaryInput"
                                  // defaultValue={ele.upload_file || ""}
                                  style={{ wordBreak: "break-all" }}
                                  name="upload_file"
                                  onChange={(e) => {
                                    changeHandler(
                                      e.target.files[0],
                                      "upload_file",
                                      index
                                    );
                                  }}
                                />
                                <br />

                                <a
                                  target="_blank"
                                  href={getImagePath(ele?.upload_file)}
                                  download
                                  rel="noreferrer"
                                  title={ele?.upload_file}
                                >
                                  View
                                </a>
                              </td>
                            ) : (
                              <td style={{ width: "20%" }}>
                                <input
                                  type="file"
                                  id="uploadDocsId"
                                  className="UploadItenaryInput"
                                  // defaultValue={ele.upload_file || ""}
                                  style={{ wordBreak: "break-all" }}
                                  name="upload_file"
                                  onChange={(e) => {
                                    changeHandler(
                                      e.target.files[0],
                                      "upload_file",
                                      index
                                    );
                                  }}
                                />
                              </td>
                            )}
                            <span>
                              <ImCross
                                onClick={(e) => {
                                  deleteRowItebary(ele.id, index);
                                }}
                              />
                            </span>
                          </tr>
                        </tbody>
                      </li>
                    </div>
                  );
                })}
          </ReactSortable>
        </table>
      </div>
      <div className="row-btn">
        <button
          className="btn btn-md btn-primary mr-2"
          type="button"
          onClick={(e) => addRow(e)}
        >
          Add Row
        </button>

        {datas && datas.length ? (
          <button
            className="btn btn-md btn-primary save"
            type="button"
            onClick={(e) => {
              submitHandler(e);
              // remove(e);
            }}
          >
            Save
            {/* <ToastContainer
              className="planner"
              position="top-right"
              autoClose={1000}
              hideProgressBar
            /> */}
            <Notification />
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default Iternary;
