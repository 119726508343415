//------------------------------> List of Speaker  Section <-----------------------------------------

import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { BallTriangle } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";

// import SweetAlert2 from "react-sweetalert2";

import "react-toastify/dist/ReactToastify.css";
// import "../Planner/Planner.css";
import ReactTooltip from "react-tooltip";
import { GrAggregate } from "react-icons/gr";
import types from "@testing-library/user-event";

import { BiCopy, BiSpeaker } from "react-icons/bi";
import "../App.css";
function Eventslist() {
  const token = useSelector((state) => state?.userDetail?.userToken);
  // const [eventDetail, setEventDetail] = useState({});
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const username = useSelector((state) => state?.userDetail?.data?.username);
  const [eventDetail, setEventDetail] = useState({});
  const [value, setValue] = useState("");
  const [eventtitle, setEventtitle] = useState("");
  const [where_is_everyone_from, setwhere_is_everyone_from] = useState("");
  const [theme, setTheme] = useState("");
  const [age, setAge] = useState("");
  const [audience, setAudience] = useState("");
  const [prize, setPrize] = useState("");
  const [music, setMusic] = useState("");
  const [list, setList] = useState("");
  const [list1, setList1] = useState("");

  const [list2, setList2] = useState("");

  const [list3, setList3] = useState("");

  const [list4, setList4] = useState("");
  const [list5, setList5] = useState("");

  const [drop, Setdrop] = useState("");
  const [drink, setDrink] = useState("");
  const [zoom, Setzoom] = useState("");
  const [organization, setOrganization] = useState("");
  const [additionNotes, setadditionNotes] = useState("");
  const [game1, setGame1] = useState("");
  const [game2, setGame2] = useState("");
  const [game3, setGame3] = useState("");
  const [game4, setGame4] = useState("");
  const [game5, setGame5] = useState("");
  const [istwohours, setIstwohours] = useState(false);
  const [isonehour, setIsonehour] = useState(false);
  const [errors, SetErrors] = useState({});
  const [txt, setTxt] = useState("");
  var difference = localStorage.getItem("differencee");
  const [val, setVal] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isOwner, setIsowner] = useState(false);

  const [NameOfOwner, setNameOfOwner] = useState();

  // const location = useLocation();
  // let eventId = location.state.id;

  // const errorHandler = (err) => {
  //   navigate("/Error");
  // };

  function CopyVala() {
    var copyText = document.getElementById("inputlink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);

    toast.success("Copied Successfully");
  }

  // const geteventlist = () => {
  //   fetch(`${process.env.REACT_APP_BASE_URL}eventdetails`, {
  //     method: "post",
  //     headers: {
  //       "x-access-token": `${token}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ id: eventId }),
  //   })
  //     .then((response) => response.json())
  //     .then((res) => {
  //       setEventDetail(res.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       errorHandler(err);
  //     });
  // };

  async function gettime() {
    let starttime = await eventDetail?.EventInfo?.event_start_time;
    let endtime = await eventDetail?.EventInfo?.event_end_time;

    if (starttime && endtime) {
      let time =
        starttime.split(":")[0] * 60 * 60 +
        starttime.split(":")[1] * 60 +
        starttime.split(":")[2];
      let time2 =
        endtime.split(":")[0] * 60 * 60 +
        endtime.split(":")[1] * 60 +
        endtime.split(":")[2];

      let difference = (time2 - time) / 360000;
      console.log(difference, "difference");

      localStorage.setItem("differencee", difference);
    }
  }

  gettime();
  useEffect(() => {
    geteventlist();
  }, []);

  //planner Section

  // const token = useSelector((state) => state?.userDetail?.userToken);

  // const navigate = useNavigate();
  const errorHandler = (err) => {
    navigate("/Error");
  };

  const changeHandler = () => {
    validation();
    setVal(false);
  };

  const notifySuccess = () => {
    toast.success("Upated Successfully");
    // window.location.reload();
  };

  const notiffailure = () => {
    setTimeout(() => {
      toast.error("Not Upated");
    }, 1000);
  };

  const submitHandler = (e) => {
    const error = validation();
    if (Object.keys(error).length > 0) {
      notiffailure();
      return;
    }

    var data = {
      event_id: eventDetail?.EventPlan.event_id,
      name_of_the_honor: eventDetail?.EventPlan?.name_of_the_honor,
      occasion: value,
      event_title: eventtitle,
      theme: theme,
      where_is_everyone_from: where_is_everyone_from,
      audience_age_range: age,
      estimate_audience: audience,
      is_drink_friendly: drink,
      prizes: prize,
      music: music,
      conservative_level: drop,
      list_of_speaker: list,
      list_of_speaker_1: list1,

      list_of_speaker_2: list2,

      list_of_speaker_3: list3,

      list_of_speaker_4: list4,

      list_of_speaker_5: list5,

      unmuted_zoom: zoom,
      what_does_your_guest_of_honor: organization,
      additional_notes_or_instructions: additionNotes,
      game_1: game1,
      game_2: game2,
      game_3: game3,
      game_4: game4,
      game_5: game5,
    };

    fetch(`${process.env.REACT_APP_BASE_URL}eventPlan`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        notifySuccess();
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const location = useLocation();
  let eventId = location.state?.id;

  const geteventlist = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}eventdetails`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: eventId }),
    })
      .then((response) => response.json())
      .then((res) => {
        var eventPlan = res?.data?.EventPlan;
        console.log(res.data.EventInfo, "id");
        setEventDetail(res.data);
        localStorage.setItem(
          "nameofhonour",
          eventDetail?.EventPlan?.name_of_the_honour
        );
        Setdrop(eventPlan?.conservative_level);
        setValue(eventPlan?.occasion);
        setEventtitle(eventPlan?.event_title);
        setTheme(eventPlan?.theme);
        setwhere_is_everyone_from(eventPlan?.where_is_everyone_from);
        setAge(eventPlan?.audience_age_range);
        setAudience(eventPlan?.estimate_audience);
        setPrize(eventPlan?.prizes);
        setMusic(eventPlan?.music);
        setList(eventPlan?.list_of_speaker);
        setList1(eventPlan?.list_of_speaker_1);
        setList2(eventPlan?.list_of_speaker_2);
        setList3(eventPlan?.list_of_speaker_3);
        setList4(eventPlan?.list_of_speaker_4);
        setList5(eventPlan?.list_of_speaker_5);

        Setzoom(eventPlan?.unmuted_zoom);
        setDrink(eventPlan?.is_drink_friendly);
        setOrganization(eventPlan?.what_does_your_guest_of_honor);
        setadditionNotes(eventPlan?.additional_notes_or_instructions);
        setGame1(eventPlan?.game_1);
        setGame2(eventPlan?.game_2);
        setGame3(eventPlan?.game_3);
        setGame4(eventPlan?.game_4);
        setGame5(eventPlan?.game_5);
        setLoading(false);
        const myself = eventPlan?.name_of_the_honour;
        setIsowner(username != myself);
        localStorage.setItem("Owner", myself);
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const validation = () => {
    const num = /^\d+$/;
    let error = {};
    let OwnerName = eventDetail?.EventPlan?.name_of_the_honour;
    console.log(eventDetail?.EventPlan, "OwnerName");
    if (OwnerName === "" || OwnerName === undefined) {
      error.OwnerName = "This field is required";
    }
    // if (age !== "" && !num.test(age)) {
    //   error.age =
    //     "Please Fill The correct age,age shoude be number  and only allow 2 digit";
    // }
    // if (audience !== "" && !num.test(audience)) {
    //   error.estimate_audience =
    //     "Please Fill The correct age,age shoude be number and only allow 3 digit";
    // }
    SetErrors(error);
    return error;
  };

  // const start = localStorage.getItem("startTime")
  // const end = localStorage.getItem("endTime")
  // console.log(moment(start).format("HH:mm").as,"starrt")
  // console.log(end,"starrt")

  // const demo = () => {

  //   console.log({myself})
  //   console.log({username})
  //   if(username == myself){
  //   return  setIsowner(false)
  //   }else{
  //    return setIsowner(true)
  //   }
  // }

  const fjfj = () => {
    if (difference >= 2) {
      setIstwohours(true);
      setIsonehour(false);
    } else if (difference < 2 && difference >= 1.5) {
      setIstwohours(false);
      setIsonehour(false);
    } else if (difference <= 1 && difference < 1.5) {
      setIsonehour(true);
      setIstwohours(false);
    }
  };

  // if(difference >= 1 && difference < 1.5) {
  //   setIstwohours(true)
  //   setIsonehour(false);

  // } else if (difference < 2 && difference >= 1.5){
  //   setIstwohours(false);
  //   setIsonehour(false);

  // }else if(difference >= 2){
  //   setIsonehour(false);
  //   setIstwohours(true);
  // }

  useEffect(() => {
    fjfj();
    geteventlist();
    // changeHandler()
  }, [eventId]);

  return (
    <div>
      {loading && (
        <div className="wholeScreen">
          <div className="spinner_design">
            <BallTriangle
              heigth="100"
              width="100"
              color="white"
              ariaLabel="loading-indicator"
            />
          </div>
        </div>
      )}
      <form
        className="row needs-validation event_panel form-wrapper"
        // onSubmit="formSubmit(event)"
      >
        {/* <div className="col-md-6 form-col form-col">
          <label htmlFor="event_name" className="form-label">
            Name
          </label>
          <input
            className="form-control"
            name="event_name"
            value={eventDetail?.EventInfo?.event_name || ""}
            disabled={true}
          />
        </div> */}

        {/* <div className="col-md-6 form-col">
          <label htmlFor="event-occasion" className="form-label">
            E-mail
          </label>
          <input
            type="text"
            className="form-control"
            name="occasion"
            value={eventDetail?.EventInfo?.email || ""}
            disabled={true}
          />
        </div> */}

        {/* event Link */}
        <div className="col-md-6 form-col">
          <label htmlFor="event-link" className="form-label">
            Event Link
          </label>
          <div className="eventLinkBiCopy">
            <input
              type="url"
              id="inputlink"
              className="form-control"
              // onInput="resetErrors('event_link_error')"
              name="event_link"
              value={eventDetail?.EventInfo?.event_link || ""}
              disabled={true}
            />
            <BiCopy
              onClick={CopyVala}
              className="BiCopy"
              style={{
                position: "absolute",
                right: "30px !important",
                top: "41px !important",
                cursor: "pointer",
              }}
            />
          </div>

          <ToastContainer className="planner" autoClose={10} />
        </div>

        <div className="col-md-6 form-col">
          <label htmlFor="event-date" className="form-label">
            Event Type
          </label>
          <input
            className="form-control"
            name="event_type"
            defaultValue={eventDetail?.EventInfo?.event_type}
            disabled={true}
          />
        </div>

        <div className="col-md-6 form-col">
          <label htmlFor="event-date" className="form-label">
            Event Date
          </label>
          <input
            // type="date"
            className="form-control"
            name="event_date"
            value={
              moment(eventDetail?.EventInfo?.event_date).format(
                "MMMM Do YYYY"
              ) || ""
            }
            // defaultValue={eventDetail?.EventInfo?.event_date}
            // onInput="resetErrors('event_date_error')"
            disabled={true}
          />
        </div>

        <div className="col-md-6 form-col">
          <label htmlFor="event-time" className="form-label">
            Event Start Time
          </label>

          <input
            // type="time"
            className="form-control"
            name="event_start_time"
            aria-describedby="inputGroupPrepend"
            value={
              moment(
                eventDetail?.EventInfo?.event_start_time,
                "hh:mm A"
              ).format("hh:mm A") || ""
            }
            // onInput="resetErrors('event_start_time_error')"
            disabled={true}
          />
          {/* {console.log(moment(eventDetail?.EventInfo?.event_start_time, 'hh:mm a').format("hh:mm a") ,"tychtc")} */}
        </div>

        <div className="col-md-6 form-col">
          <label htmlFor="event-time" className="form-label">
            Event End Time
          </label>

          <input
            // type="time"
            className="form-control"
            name="event_end_time"
            aria-describedby="inputGroupPrepend"
            value={
              moment(eventDetail?.EventInfo?.event_end_time, "hh:mm A").format(
                "hh:mm A"
              ) || ""
            }
            // onInput="resetErrors('event_end_time_error')"
            disabled={true}
          />
        </div>

        <div className="col-md-6 form-col">
          <label htmlFor="event-name" className="form-label">
            Name of the guest of honor
          </label>
          <label className="form-control">
            {eventDetail?.EventPlan?.name_of_the_honour || ""}
          </label>
          <span className="error_message">{errors.OwnerName}</span>
        </div>

        <div className="col-md-6 form-col">
          <label htmlFor="event_occasion" className="form-label">
            Occasion&nbsp;
          </label>
          {val ? (
            <input
              type="text"
              text="true"
              maxLength={50}
              className="form-control"
              placeholder="Occasion "
              value={eventDetail?.EventPlan?.occasion || ""}
              onChange={changeHandler}
              // disabled={isOwner}
              disabled={true}

              // disabled={}
              // disabled={isOwner}
            />
          ) : (
            <input
              type="text"
              // disabled={isOwner}
              // disabled={isOwner}
              disabled={true}
              text="true"
              maxLength={50}
              className="form-control"
              placeholder="Occasion"
              value={value}
              onChange={(e) => {
                validation();
                setValue(e.target.value);
              }}
            />
          )}
          <span className="error_message">{errors.occasion}</span>
          {/* {console.log(isOwner, "weudgy")} */}
        </div>

        <div className="col-md-6 form-col">
          <label htmlFor="event-date" className="form-label">
            Event Title&nbsp;
          </label>
          {val ? (
            <input
              // disabled={isOwner}
              type="text"
              maxLength={50}
              text="true"
              className="form-control"
              placeholder="Event Title"
              value={eventDetail?.EventPlan?.event_title || ""}
              onChange={changeHandler}
            />
          ) : (
            <input
              // disabled={isOwner}
              maxLength={50}
              type="text"
              text="true"
              className="form-control"
              placeholder="Event Title"
              value={eventtitle}
              onChange={(e) => {
                validation();
                setEventtitle(e.target.value);
              }}
            />
          )}
          <span className="error_message">{errors.event_title}</span>
        </div>

        <div className="col-md-6 form-col">
          <label htmlFor="event-time" className="form-label">
            Theme&nbsp;
          </label>

          {val ? (
            <input
              // disabled={isOwner}
              maxLength={50}
              type="text"
              className="form-control"
              placeholder="Theme"
              value={eventDetail?.EventPlan?.theme || ""}
              onChange={changeHandler}
            />
          ) : (
            <input
              // disabled={isOwner}
              maxLength={50}
              type="text"
              className="form-control"
              placeholder="Theme"
              value={theme}
              onChange={(e) => {
                validation();
                setTheme(e.target.value);
              }}
            />
          )}
          <span className="error_message">{errors.theme}</span>
        </div>

        <div className="col-md-6 form-col ">
          <label htmlFor="Organization" className="form-label">
            Where is everyone from ? &nbsp; &nbsp; &nbsp;
            <icon
              data-tip="Country/State/Ethnic-backgrounds"
              data-for="test"
              multiline="true"
              className="ReactTooltipSpan"
            >
              i
              <ReactTooltip type="warning" id="test" />
            </icon>
          </label>
          {val ? (
            <input
              maxLength={100}
              type="text"
              name="Organization"
              className="form-control"
              placeholder="Where is everyone from "
              // disabled={isOwner}
              onChange={(e) => {
                changeHandler(e);
              }}
              value={eventDetail.EventPlan?.where_is_everyone_from || ""}
            ></input>
          ) : (
            <textarea
              maxLength={100}
              name="Organization"
              // disabled={isOwner}
              className="form-control"
              placeholder="Where is everyone from "
              onChange={(e) => {
                validation();
                setwhere_is_everyone_from(e.target.value);
              }}
              value={where_is_everyone_from}
            ></textarea>
          )}
          <span className="error_message">{errors.organization}</span>
        </div>
        <div className="col-md-6 form-col ">
          <label htmlFor="Organization" className="form-label">
            What does your guest of honor or organization do?&nbsp;
          </label>
          {val ? (
            <input
              maxLength={100}
              type="text"
              name="Organization"
              className="form-control"
              placeholder="What does your guest of honor or organization do?"
              // disabled={isOwner}
              onChange={(e) => {
                changeHandler(e);
              }}
              value={
                eventDetail?.EventPlan?.what_does_your_guest_of_honor || ""
              }
            ></input>
          ) : (
            <textarea
              maxLength={100}
              name="Organization"
              // disabled={isOwner}
              className="form-control"
              placeholder="What does your guest of honor or organization do?"
              onChange={(e) => {
                validation();
                setOrganization(e.target.value);
              }}
              value={organization}
            ></textarea>
          )}
          <span className="error_message">{errors.organization}</span>
        </div>

        <div className="col-md-6 form-col ">
          <label htmlFor="invoice" className="form-label">
            Audience age range&nbsp;
          </label>
          {val ? (
            <input
              // disabled={isOwner}
              // number="true"
              // maxLength={2}
              type="text"
              className="form-control"
              placeholder="Audience-Age-Range"
              value={eventDetail?.EventPlan?.audience_age_range || ""}
              onChange={changeHandler}
            />
          ) : (
            <input
              // disabled={isOwner}
              // type="number"
              type="text"
              // maxLength={2}
              className="form-control"
              placeholder="Audience-Age-Range"
              value={age}
              onChange={(e) => {
                setAge(e.target.value);
                validation();
              }}
            />
          )}
          <span className="error_message">{errors.age}</span>
        </div>

        <div className="col-md-6 form-col ">
          <label htmlFor="invoice" className="form-label">
            Guest count estimate&nbsp;
          </label>
          {val ? (
            // inputProps={{ maxLength: 999}}
            <input
              // disabled={isOwner}
              // number="true"
              // maxLength={3}
              type="text"
              className="form-control"
              placeholder="Estimated Audience"
              value={eventDetail?.EventPlan?.estimate_audience || ""}
              onChange={changeHandler}
            />
          ) : (
            <input
              // disabled={isOwner}
              // number="true"
              // maxLength={3}
              type="text"
              className="form-control"
              placeholder="Estimated Audience"
              value={audience}
              onChange={(e) => {
                setAudience(e.target.value);
                validation();
              }}
            />
          )}
          <span className="error_message">{errors.estimate_audience}</span>
        </div>

        <div className="col-md-6 form-col">
          <label className="form-label  d-flex">
            Is this event drink friendly ?
          </label>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              // disabled={isOwner}
              value="yes"
              checked={drink === "yes"}
              className="form-check-input"
              onChange={(e) => setDrink(e.target.value)}
            />
            <label className="form-check-label" htmlFor="admintypeCustomRadio1">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              // disabled={isOwner}
              checked={drink === "no"}
              value="no"
              className="form-check-input"
              onChange={(e) => setDrink(e.target.value)}
            />
            <label className="form-check-label" htmlFor="admintypeCustomRadio2">
              No
            </label>
          </div>
        </div>

        <div className="col-md-6 form-col">
          <label className="form-label  d-flex">
            Would you like to partake in our traditional crazy unmuted Zoom
            happy birthday ?
          </label>
          <div className="form-check form-check-inline">
            <input
              // disabled={isOwner}
              type="radio"
              name="unmuted_zoom"
              className="form-check-input"
              checked={zoom === "yes"}
              value="yes"
              onChange={(e) => Setzoom(e.target.value)}
            />
            <label className="form-check-label" htmlFor="admintypeCustomRadio1">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              // disabled={isOwner}
              type="radio"
              checked={zoom === "no"}
              onChange={(e) => Setzoom(e.target.value)}
              value="no"
              name="unmuted_zoom"
              className="form-check-input"
            />
            <label className="form-check-label" htmlFor="admintypeCustomRadio2">
              No
            </label>
          </div>
        </div>

        <div className="col-md-6 form-col ">
          <label htmlFor="invoice" className="form-label">
            Prizes&nbsp; &nbsp; &nbsp;
            <icon
              data-for="test"
              data-multiline="true"
              color="black"
              background="white"
              className="ReactTooltipSpan"
              data-tip="We usually pick 3 top collective winners towrds the end of the event.<br/>
                        If you'd like to give more than 3 prizes, let us know what the prizes are <br/>
                         and we'll create awards like best dancer to distribute them"
            >
              i
              <ReactTooltip type="warning" id="test" />
            </icon>
          </label>
          {val ? (
            <textarea
              // disabled={isOwner}
              type="number"
              maxLength={100}
              number="true"
              className="form-control"
              placeholder="Prizes"
              value={eventDetail?.EventPlan?.prizes || ""}
              onChange={changeHandler}
            />
          ) : (
            <textarea
              // disabled={isOwner}
              maxLength={100}
              type="number"
              number="true"
              className="form-control"
              placeholder="Prizes"
              value={prize}
              onChange={(e) => {
                setPrize(e.target.value);
                validation();
              }}
            />
          )}
          <span className="error_message">{errors.prizes}</span>
        </div>

        <div className="col-md-6 form-col ">
          <label htmlFor="music" className="form-label">
            Music requests &nbsp; &nbsp; &nbsp;
            <icon
              data-for="test"
              multiline="true"
              color="black"
              background="white"
              className="ReactTooltipSpan"
              data-tip="Favorite genres & artists"
            >
              i
              <ReactTooltip type="warning" id="test" />
            </icon>
          </label>
          {val ? (
            <textarea
              // disabled={isOwner}
              maxLength={100}
              type="text"
              className="form-control"
              placeholder="Music"
              value={eventDetail?.EventPlan?.music || ""}
              onChange={changeHandler}
            />
          ) : (
            <textarea
              maxLength={100}
              // disabled={isOwner}
              type="text"
              className="form-control"
              placeholder="Music"
              value={music}
              onChange={(e) => {
                setMusic(e.target.value);
                validation();
              }}
            />
          )}
          <span className="error_message">{errors.music}</span>
        </div>

        <div className="col-md-6 form-col ">
          <label htmlFor="conservative_level" className="form-label">
            Conservative Level&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <icon
              data-for="test"
              data-multiline="true"
              color="black"
              background="white"
              className="ReactTooltipSpan"
              data-tip="(1=most conservative, 5=least conservative)<br/> “Conservative” This is in regards to banter and topic <br/>choices. At no level do we curse or bring up politics. <br/>
               Below are some examples to help understand each level"
            >
              i
              <ReactTooltip type="warning" id="test" />
            </icon>
          </label>

          {val ? (
            <select
              // disabled={isOwner}
              // defaultValue=""
              className="form-select"
              onChange={(e) => changeHandler(e)}
              value={drop || ""}
            >
              <option>{eventDetail?.EventPlan?.conservative_level}</option>
              <option>Highly-Liked Conservative</option>
              <option>Most Conservative</option>
              <option> Modrate Conservative</option>
              <option>Less Conservative</option>
              <option>Least Conservative</option>
            </select>
          ) : (
            <select
              // disabled={isOwner}
              className="form-control"
              placeholder="Please select"
              value={drop}
              onChange={(e) => {
                validation();
                Setdrop(e.target.value);
              }}
            >
              <option> </option>
              <option>Highly-Liked Conservative</option>
              <option>Most Conservative</option>
              <option> Modrate Conservative</option>
              <option>Less Conservative</option>
              <option>Least Conservative</option>
            </select>
          )}
          <span className="error_message">{errors.drop}</span>
        </div>

        <div className="col-md-6 form-col ">
          <label htmlFor="list_of_speaker" className="form-label">
            List Of Speakers&nbsp;
          </label>
          {val ? (
            <input
              // disabled={isOwner}
              name="list_of_speaker"
              maxLength={100}
              className="form-control"
              placeholder="List Of Speaker"
              onChange={(e) => {
                changeHandler(e);
              }}
              value={eventDetail?.EventPlan?.list_of_speaker || ""}
            ></input>
          ) : (
            <textarea
              name="list_of_speaker"
              className="form-control"
              maxLength={100}
              // disabled={isOwner}
              placeholder="List Of Speaker"
              onChange={(e) => {
                validation();
                setList(e.target.value);
              }}
              value={list}
            ></textarea>
          )}
          <span className="error_message">{errors.list}</span>
        </div>

        {istwohours ? (
          <div className="row">
            <div className="col-md-6 form-col " id="game1">
              <label htmlFor="conservative_level" className="form-label">
                Select Game 1 &nbsp;<span style={{ color: "red" }}>*</span>
              </label>
              {val ? (
                <select
                  className="form-select"
                  // disabled={isOwner}
                  onChange={(e) => changeHandler(e)}
                  value={game1 || ""}
                >
                  <option style={{ display: "none" }}> Select Game</option>
                  <option>{eventDetail?.EventPlan?.game_1}</option>
                  <option>Icebreaker - 5 Second Rule</option>
                  <option>Icebreaker - Celebrity Alphabet</option>
                  <option>Corporate Card Revoked</option>
                  <option>Name That Tune</option>
                  <option>Chatageous</option>i8
                  <option>Word Search</option>
                  <option>Charades</option>
                  <option>Family Feud</option>
                  <option>Mad Gab3</option>
                  <option>level Scavenger Hunt</option>
                  <option>Trivia</option>
                  <option>2 Truths and a Lie</option>
                </select>
              ) : (
                <select
                  // defaultValue=""
                  className="form-select"
                  // disabled={isOwner}
                  value={game1}
                  placeholder="select"
                  onChange={(e) => {
                    setGame1(e.target.value);
                    validation();
                  }}
                  // onChange={(e) => changeHandler(e)}
                >
                  <option></option>
                  <option>Icebreaker - 5 Second Rule</option>
                  <option>Icebreaker - Celebrity Alphabet</option>
                  <option>Corporate Card Revoked</option>
                  <option>Name That Tune</option>
                  <option>Chatageous</option>
                  <option>Word Search</option>
                  <option>Charades</option>
                  <option>Family Feud</option>
                  <option>Mad Gab3</option>
                  <option>level Scavenger Hunt</option>
                  <option>Trivia</option>
                  <option>2 Truths and a Lie</option>
                </select>
              )}
            </div>

            <div className="col-md-6 form-col">
              <label htmlFor="list_of_speaker" className="form-label">
                List Of Speakers&nbsp;
              </label>
              {val ? (
                <input
                  // disabled={isOwner}
                  name="list_of_speaker_1"
                  maxLength={100}
                  className="form-control"
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                  value={eventDetail?.EventPlan?.list_of_speaker_1 || ""}
                ></input>
              ) : (
                <textarea
                  name="list_of_speaker_1"
                  className="form-control"
                  maxLength={100}
                  // disabled={isOwner}
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    validation();
                    setList1(e.target.value);
                  }}
                  value={list1}
                ></textarea>
              )}
              <span className="error_message">{errors.list}</span>
            </div>

            {/* end */}
            <div className="col-md-6 form-col " id="game2">
              <label htmlFor="conservative_level" className="form-label">
                Select Game 2 &nbsp;
              </label>
              {val ? (
                <select
                  // disabled={isOwner}
                  className="form-select"
                  onChange={(e) => changeHandler(e)}
                  value={game2 || ""}
                >
                  <option style={{ display: "none" }}> Select Game</option>
                  <option>{eventDetail?.EventPlan?.game_2}</option>
                  <option>Icebreaker - 5 Second Rule</option>
                  <option>Icebreaker - Celebrity Alphabet</option>
                  <option>Corporate Card Revoked</option>
                  <option>Name That Tune</option>
                  <option>Chatageous</option>
                  <option>Word Search</option>
                  <option>Charades</option>
                  <option>Family Feud</option>
                  <option>Mad Gab3</option>
                  <option>level Scavenger Hunt</option>
                  <option>Trivia</option>
                  <option>2 Truths and a Lie</option>
                </select>
              ) : (
                <div>
                  {/* {console.log(val, "val")} */}

                  <select
                    // defaultValue=""
                    className="form-select"
                    // disabled={isOwner}
                    value={game2}
                    placeholder="select"
                    onChange={(e) => {
                      setGame2(e.target.value);
                      validation();
                    }}
                    // onChange={(e) => changeHandler(e)}
                  >
                    <option></option>
                    <option>Icebreaker - 5 Second Rule</option>
                    <option>Icebreaker - Celebrity Alphabet</option>
                    <option>Corporate Card Revoked</option>
                    <option>Name That Tune</option>
                    <option>Chatageous</option>
                    <option>Word Search</option>
                    <option>Charades</option>
                    <option>Family Feud</option>
                    <option>Mad Gab3</option>
                    <option>level Scavenger Hunt</option>
                    <option>Trivia</option>
                    <option>2 Truths and a Lie</option>
                  </select>
                </div>
              )}
              {/* <span className="error_message">{errors.drop}</span> */}
            </div>

            <div className="col-md-6 form-col ">
              <label htmlFor="list_of_speaker" className="form-label">
                List Of Speakers&nbsp;
              </label>
              {val ? (
                <input
                  // disabled={isOwner}
                  name="list_of_speaker_2"
                  maxLength={100}
                  className="form-control"
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                  value={eventDetail?.EventPlan?.list_of_speaker_2 || ""}
                ></input>
              ) : (
                <textarea
                  name="list_of_speaker_2"
                  className="form-control"
                  maxLength={100}
                  // disabled={isOwner}
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    validation();
                    setList2(e.target.value);
                  }}
                  value={list2}
                ></textarea>
              )}
              <span className="error_message">{errors.list}</span>
            </div>

            <div className="col-md-6 form-col " id="game3">
              <label htmlFor="conservative_level" className="form-label">
                Select Game 3 &nbsp;
              </label>
              {val ? (
                <select
                  // disabled={isOwner}
                  className="form-select"
                  onChange={(e) => changeHandler(e)}
                  value={game3 || ""}
                >
                  <option style={{ display: "none" }}> Select Game</option>
                  <option>{eventDetail?.EventPlan?.game_3}</option>
                  <option>Icebreaker - 5 Second Rule</option>
                  <option>Icebreaker - Celebrity Alphabet</option>
                  <option>Corporate Card Revoked</option>
                  <option>Name That Tune</option>
                  <option>Chatageous</option>
                  <option>Word Search</option>
                  <option>Charades</option>
                  <option>Family Feud</option>
                  <option>Mad Gab3</option>
                  <option>level Scavenger Hunt</option>
                  <option>Trivia</option>
                  <option>2 Truths and a Lie</option>
                </select>
              ) : (
                <div>
                  {/* {console.log(val, "val")} */}

                  <select
                    // defaultValue=""
                    className="form-select"
                    value={game3}
                    // disabled={isOwner}
                    placeholder="select"
                    onChange={(e) => {
                      setGame3(e.target.value);
                      validation();
                    }}
                    // onChange={(e) => changeHandler(e)}
                  >
                    <option></option>
                    <option>Icebreaker - 5 Second Rule</option>
                    <option>Icebreaker - Celebrity Alphabet</option>
                    <option>Corporate Card Revoked</option>
                    <option>Name That Tune</option>
                    <option>Chatageous</option>
                    <option>Word Search</option>
                    <option>Charades</option>
                    <option>Family Feud</option>
                    <option>Mad Gab3</option>
                    <option>level Scavenger Hunt</option>
                    <option>Trivia</option>
                    <option>2 Truths and a Lie</option>
                  </select>
                </div>
              )}
              {/* <span className="error_message">{errors.drop}</span> */}
            </div>
            <div className="col-md-6 form-col ">
              <label htmlFor="list_of_speaker" className="form-label">
                List Of Speakers&nbsp;
              </label>
              {val ? (
                <input
                  // disabled={isOwner}
                  name="list_of_speaker_3"
                  maxLength={100}
                  className="form-control"
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                  value={eventDetail?.EventPlan?.list_of_speaker_3 || ""}
                ></input>
              ) : (
                <textarea
                  name="list_of_speaker_3"
                  className="form-control"
                  maxLength={100}
                  // disabled={isOwner}
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    validation();
                    setList3(e.target.value);
                  }}
                  value={list3}
                ></textarea>
              )}
              <span className="error_message">{errors.list}</span>
            </div>

            <div className="col-md-6 form-col " id="game4">
              <label htmlFor="conservative_level" className="form-label">
                Select Game 4 &nbsp;
              </label>
              {val ? (
                <select
                  // disabled={isOwner}
                  className="form-select"
                  onChange={(e) => changeHandler(e)}
                  value={game4 || ""}
                >
                  <option style={{ display: "none" }}> Select Game</option>
                  <option>{eventDetail?.EventPlan?.game_4}</option>
                  <option>Icebreaker - 5 Second Rule</option>
                  <option>Icebreaker - Celebrity Alphabet</option>
                  <option>Corporate Card Revoked</option>
                  <option>Name That Tune</option>
                  <option>Chatageous</option>
                  <option>Word Search</option>
                  <option>Charades</option>
                  <option>Family Feud</option>
                  <option>Mad Gab3</option>
                  <option>level Scavenger Hunt</option>
                  <option>Trivia</option>
                  <option>2 Truths and a Lie</option>
                </select>
              ) : (
                <div>
                  {/* {console.log(val, "val")} */}

                  <select
                    // defaultValue=""
                    className="form-select"
                    value={game4}
                    // disabled={isOwner}
                    placeholder="select"
                    onChange={(e) => {
                      setGame4(e.target.value);
                      validation();
                    }}
                    // onChange={(e) => changeHandler(e)}
                  >
                    <option></option>
                    <option>Icebreaker - 5 Second Rule</option>
                    <option>Icebreaker - Celebrity Alphabet</option>
                    <option>Corporate Card Revoked</option>
                    <option>Name That Tune</option>
                    <option>Chatageous</option>
                    <option>Word Search</option>
                    <option>Charades</option>
                    <option>Family Feud</option>
                    <option>Mad Gab3</option>
                    <option>level Scavenger Hunt</option>
                    <option>Trivia</option>
                    <option>2 Truths and a Lie</option>
                  </select>
                </div>
              )}
              {/* <span className="error_message">{errors.drop}</span> */}
            </div>

            <div className="col-md-6 form-col ">
              <label htmlFor="list_of_speaker" className="form-label">
                List Of Speakers&nbsp;
              </label>
              {val ? (
                <input
                  // disabled={isOwner}
                  name="list_of_speaker_4"
                  maxLength={100}
                  className="form-control"
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                  value={eventDetail?.EventPlan?.list_of_speaker_4 || ""}
                ></input>
              ) : (
                <textarea
                  name="list_of_speaker_4"
                  className="form-control"
                  maxLength={100}
                  // disabled={isOwner}
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    validation();
                    setList4(e.target.value);
                  }}
                  value={list4}
                ></textarea>
              )}
              <span className="error_message">{errors.list}</span>
            </div>

            <div className="col-md-6 form-col " id="game5">
              <label htmlFor="conservative_level" className="form-label">
                Select Game 5 &nbsp;
              </label>
              {val ? (
                <select
                  // disabled={isOwner}
                  className="form-select"
                  onChange={(e) => changeHandler(e)}
                  value={game5 || ""}
                >
                  <option style={{ display: "none" }}> Select Game</option>
                  <option>{eventDetail?.EventPlan?.game_5}</option>
                  <option>Icebreaker - 5 Second Rule</option>
                  <option>Icebreaker - Celebrity Alphabet</option>
                  <option>Corporate Card Revoked</option>
                  <option>Name That Tune</option>
                  <option>Chatageous</option>
                  <option>Word Search</option>
                  <option>Charades</option>
                  <option>Family Feud</option>
                  <option>Mad Gab3</option>
                  <option>level Scavenger Hunt</option>
                  <option>Trivia</option>
                  <option>2 Truths and a Lie</option>
                </select>
              ) : (
                <div>
                  {/* {console.log(val, "val")} */}

                  <select
                    // defaultValue=""
                    className="form-select"
                    // disabled={isOwner}
                    value={game5}
                    placeholder="select"
                    onChange={(e) => {
                      setGame5(e.target.value);
                      validation();
                    }}
                    // onChange={(e) => changeHandler(e)}
                  >
                    <option></option>
                    <option>Icebreaker - 5 Second Rule</option>
                    <option>Icebreaker - Celebrity Alphabet</option>
                    <option>Corporate Card Revoked</option>
                    <option>Name That Tune</option>
                    <option>Chatageous</option>
                    <option>Word Search</option>
                    <option>Charades</option>
                    <option>Family Feud</option>
                    <option>Mad Gab3</option>
                    <option>level Scavenger Hunt</option>
                    <option>Trivia</option>
                    <option>2 Truths and a Lie</option>
                  </select>
                </div>
              )}
              {/* <span className="error_message">{errors.drop}</span> */}
            </div>
            <div className="col-md-6 form-col ">
              <label htmlFor="list_of_speaker" className="form-label">
                List Of Speakers&nbsp;
              </label>
              {val ? (
                <input
                  // disabled={isOwner}
                  name="list_of_speaker_5"
                  maxLength={100}
                  className="form-control"
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                  value={eventDetail?.EventPlan?.list_of_speaker_5 || ""}
                ></input>
              ) : (
                <textarea
                  name="list_of_speaker_5"
                  className="form-control"
                  maxLength={100}
                  // disabled={isOwner}
                  placeholder="List Of Speaker"
                  onChange={(e) => {
                    validation();
                    setList5(e.target.value);
                  }}
                  value={list5}
                ></textarea>
              )}
              <span className="error_message">{errors.list}</span>
            </div>
          </div>
        ) : (
          <div>
            {isonehour ? (
              <div className="row">
                <div className="col-md-6 form-col " id="game1">
                  <label htmlFor="conservative_level" className="form-label">
                    Select Game 1 &nbsp;
                  </label>
                  {val ? (
                    <select
                      // disabled={isOwner}
                      className="form-select"
                      onChange={(e) => changeHandler(e)}
                      value={game1 || ""}
                    >
                      <option style={{ display: "none" }}> Select Game</option>
                      <option>{eventDetail?.EventPlan?.game_1}</option>
                      <option>Icebreaker - 5 Second Rule</option>
                      <option>Icebreaker - Celebrity Alphabet</option>
                      <option>Corporate Card Revoked</option>
                      <option>Name That Tune</option>
                      <option>Chatageous</option>i8
                      <option>Word Search</option>
                      <option>Charades</option>
                      <option>Family Feud</option>
                      <option>Mad Gab3</option>
                      <option>level Scavenger Hunt</option>
                      <option>Trivia</option>
                      <option>2 Truths and a Lie</option>
                    </select>
                  ) : (
                    <div>
                      {/* {console.log(val, "val")} */}

                      <select
                        // defaultValue=""
                        className="form-select"
                        // disabled={isOwner}
                        value={game1}
                        placeholder="select"
                        onChange={(e) => {
                          setGame1(e.target.value);
                          validation();
                        }}
                        // onChange={(e) => changeHandler(e)}
                      >
                        <option></option>
                        <option>Icebreaker - 5 Second Rule</option>
                        <option>Icebreaker - Celebrity Alphabet</option>
                        <option>Corporate Card Revoked</option>
                        <option>Name That Tune</option>
                        <option>Chatageous</option>
                        <option>Word Search</option>
                        <option>Charades</option>
                        <option>Family Feud</option>
                        <option>Mad Gab3</option>
                        <option>level Scavenger Hunt</option>
                        <option>Trivia</option>
                        <option>2 Truths and a Lie</option>
                      </select>
                    </div>
                  )}
                  {/* <span className="error_message">{errors.drop}</span> */}
                </div>

                <div className="col-md-6 form-col ">
                  <label htmlFor="list_of_speaker" className="form-label">
                    List Of Speakers&nbsp;
                  </label>
                  {val ? (
                    <input
                      // disabled={isOwner}
                      name="list_of_speaker_1"
                      maxLength={100}
                      className="form-control"
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      value={eventDetail?.EventPlan?.list_of_speaker_1 || ""}
                    ></input>
                  ) : (
                    <textarea
                      name="list_of_speaker_1"
                      className="form-control"
                      maxLength={100}
                      // disabled={isOwner}
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        validation();
                        setList1(e.target.value);
                      }}
                      value={list1}
                    ></textarea>
                  )}
                  <span className="error_message">{errors.list}</span>
                </div>

                <div className="col-md-6 form-col " id="game2">
                  <label htmlFor="conservative_level" className="form-label">
                    Select Game 2 &nbsp;
                  </label>
                  {val ? (
                    <select
                      // disabled={isOwner}
                      className="form-select"
                      onChange={(e) => changeHandler(e)}
                      value={game2 || ""}
                    >
                      <option style={{ display: "none" }}> Select Game</option>
                      <option>{eventDetail?.EventPlan?.game_2}</option>
                      <option>Icebreaker - 5 Second Rule</option>
                      <option>Icebreaker - Celebrity Alphabet</option>
                      <option>Corporate Card Revoked</option>
                      <option>Name That Tune</option>
                      <option>Chatageous</option>
                      <option>Word Search</option>
                      <option>Charades</option>
                      <option>Family Feud</option>
                      <option>Mad Gab3</option>
                      <option>level Scavenger Hunt</option>
                      <option>Trivia</option>
                      <option>2 Truths and a Lie</option>
                    </select>
                  ) : (
                    <div>
                      {/* {console.log(val, "val")} */}

                      <select
                        // defaultValue=""
                        className="form-select"
                        // disabled={isOwner}
                        value={game2}
                        placeholder="select"
                        onChange={(e) => {
                          setGame2(e.target.value);
                          validation();
                        }}
                        // onChange={(e) => changeHandler(e)}
                      >
                        <option></option>
                        <option>Icebreaker - 5 Second Rule</option>
                        <option>Icebreaker - Celebrity Alphabet</option>
                        <option>Corporate Card Revoked</option>
                        <option>Name That Tune</option>
                        <option>Chatageous</option>
                        <option>Word Search</option>
                        <option>Charades</option>
                        <option>Family Feud</option>
                        <option>Mad Gab3</option>
                        <option>level Scavenger Hunt</option>
                        <option>Trivia</option>
                        <option>2 Truths and a Lie</option>
                      </select>
                    </div>
                  )}
                  {/* <span className="error_message">{errors.drop}</span> */}
                </div>

                <div className="col-md-6 form-col ">
                  <label htmlFor="list_of_speaker" className="form-label">
                    List Of Speakers&nbsp;
                  </label>
                  {val ? (
                    <input
                      // disabled={isOwner}
                      name="list_of_speaker_2"
                      maxLength={100}
                      className="form-control"
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      value={eventDetail?.EventPlan?.list_of_speaker_2 || ""}
                    ></input>
                  ) : (
                    <textarea
                      name="list_of_speaker_2"
                      className="form-control"
                      maxLength={100}
                      // disabled={isOwner}
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        validation();
                        setList2(e.target.value);
                      }}
                      value={list2}
                    ></textarea>
                  )}
                  <span className="error_message">{errors.list}</span>
                </div>

                <div className="col-md-6 form-col " id="game3">
                  <label htmlFor="conservative_level" className="form-label">
                    Select Game 3 &nbsp;
                  </label>
                  {val ? (
                    <select
                      className="form-select"
                      onChange={(e) => changeHandler(e)}
                      value={game3 || ""}
                    >
                      <option style={{ display: "none" }}> Select Game</option>
                      <option>{eventDetail?.EventPlan?.game_3}</option>
                      <option>Icebreaker - 5 Second Rule</option>
                      <option>Icebreaker - Celebrity Alphabet</option>
                      <option>Corporate Card Revoked</option>
                      <option>Name That Tune</option>
                      <option>Chatageous</option>
                      <option>Word Search</option>
                      <option>Charades</option>
                      <option>Family Feud</option>
                      <option>Mad Gab3</option>
                      <option>level Scavenger Hunt</option>
                      <option>Trivia</option>
                      <option>2 Truths and a Lie</option>
                    </select>
                  ) : (
                    <div>
                      {/* {console.log(val, "val")} */}

                      <select
                        // defaultValue=""
                        className="form-select"
                        // disabled={isOwner}
                        value={game3}
                        placeholder="select"
                        onChange={(e) => {
                          setGame3(e.target.value);
                          validation();
                        }}
                        // onChange={(e) => changeHandler(e)}
                      >
                        <option></option>
                        <option>Icebreaker - 5 Second Rule</option>
                        <option>Icebreaker - Celebrity Alphabet</option>
                        <option>Corporate Card Revoked</option>
                        <option>Name That Tune</option>
                        <option>Chatageous</option>
                        <option>Word Search</option>
                        <option>Charades</option>
                        <option>Family Feud</option>
                        <option>Mad Gab3</option>
                        <option>level Scavenger Hunt</option>
                        <option>Trivia</option>
                        <option>2 Truths and a Lie</option>
                      </select>
                    </div>
                  )}
                </div>
                <div className="col-md-6 form-col ">
                  <label htmlFor="list_of_speaker" className="form-label">
                    List Of Speakers&nbsp;
                  </label>
                  {val ? (
                    <input
                      // disabled={isOwner}
                      name="list_of_speaker_3"
                      maxLength={100}
                      className="form-control"
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      value={eventDetail?.EventPlan?.list_of_speaker_3 || ""}
                    ></input>
                  ) : (
                    <textarea
                      name="list_of_speaker_3"
                      className="form-control"
                      maxLength={100}
                      // disabled={isOwner}
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        validation();
                        setList3(e.target.value);
                      }}
                      value={list3}
                    ></textarea>
                  )}
                  <span className="error_message">{errors.list}</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-6 form-col " id="game1">
                  <label htmlFor="conservative_level" className="form-label">
                    Select Game 1 &nbsp;
                  </label>
                  {val ? (
                    <select
                      className="form-select"
                      onChange={(e) => changeHandler(e)}
                      value={game1 || ""}
                    >
                      <option style={{ display: "none" }}> Select Game</option>
                      <option>{eventDetail?.EventPlan?.game_1}</option>
                      <option>Icebreaker - 5 Second Rule</option>
                      <option>Icebreaker - Celebrity Alphabet</option>
                      <option>Corporate Card Revoked</option>
                      <option>Name That Tune</option>
                      <option>Chatageous</option>i8
                      <option>Word Search</option>
                      <option>Charades</option>
                      <option>Family Feud</option>
                      <option>Mad Gab3</option>
                      <option>level Scavenger Hunt</option>
                      <option>Trivia</option>
                      <option>2 Truths and a Lie</option>
                    </select>
                  ) : (
                    <div>
                      <select
                        className="form-select"
                        // disabled={isOwner}
                        value={game1}
                        placeholder="select"
                        onChange={(e) => {
                          setGame1(e.target.value);
                          validation();
                        }}
                      >
                        <option></option>
                        <option>Icebreaker - 5 Second Rule</option>
                        <option>Icebreaker - Celebrity Alphabet</option>
                        <option>Corporate Card Revoked</option>
                        <option>Name That Tune</option>
                        <option>Chatageous</option>
                        <option>Word Search</option>
                        <option>Charades</option>
                        <option>Family Feud</option>
                        <option>Mad Gab3</option>
                        <option>level Scavenger Hunt</option>
                        <option>Trivia</option>
                        <option>2 Truths and a Lie</option>
                      </select>
                    </div>
                  )}
                  {/* <span className="error_message">{errors.drop}</span> */}
                </div>

                <div className="col-md-6 form-col ">
                  <label htmlFor="list_of_speaker" className="form-label">
                    List Of Speakers&nbsp;
                  </label>
                  {val ? (
                    <input
                      // disabled={isOwner}
                      name="list_of_speaker_1"
                      maxLength={100}
                      className="form-control"
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      value={eventDetail?.EventPlan?.list_of_speaker_1 || ""}
                    ></input>
                  ) : (
                    <textarea
                      name="list_of_speaker_1"
                      className="form-control"
                      maxLength={100}
                      // disabled={isOwner}
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        validation();
                        setList1(e.target.value);
                      }}
                      value={list1}
                    ></textarea>
                  )}
                  <span className="error_message">{errors.list}</span>
                </div>

                <div className="col-md-6 form-col " id="game2">
                  <label htmlFor="conservative_level" className="form-label">
                    Select Game 2 &nbsp;
                  </label>
                  {val ? (
                    <select
                      className="form-select"
                      onChange={(e) => changeHandler(e)}
                      value={game2 || ""}
                    >
                      <option style={{ display: "none" }}> Select Game</option>
                      <option>{eventDetail?.EventPlan?.game_2}</option>
                      <option>Icebreaker - 5 Second Rule</option>
                      <option>Icebreaker - Celebrity Alphabet</option>
                      <option>Corporate Card Revoked</option>
                      <option>Name That Tune</option>
                      <option>Chatageous</option>
                      <option>Word Search</option>
                      <option>Charades</option>
                      <option>Family Feud</option>
                      <option>Mad Gab3</option>
                      <option>level Scavenger Hunt</option>
                      <option>Trivia</option>
                      <option>2 Truths and a Lie</option>
                    </select>
                  ) : (
                    <div>
                      {/* {console.log(val, "val")} */}

                      <select
                        // defaultValue=""
                        className="form-select"
                        // disabled={isOwner}
                        value={game2}
                        placeholder="select"
                        onChange={(e) => {
                          setGame2(e.target.value);
                          validation();
                        }}
                        // onChange={(e) => changeHandler(e)}
                      >
                        <option style={{ display: "none" }}>
                          {" "}
                          Select Game
                        </option>
                        <option>Icebreaker - 5 Second Rule</option>
                        <option>Icebreaker - Celebrity Alphabet</option>
                        <option>Corporate Card Revoked</option>
                        <option>Name That Tune</option>
                        <option>Chatageous</option>
                        <option>Word Search</option>
                        <option>Charades</option>
                        <option>Family Feud</option>
                        <option>Mad Gab3</option>
                        <option>level Scavenger Hunt</option>
                        <option>Trivia</option>
                        <option>2 Truths and a Lie</option>
                      </select>
                    </div>
                  )}
                  {/* <span className="error_message">{errors.drop}</span> */}
                </div>

                <div className="col-md-6 form-col ">
                  <label htmlFor="list_of_speaker" className="form-label">
                    List Of Speakers&nbsp;
                  </label>
                  {val ? (
                    <input
                      // disabled={isOwner}
                      name="list_of_speaker_2"
                      maxLength={100}
                      className="form-control"
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      value={eventDetail?.EventPlan?.list_of_speaker_2 || ""}
                    ></input>
                  ) : (
                    <textarea
                      name="list_of_speaker_2"
                      className="form-control"
                      maxLength={100}
                      // disabled={isOwner}
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        validation();
                        setList2(e.target.value);
                      }}
                      value={list2}
                    ></textarea>
                  )}
                  <span className="error_message">{errors.list}</span>
                </div>

                <div className="col-md-6 form-col " id="game3">
                  <label htmlFor="conservative_level" className="form-label">
                    Select Game 3 &nbsp;
                  </label>
                  {val ? (
                    <select
                      // disabled={isOwner}
                      className="form-select"
                      onChange={(e) => changeHandler(e)}
                      value={game3 || ""}
                    >
                      <option style={{ display: "none" }}> Select Game</option>
                      <option>{eventDetail?.EventPlan?.game_3}</option>
                      <option>Icebreaker - 5 Second Rule</option>
                      <option>Icebreaker - Celebrity Alphabet</option>
                      <option>Corporate Card Revoked</option>
                      <option>Name That Tune</option>
                      <option>Chatageous</option>
                      <option>Word Search</option>
                      <option>Charades</option>
                      <option>Family Feud</option>
                      <option>Mad Gab3</option>
                      <option>level Scavenger Hunt</option>
                      <option>Trivia</option>
                      <option>2 Truths and a Lie</option>
                    </select>
                  ) : (
                    <div>
                      {/* {console.log(val, "val")} */}

                      <select
                        // defaultValue=""
                        className="form-select"
                        // disabled={isOwner}
                        value={game3}
                        placeholder="select"
                        onChange={(e) => {
                          setGame3(e.target.value);
                          validation();
                        }}
                        // onChange={(e) => changeHandler(e)}
                      >
                        <option></option>
                        <option>Icebreaker - 5 Second Rule</option>
                        <option>Icebreaker - Celebrity Alphabet</option>
                        <option>Corporate Card Revoked</option>
                        <option>Name That Tune</option>
                        <option>Chatageous</option>
                        <option>Word Search</option>
                        <option>Charades</option>
                        <option>Family Feud</option>
                        <option>Mad Gab3</option>
                        <option>level Scavenger Hunt</option>
                        <option>Trivia</option>
                        <option>2 Truths and a Lie</option>
                      </select>
                    </div>
                  )}
                  {/* <span className="error_message">{errors.drop}</span> */}
                </div>
                <div className="col-md-6 form-col ">
                  <label htmlFor="list_of_speaker" className="form-label">
                    List Of Speakers&nbsp;
                  </label>
                  {val ? (
                    <input
                      // disabled={isOwner}
                      name="list_of_speaker_3"
                      maxLength={100}
                      className="form-control"
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      value={eventDetail?.EventPlan?.list_of_speaker_3 || ""}
                    ></input>
                  ) : (
                    <textarea
                      name="list_of_speaker_3"
                      className="form-control"
                      maxLength={100}
                      // disabled={isOwner}
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        validation();
                        setList3(e.target.value);
                      }}
                      value={list3}
                    ></textarea>
                  )}
                  <span className="error_message">{errors.list}</span>
                </div>

                <div className="col-md-6 form-col " id="game4">
                  <label htmlFor="conservative_level" className="form-label">
                    Select Game 4 &nbsp;
                  </label>
                  {val ? (
                    <select
                      // disabled={isOwner}
                      className="form-select"
                      onChange={(e) => changeHandler(e)}
                      value={game4 || ""}
                    >
                      <option style={{ display: "none" }}> Select Game</option>
                      <option>{eventDetail?.EventPlan?.game_4}</option>
                      <option>Icebreaker - 5 Second Rule</option>
                      <option>Icebreaker - Celebrity Alphabet</option>
                      <option>Corporate Card Revoked</option>
                      <option>Name That Tune</option>
                      <option>Chatageous</option>
                      <option>Word Search</option>
                      <option>Charades</option>
                      <option>Family Feud</option>
                      <option>Mad Gab3</option>
                      <option>level Scavenger Hunt</option>
                      <option>Trivia</option>
                      <option>2 Truths and a Lie</option>
                    </select>
                  ) : (
                    <div>
                      {/* {console.log(val, "val")} */}

                      <select
                        // defaultValue=""
                        className="form-select"
                        // disabled={isOwner}
                        value={game4}
                        placeholder="select"
                        onChange={(e) => {
                          setGame4(e.target.value);
                          validation();
                        }}
                        // onChange={(e) => changeHandler(e)}
                      >
                        <option></option>
                        <option>Icebreaker - 5 Second Rule</option>
                        <option>Icebreaker - Celebrity Alphabet</option>
                        <option>Corporate Card Revoked</option>
                        <option>Name That Tune</option>
                        <option>Chatageous</option>
                        <option>Word Search</option>
                        <option>Charades</option>
                        <option>Family Feud</option>
                        <option>Mad Gab3</option>
                        <option>level Scavenger Hunt</option>
                        <option>Trivia</option>
                        <option>2 Truths and a Lie</option>
                      </select>
                    </div>
                  )}
                  {/* <span className="error_message">{errors.drop}</span> */}
                </div>
                <div className="col-md-6 form-col ">
                  <label htmlFor="list_of_speaker" className="form-label">
                    List Of Speakers&nbsp;
                  </label>
                  {val ? (
                    <input
                      // disabled={isOwner}
                      name="list_of_speaker_4"
                      maxLength={100}
                      className="form-control"
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      value={eventDetail?.EventPlan?.list_of_speaker_4 || ""}
                    ></input>
                  ) : (
                    <textarea
                      name="list_of_speaker_4"
                      className="form-control"
                      maxLength={100}
                      // disabled={isOwner}
                      placeholder="List Of Speaker"
                      onChange={(e) => {
                        validation();
                        setList4(e.target.value);
                      }}
                      value={list4}
                    ></textarea>
                  )}
                  <span className="error_message">{errors.list}</span>
                </div>
              </div>
            )}
          </div>
        )}

        {/* <div className="col-md-6 form-col">
          <label htmlFor="event-time" className="form-label">
            Event Start Time
          </label>

          <input

            className="form-control"
            name="event_start_time"
            aria-describedby="inputGroupPrepend"
            value={
              moment(
                eventDetail?.EventInfo?.event_start_time,
                "hh:mm A"
              ).format("hh:mm A") || ""
            }
            disabled={true}
          />
     
        </div> */}

        {/* <div className="col-md-6 form-col">
          <label htmlFor="event-time" className="form-label">
            Event End Time
          </label>

          <input
            // type="time"
            className="form-control"
            name="event_end_time"
            aria-describedby="inputGroupPrepend"
            value={
              moment(eventDetail?.EventInfo?.event_end_time, "hh:mm A").format(
                "hh:mm A"
              ) || ""
            }
            // onInput="resetErrors('event_end_time_error')"
            disabled={true}
          />
        </div> */}

        {/* <div className="col-md-6 form-col">
          <label htmlFor="event-occasion" className="form-label">
            Occasion
          </label>
          <input
            type="text"
            className="form-control"
            name="occasion"
            value={eventDetail?.EventInfo?.occasion || ""}
            disabled={true}
          />
        </div> */}

        {/* <div className="col-md-6 form-col">
          <label htmlFor="Guest-Count" className="form-label">
            Guest Count
          </label>
          <input
            type="text"
            className="form-control"
            name="guest_count"
            value={eventDetail?.EventInfo?.guest_count || ""}
            disabled={true}
          />
        </div> */}
        {/* <div className="col-md-6 form-col">
          <label htmlFor="event-occasion" className="form-label">
            Phone
          </label>
          <input
            type="text"
            className="form-control"
            name="occasion"
            value={eventDetail?.EventInfo?.phone || ""}
         
            disabled={true}
          />
        </div> */}
        <div className="col-12 form-btn-area">
          <div className="row-btn">
            <button
              className="btn btn-primary "
              type="button"
              onClick={(e) => {
                submitHandler(e);
              }}
            >
              Save <ToastContainer className="planner" autoClose={10} />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Eventslist;
