//Add/Remove Upgraded Backdrop Start
//sequin
import blackWhite from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Sequins/Black_White.png";
import goldWhite from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Sequins/Gold_White.png";
import goldSeq from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Sequins/Gold.png";
import navySilver from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Sequins/Navy_Silver.png";
import redSeq from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Sequins/Red.png";

//Green
import greenScreen from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Green/Green_Screen.png";
// import greenLeaves from "./OpenAir_assets/Open_Air/Backdrop/Green/greenLeaves.png";

//stretch
import greenLeaves from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Stretch/Leaves.png";
import pink from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Stretch/Pink.png";
import whiteGold from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Stretch/White_Gold.png";
import wood from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Stretch/Wood.png";
import Yellow from "./OpenAir_assets/Open_Air/UpgradedBackdrop/Stretch/Yellow.png";
import WhiteNew from "./OpenAir_assets/Open_Air/Backdrop/Stretch/WhiteNew.png";
import BlackNew from "./OpenAir_assets/Open_Air/Backdrop/Stretch/BlackNew.png";

// circle Image
// Sequin
import blackWhite_Circle from "./OpenAir_assets/CircleBackdropImage/Sequin/Black & White.png";
import goldWhite_Circle from "./OpenAir_assets/CircleBackdropImage/Sequin/Gold & White.png";
import goldSeq_Circle from "./OpenAir_assets/CircleBackdropImage/Sequin/Gold.png";
import navySilver_Circle from "./OpenAir_assets/CircleBackdropImage/Sequin/Navy & Silver.png";
import redSeq_Circle from "./OpenAir_assets/CircleBackdropImage/Sequin/Red.png";

//Stretch

import greenLeaves_Circle from "./OpenAir_assets/CircleBackdropImage/Stretch/Green Laves.png";
import BlackNew_Circle from "./OpenAir_assets/CircleBackdropImage/Stretch/Black.png";
import pink_Circle from "./OpenAir_assets/CircleBackdropImage/Stretch/Pink.png";
import WhiteGold_Circle from "./OpenAir_assets/CircleBackdropImage/Stretch/White & Gold.png";
import WhiteNew_circle from "./OpenAir_assets/CircleBackdropImage/Stretch/White.png";
import wood_Circle from "./OpenAir_assets/CircleBackdropImage/Stretch/Wood.png";
import Yellow_circle from "./OpenAir_assets/CircleBackdropImage/Stretch/Yellow.png";

//green Screen
import greenScreen_circle from "./OpenAir_assets/CircleBackdropImage/Stretch/Green Screen.png";

//2X6 Backdrop End

var Sequin_Add_Backdrop = [
  { img: blackWhite, imgTitle: "black-white", circleImage: blackWhite_Circle },
  { img: goldWhite, imgTitle: "gold-white", circleImage: goldWhite_Circle },
  { img: goldSeq, imgTitle: "gold", circleImage: goldSeq_Circle },
  { img: navySilver, imgTitle: "navy-silver", circleImage: navySilver_Circle },
  { img: redSeq, imgTitle: "red", circleImage: redSeq_Circle },
  // silverSeq,
  // whiteSeq,
];

var Green_Add_Backdrop = [
  {
    img: greenScreen,
    imgTitle: "green-screen",
    circleImage: greenScreen_circle,
  },
  {
    img: greenScreen,
    imgTitle: "green-screen",
    circleImage: greenScreen_circle,
  },
  {
    img: greenScreen,
    imgTitle: "green-screen",
    circleImage: greenScreen_circle,
  },
  {
    img: greenScreen,
    imgTitle: "green-screen",
    circleImage: greenScreen_circle,
  },
  {
    img: greenScreen,
    imgTitle: "green-screen",
    circleImage: greenScreen_circle,
  },
];

var stretch_Add_Backdrop = [
  {
    img: greenLeaves,
    imgTitle: "green-leaves",
    circleImage: greenLeaves_Circle,
  },
  { img: BlackNew, imgTitle: "black", circleImage: BlackNew_Circle },
  { img: pink, imgTitle: "pink", circleImage: pink_Circle },
  { img: whiteGold, imgTitle: "white-gold", circleImage: WhiteGold_Circle },
  { img: WhiteNew, imgTitle: "white", circleImage: WhiteNew_circle },
  { img: wood, imgTitle: "wood", circleImage: wood_Circle },
  { img: Yellow, imgTitle: "yellow", circleImage: Yellow_circle },
];

export { Sequin_Add_Backdrop, Green_Add_Backdrop, stretch_Add_Backdrop };
