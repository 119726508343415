export const weddingActivity = [
  {
    name: "Ceremony",
    childArr: [
      {
        name: "Guests Enter Room",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Family Bridal Party Entrance",
        subChildArr: [
          {
            person_name_1_subChild: "",
            person_name_2_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Bride Entrance",
        subChildArr: [
          {
            person_name_1_subChild: "",
            person_name_2_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "After Kiss",
        subChildArr: [
          {
            person_name_1_subChild: "",
            person_name_2_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
  {
    name: "Cocktail Hour",
    childArr: [
      {
        name: "Playlist",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
  {
    name: "Reception",
    childArr: [
      {
        name: "Bridal Party Entrances",
        subChildArr: [
          {
            person_name_1_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            // person_name_2_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Bride & Groom Entrance",
        subChildArr: [
          {
            person_name_1_subChild: "",
            // person_name_2_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "1st Dance",
        subChildArr: [
          {
            person_name_1_subChild: "",
            // person_name_2_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "2nd Dance",
        subChildArr: [
          {
            // person_name_1_subChild: "",
            // person_name_2_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "DANCE SET",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Sit for dinner orders, first course, toasts",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
      {
        name: "first Course served",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Grace/Blessings/Toasts",
        subChildArr: [
          {
            person_name_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Dinner served(playlist)",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Cake Cutting",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Bouquet Toss",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Garter Removal",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Garter Toss",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Garter catcher dressed garter on the bouquet catchers leg",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Special Dances(parent/signicant person)",
        subChildArr: [
          {
            person_name_1_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Dessert",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Last Song",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
  {
    name: "Music & Crowd Description",
    childArr: [
      {
        name: "Crowd Description",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Playlist",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Do Not Play",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
];

export const sweet16Activity = [
  {
    name: "Cocktail Hour",
    childArr: [
      {
        name: "Playlist",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
  {
    name: "Reception",
    childArr: [
      {
        name: "Court/Family Entrances",
        subChildArr: [
          {
            person_name_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Sweet 16 Entrance",
        subChildArr: [
          {
            person_name_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "DANCE SET",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Show/Crown Ceremony",
        subChildArr: [
          {
            person_name_1_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Grace/Blessings/Toasts",
        subChildArr: [
          {
            person_name_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Dinner served(playlist)",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Special Dances(parent/signicant person)",
        subChildArr: [
          {
            person_name_1_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Candles/Cake Cutting",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Dessert",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
    ],
  },
  {
    name: "Music & Crowd Description",
    childArr: [
      {
        name: "Crowd Description",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Playlist",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Do Not Play",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
];

export const mitzvahActivity = [
  {
    name: "Cocktail Hour",
    childArr: [
      {
        name: "Playlist",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
  {
    name: "Reception",
    childArr: [
      {
        name: "Family Entrances",
        subChildArr: [
          {
            person_name_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Guest Of Honor Entrance",
        subChildArr: [
          {
            person_name_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Hora",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },

      {
        name: "DANCE SET",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Grace/Blessings/Toasts",
        subChildArr: [
          {
            person_name_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Dinner served(playlist)",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Candles/Cake Cutting",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Special Dances(parent/signicant person)",
        subChildArr: [
          {
            person_name_1_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Dessert",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
    ],
  },

  {
    name: "Music & Crowd Description",
    childArr: [
      {
        name: "Crowd Description",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Playlist",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Do Not Play",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
];

export const otherDefault = [
  {
    name: "Cocktail Hour",
    childArr: [
      {
        name: "Playlist",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
  {
    name: "Reception",
    childArr: [
      {
        name: "Entrances",
        subChildArr: [
          {
            person_name_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Welcome",
        subChildArr: [
          {
            person_name_subChild: "",
            relation_to_guest_of_honors_subChild: "",
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Dinner served(playlist)",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },

      {
        name: "DANCE SET",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Dessert",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },

      {
        name: "Last Song",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },

  {
    name: "Music & Crowd Description",
    childArr: [
      {
        name: "Crowd Description",
        subChildArr: [
          {
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Playlist",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            youtube_link_subChild: "",
            notes_subChild: "",
          },
        ],
      },
      {
        name: "Do Not Play",
        subChildArr: [
          {
            song_title_subChild: "",
            artist_subChild: "",
            notes_subChild: "",
          },
        ],
      },
    ],
  },
];
