import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation , useNavigate} from "react-router-dom";
import { UserUpdate } from "../Redux/user/userAction";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css"
import "./Profile.css";

function Profile() {

  const dispatch = useDispatch();

  const token = useSelector((state) => state?.userDetail?.userToken);
  // const userinfo = useSelector((state) => state?.userDetail?.data);
  const userinfo = useSelector((state) => state?.userupdate);
  // console.log(userinfo,"jvuyv")

  const uploadedImage = useRef(null);
  const imageUploader = useRef();

  
  const [name, setname] = useState();
  const [username, setusername] = useState();
  const [phone, setphone] = useState();
  const [address, setaddress] = useState();
  const [email, setemail] = useState();
  const [image, setimage] = useState();
  const [val, setVal] = useState(false);
  const [file, setFile] = useState(null);
  const [errors, SetErrors] = useState({});

  const changeHandler = () => {
    validation();
    setVal(false);
 
  };

  const navigate = useNavigate();
  const errorHandler = (err) => {

    navigate("/Error");
  };

  const keynotpress = (e) => {

    // let error = {};

    console.log(e.which, "rrre")
    // // console.log(window.event, "jkbckeubvbe")

    // if (window.event) {
    //   var charCode = window.event.keyCode;
    // }
    // else if (e) {
    //   var charCode = e.which;
    //   // console.log(charCode,"charcode")
    // }
    // else { return true; }
    // if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123 || e.which == 32))
    //   return true;
    // else {
    //   alert("Please enter only alphabets");
    //   SetErrors(error);
    //   return false;
    // }

    // return error;

  }

  const notiffailure = () => {
    setTimeout(() => {
      toast.error("Not Upated");
    }, 1000);
  };



  const validation = (e) => {
    
    // const re =  /[!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?] +/;
    const re = /^[a-zA-Z0-9]$/ ;
    // const re = /![\s\S]*@/;

    console.log(name,"e")
    let error = {};
    // console.log(re.test(name),"eggewg3fg")
    if (name.trim() === "" || /\d/.test(name)) {
      // alert("Please enter only alphabets");
      error.name = "Please Enter name properly and only alphabet ";
    }

    if (address === "") {
      error.address = "Please Fill properly";
    }

    if (phone === "") {
      error.phone = "Please Fill The phone";
    }

    if (username === "") {
      error.username = "Please Fill The occasion";
    }

    SetErrors(error)

    return error;
  }


  const notifySuccess = () => {
    toast.success("Upated Successfully");
  };
  const submitHandler = (e) => {

    const error = validation();
    if (Object.keys(error).length > 0) {
      notiffailure()
      return;
    }

    var datas = {
      name: name,
      image: file,
      address: address,
      email: email,
      phone: phone,
      username: username,
      password: "",
    };

    // console.log(datas,"y")


    const formData = new FormData();
    for (const key in datas) {
      formData.append(key, datas[key]);
    }
    fetch(`${process.env.REACT_APP_BASE_URL}editProfile`, {
      method: "post",
      headers: {
        "x-access-token": `${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        // console.log(image)
        const data = {...datas};
        data.image = image;
        dispatch(UserUpdate(data));
        notifySuccess();
      })
      .catch((err) => {
        // console.log("====================================");
        errorHandler(err)
        // console.log(err,"err");
        // console.log("====================================");
      });
  };



 

  const triggerFileUploadClick = () => {
    imageUploader.current.click();  
  };
  // console.log(image,"tfuy6f6f")

  const onImageChange = (event) => {
    // console.log(event.target.files[0]);
    

    var fileInput =document.getElementById('imageup');
             
            var filePath = fileInput.value;
         
            // Allowing file type
            var allowedExtensions =
                    /(\.jpg|\.jpeg|\.png|\.gif)$/i;
             
            if (!allowedExtensions.exec(filePath)) {
                alert('Invalid file type');
                fileInput.value = '';
                return false;
            }
            else
            {
             
                // Image preview
                if (event.target.files && event.target.files[0]) {
                  let reader = new FileReader();
                  reader.onload = (e) => {
                    
                    setimage(e.target.result);
                    setFile(event.target.files[0]);
              
                  };
                  reader.readAsDataURL(event.target.files[0]);
                }
            }
  };

  useEffect(() => {
    setname(userinfo?.name);
    // setimage(userinfo?.image);
    // setFile(userinfo?.image);
    let imageUrl = `${userinfo?.image?.startsWith('http') ? '' : process.env.REACT_APP_BASE_URL}${userinfo?.image}`;

    // let imageUrl = `${process.env.REACT_APP_BASE_URL}${userinfo?.image}`;
    if(userinfo?.image.startsWith("data")) {  
      imageUrl = userinfo?.image;
    }
     setimage(imageUrl);
    setaddress(userinfo?.address);
    setusername(userinfo?.username);
    setphone(userinfo?.phone);
    setemail(userinfo?.email);
  }, []);

  return (
    <section className="Main-all-project Events" data-toggle="collapse" data-target=".navbar-collapse.show">
              <h5 className="page-title">PROFILE DETAILS</h5>
      <div className="product-inner-area">
        <div className="container">
          <div className="row">
            <div className="col-md-4 ">
              <div className="product-inner-area  profile_img-are">
                <input
                  style={{
                    width: "0",
                  }}
                  type="file"
                  id="imageup"
                  // accept="image/*"
                  accept="image/png,image/jpeg,image/jpg"
                  // src={userinfo?.image}
                  ref={imageUploader}
                  onChange={onImageChange}
                />
                <div
                  className="profile_img_round"
                >
                  <img
                    // src={file}
                    src={image}
                    alt="pik"
                    onClick={triggerFileUploadClick}
                  />
                  <div className="edit_halwa">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}img/edit.svg`}
                      alt="pik"
                      onClick={triggerFileUploadClick}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <form className="row form-wrapper needs-validation">
                <div className="col-md-6 form-col">
                  <label className="form-label">Name</label>
                  {val ? (
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={userinfo?.name || ""}
                      onChange={changeHandler}
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={(e) => (  
                        setname(e.target.value) ,validation()
                     )}
                    />
                  )}
          <span className="error_message">{errors.name}</span>

                </div>

                <div className="col-md-6 form-col">
                  <label htmlFor="validationCustom01" className="form-label">
                    Username
                  </label>
                  {val ? (
                    <input
                      type="text"
                      className="form-control"
                      onChange={changeHandler}
                      name="username"
                      value={userinfo?.username || ""}
                      disabled={true}
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => (validation(e),
                        setusername(e.target.value))}
                      name="username"
                      value={username}
                      disabled={true}
                    />
                  )}
          <span className="error_message">{errors.username}</span>

                </div>

                <div className="col-md-6 form-col">
                  <label htmlFor="validationCustomEmail" className="form-label">
                    Email
                  </label>
                  <div className="input-group has-validation">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      aria-describedby="inputGroupPrepend"
                      disabled={true}
                      defaultValue={userinfo?.email ||  " "}
                    />
                  </div>
                </div>

                <div className="col-md-6 form-col">
                  <label htmlFor="validationCustom01" className="form-label">
                    Phone
                  </label>
                  {val ? (
                    <input
                      number="true"
                      type="number"
                      maxLength={13}
                      className="form-control"
                      onChange={changeHandler}
                      name="phone"
                      value={userinfo?.phone || ""}
                      disabled={true}
                    />
                  ) : (
                    <input
                      number="true"
                      maxLength={13}
                      type="number"
                      className="form-control "
                      onChange={(e) => (validation(e),
                        setphone(e.target.value))}
                      name="phone"
                      value={phone}
                      disabled={true}
                    />
                  )}
          <span className="error_message">{errors.phone}</span>

                </div>

                <div className="col-md-6 form-col">
                  <label htmlFor="validationCustom01" className="form-label">
                    Address
                  </label>
                  {val ? (
                    <input
                      type="text"
                      className="form-control"
                      onChange={changeHandler}
                      name="address"
                      value={userinfo?.address || ""}
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={address}
                      onChange={(e) => (validation(e),
                        setaddress(e.target.value))}
                    />
                  )}
          <span className="error_message">{errors.address}</span>

                </div>

                <div className="col-12 mt-1  form-col text-center text-md-left ">
                  <div className="row-btn">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => {
                        submitHandler(e);
                      }}
                    >
                      Update
                      <ToastContainer className="planner" autoClose={10}/>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;
